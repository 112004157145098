import bodyContouringSVG from "@/assets/offerings/body-contouring.svg";
import hairLossManagementSVG from "@/assets/offerings/hair-loss-management.svg";
import medicalDermatologySVG from "@/assets/offerings/medical-dermatology.svg";
import skinRejuvenationSVG from "@/assets/offerings/skin-rejuvenation.svg";
import stemCellRejuvenationSVG from "@/assets/offerings/stem-cell-rejuvenation.svg";
import weightLossManagementSVG from "@/assets/offerings/weight-loss-management.svg";

export const OFFERINGS_OPTIONS = [
  {
    name: "Skin Rejuvenation",
    iconSrc: skinRejuvenationSVG,
    description: "Lines, wrinkles, pigmentation",
  },
  {
    name: "Weight loss management",
    iconSrc: weightLossManagementSVG,
  },
  {
    name: "Body Contouring",
    iconSrc: bodyContouringSVG,
  },
  {
    name: "Medical Dermatology",
    iconSrc: medicalDermatologySVG,
  },
  {
    name: "Hair loss treatment",
    iconSrc: hairLossManagementSVG,
  },
  {
    name: "Stem cell rejuvenation",
    iconSrc: stemCellRejuvenationSVG,
  },
];
