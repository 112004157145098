import { AuthLayout } from "../Login/components/AuthLayout";
import { AuthFormHeading } from "../Login/components/UI/misc";
import { AddIcon, CheckIcon, TrashIcon } from "@/components/UIKit/Icons";
import { TextField } from "@/components/UIKit/Inputs/TextField/TextField";
import { yupEmail } from "@/utils/yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { LoadingButton } from "@mui/lab";
import {
  Box,
  Button,
  FormHelperText,
  IconButton,
  IconButtonProps,
  InputAdornment,
  Typography,
  styled,
} from "@mui/material";
import { useEffect, useState } from "react";
import { UseFormProps, useFieldArray, useForm } from "react-hook-form";
import * as yup from "yup";

export type OnNextResponse = {
  failedEmails: { email: string; errMessage: string }[];
  successfulEmails: { email: string; assistantId: string }[];
} | null;
type Props = {
  onNext: (next: string[]) => Promise<OnNextResponse>;
  onCancelInvitation: (assistantId: string) => Promise<void>;
};
export const AddYourTeam = (props: Props) => {
  const [isLoading, setIsLoading] = useState(false);
  const {
    control,
    handleSubmit,
    register,
    watch,
    setError,
    setValue,
    formState: { errors },
  } = useAddYourTeamForm({
    defaultValues: {
      teamMembers: [{ email: "", isRequired: false }],
    },
  });
  const teamMembers = watch("teamMembers");
  const {
    fields: emailFields,
    append: addEmail,
    remove: deleteEmail,
  } = useFieldArray({ control, name: "teamMembers" });

  // in the case where the first input is replaced by another (ie: first input gets deleted, and 2nd input moves up to 1st), set it as not required so user can still skip this page
  useEffect(() => {
    if (teamMembers[0]?.isRequired) {
      setValue("teamMembers.0.isRequired", false);
    }
  }, [teamMembers, setValue]);

  const onNext = async (data: FormInputs) => {
    const emails = data.teamMembers
      .filter((member) => member.email && !member.assistantId)
      .map((m) => m.email) as string[];

    try {
      setIsLoading(true);
      const res = await props.onNext(emails);
      if (!res) return;

      res.failedEmails.forEach(({ email, errMessage }) => {
        const fieldIndex = teamMembers.findIndex((m) => m.email === email);
        setError(`teamMembers.${fieldIndex}.email`, {
          message: errMessage,
        });
      });

      res.successfulEmails.forEach(({ email, assistantId }) => {
        const fieldIndex = teamMembers.findIndex((m) => m.email === email);
        setValue(`teamMembers.${fieldIndex}.assistantId`, assistantId);
      });
    } catch (err: any) {
      throw Error(err);
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <AuthLayout>
      <AuthFormHeading
        title="Add your team"
        description="They will receive an email to log in. You will be able to manage access from the portal."
      />
      <Box>
        <Box mb="32px">
          {emailFields.map((field, index) => {
            const { assistantId } = teamMembers[index];
            const isDeleteHidden = index === 0 && !assistantId;

            return (
              <Box key={field.id} display="flex">
                <TeamMemberTextField
                  control={control}
                  register={register(`teamMembers.${index}.email`)}
                  label="Email"
                  fullWidth
                  placeholder="Email"
                  {...(!!assistantId && {
                    disabled: true,
                    hasBeenInvited: true,
                    InputProps: { endAdornment: <InviteSentAdornment /> },
                  })}
                />
                <FieldDeleteButton
                  sx={{ ...(isDeleteHidden && { visibility: "hidden" }) }}
                  onClick={async () => {
                    if (assistantId) {
                      await props.onCancelInvitation(assistantId);
                    }
                    // using setTimeout to ensure the state updates of "deleteEmail()" comes after the state updates of "props.onCancelInvitation()"
                    // - props.onCancelInvitation() -> calls useMutation.mutateAsync() -> which i believe triggers a state change and clashes with this one, causing the field to not get deleted properly.
                    setTimeout(() => {
                      deleteEmail(index);
                    }, 0);
                  }}
                />
              </Box>
            );
          })}
          <Box>
            <UserMenuActionButton
              startIcon={<AddIcon />}
              onClick={() => addEmail({ email: "", isRequired: true })}
            >
              Add email
            </UserMenuActionButton>
          </Box>
          {errors.teamMembers?.message && (
            <FormHelperText error>{errors.teamMembers.message}</FormHelperText>
          )}
        </Box>
        <LoadingButton
          loading={isLoading}
          variant="contained"
          color="primary"
          onClick={() => handleSubmit(onNext)()}
          fullWidth
        >
          {teamMembers.length > 1 || teamMembers[0]?.email
            ? "Continue"
            : "Skip"}
        </LoadingButton>
      </Box>
    </AuthLayout>
  );
};

type FormInputs = {
  teamMembers: {
    isRequired: boolean;
    email?: string;
    assistantId?: string;
  }[];
};

export const useAddYourTeamForm = (props?: UseFormProps<FormInputs>) => {
  let defaultValues = props?.defaultValues;
  const validationSchema: yup.SchemaOf<FormInputs> = yup.object({
    teamMembers: yup
      .array(
        yup.object({
          isRequired: yup.boolean().required(),
          email: yupEmail().when("isRequired", {
            is: (isRequired: boolean) => isRequired,
            then: yupEmail().required(),
          }),
          assistantId: yup.string(),
        })
      )
      .test({
        name: "no-duplicate-emails",
        message: "Found duplicate emails",
        test: (arr) => {
          if (!arr) return true;
          let hasNoDuplicates = true;
          const emailsSet = new Set<string>();
          arr.forEach((obj) => {
            if (!obj.email) return;
            if (emailsSet.has(obj.email)) hasNoDuplicates = false;
            emailsSet.add(obj.email);
          });
          return hasNoDuplicates;
        },
      }),
  });

  return useForm<FormInputs>({
    ...props,
    resolver: yupResolver(validationSchema),
    defaultValues: defaultValues,
  });
};
AddYourTeam.defaultProps = {
  onCancelInvitation: async () => {},
};

const UserMenuActionButton = styled(Button)`
  padding: 8px 6px;
  margin-bottom: 2px;
  justify-content: start;
  margin-bottom: 2px;
  opacity: 0.8;
  text-transform: none;

  :last-of-type {
    margin-bottom: 0px;
  }
  :hover {
    background: none;
    opacity: 1;
  }
`;

const TeamMemberTextField = styled(TextField, {
  shouldForwardProp: (prop) => prop !== "hasBeenInvited",
})<{ hasBeenInvited?: boolean }>`
  margin-bottom: 12px;

  ${(props) =>
    props.hasBeenInvited &&
    `
    .MuiInputLabel-root.Mui-disabled {
      color: #78756E;
    }
    .MuiOutlinedInput-root.Mui-disabled {
      background: #FCFCFC;
      input {
        -webkit-text-fill-color: #78756E;
        border-right: none;
      }
    }
  `}
`;

const InviteSentAdornment = () => {
  return (
    <InputAdornment position="end">
      <Box display="flex" alignItems="center" color="text.secondary">
        <CheckIcon sx={{ fontSize: "20px", mr: "4px", mt: "1px" }} />
        <Typography variant="body2Medium">Invite sent</Typography>
      </Box>
    </InputAdornment>
  );
};

const FieldDeleteButton = (
  props: IconButtonProps & { onClick: () => Promise<void> }
) => {
  const { onClick = () => {}, ...rest } = props;
  const [isLoading, setIsLoading] = useState(false);

  return (
    <FieldDeleteButtonBody
      color="secondary"
      disabled={isLoading}
      aria-label="delete"
      {...rest}
      onClick={async () => {
        setIsLoading(true);
        try {
          await onClick();
        } catch (e: any) {
          throw Error(e);
        } finally {
          setIsLoading(false);
        }
      }}
    >
      <TrashIcon />
    </FieldDeleteButtonBody>
  );
};
const FieldDeleteButtonBody = styled(IconButton)`
  align-self: start;
  margin-top: 48px;
  margin-left: 8px;
`;
