import freeAestheticConsultationIcon from "@/assets/checkout/benefits/free-aesthetic-consultation.svg";
import freeFacialOrPeelIcon from "@/assets/checkout/benefits/free-facial-or-peel.svg";
import freeSunscreenIcon from "@/assets/checkout/benefits/free-sunscreen.svg";
import skinCancerCheckIcon from "@/assets/checkout/benefits/skin-cancer-check.svg";
import skincareProductDiscountIcon from "@/assets/checkout/benefits/skincare-product-discount.svg";
import { VisitReason } from "@/types/apiContract/visit";

export const ONE_TIME_USE_VISIT_REASONS: VisitReason["type"][] = [
  "SKIN_CANCER_SCREENING",
  "AESTHETIC_CONSULTATION",
  "FACIALS",
  "SUNSCREEN",
];

export const BENEFITS_DATA: { [key: string]: { iconSrc: string } } = {
  SKIN_CANCER_CHECK: { iconSrc: skinCancerCheckIcon },
  SKIN_CARE_PRODUCT_DISCOUNT: { iconSrc: skincareProductDiscountIcon },
  FREE_FACIAL_OR_PEEL: { iconSrc: freeFacialOrPeelIcon },
  FREE_AESTHETIC_CONSULTATION: { iconSrc: freeAestheticConsultationIcon },
  FREE_SUNSCREEN: { iconSrc: freeSunscreenIcon },
};

export const BENEFITS_TO_ONE_TIME_USE_VISIT_REASONS: {
  [key: string]: VisitReason["type"];
} = {
  SKIN_CANCER_CHECK: "SKIN_CANCER_SCREENING",
  FREE_AESTHETIC_CONSULTATION: "AESTHETIC_CONSULTATION",
  FREE_FACIAL_OR_PEEL: "FACIALS",
  FREE_SUNSCREEN: "SUNSCREEN",
};
