import { OnboardingFooter } from "./components/OnboardingFooter";
import { OnboardingHeader } from "./components/OnboardingHeader";
import { LoggedInProvider } from "@/components/GlobalContext";
import { Box } from "@mui/material";
import { PropsWithChildren } from "react";

type Props = {
  provider: Pick<LoggedInProvider, "email" | "roles" | "type">;
};
export const OnboardingLayout = (props: PropsWithChildren<Props>) => {
  return (
    <Box>
      <OnboardingHeader provider={props.provider} />
      {props.children}
      <OnboardingFooter />
    </Box>
  );
};
