import { Provider } from "@/types/apiContract/provider";

export const SPECIALIZATION_OPTIONS = [
  { label: "Dermatologist", value: "DERMATOLOGIST" },
  { label: "Esthetician", value: "ESTHETICIAN" },
  { label: "Laser Technician", value: "LASER_TECHNICIAN" },
  { label: "Nurse Practitioner", value: "NURSE_PRACTITIONER" },
  { label: "Physician", value: "PHYSICIAN" },
  { label: "Physician Assistant", value: "PHYSICIAN_ASSISTANT" },
  { label: "Plastic Surgeon", value: "PLASTIC_SURGEON" },
  { label: "Registered Nurse", value: "REGISTERED_NURSE" },
] satisfies { label: string; value: Provider["specialization"] }[]; // based off backend
export type SpecializationType =
  (typeof SPECIALIZATION_OPTIONS)[number]["value"];
export const getSpecialization = (val: SpecializationType) => {
  return SPECIALIZATION_OPTIONS.find((o) => o.value === val);
};
