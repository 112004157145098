import { HeroSectionCard } from "./UI";
import skinCancerInformationalVideoPoster from "@/assets/dashboard/skin-cancer-informational-video.jpg";
import skinCancerInformationalVideoMp4 from "@/assets/dashboard/skin-cancer-informational-video.mp4";
import { CloseIcon } from "@/components/UIKit/Icons";
import useOpenable from "@/hooks/useOpenable";
import {
  Box,
  Dialog,
  DialogContent,
  IconButton,
  Typography,
  styled,
} from "@mui/material";
import ReactPlayer from "react-player/file";

export const VideoSection = () => {
  const { isOpen, onOpen, onClose } = useOpenable();

  return (
    <>
      <VideoModal open={isOpen} onClose={onClose}>
        <VideoModalCloseBtn onClick={onClose}>
          <CloseIcon sx={{ fontSize: "20px" }} />
        </VideoModalCloseBtn>
        <DialogContent sx={{ p: "8px", zIndex: 1 }}>
          <InformationalVideo
            controls
            url={skinCancerInformationalVideoMp4}
            width="100%"
            height="auto"
            playing
          />
        </DialogContent>
      </VideoModal>
      <Body>
        <VideoPreview onClick={() => onOpen()}>
          <img
            src={skinCancerInformationalVideoPoster}
            alt="skin-cancer-screening-video-poster"
          />
          <VideoPreviewOverlay>
            <PlaySvg />
          </VideoPreviewOverlay>
        </VideoPreview>
        <Box p="24px 16px" display="flex" justifyContent="space-between">
          <VideoTitleText maxWidth="210px">
            Skin Cancer Screening Informational Video
          </VideoTitleText>
          <VideoTimeText>16 min</VideoTimeText>
        </Box>
      </Body>
    </>
  );
};

const VideoModal = styled(Dialog)`
  .MuiDialog-paper {
    max-width: 960px;
    width: 100%;
    overflow-y: unset; // allow close button to go outside of the dialog
  }
`;

const VideoModalCloseBtn = styled(IconButton)`
  background: ${(props) => props.theme.palette.primary.main};
  display: flex;
  position: absolute;
  color: #fff;
  padding: 8px;
  top: -18px;
  right: -18px;
  z-index: 5;
  opacity: 1;
  :hover {
    background: ${(props) => props.theme.palette.primary.dark};
  }
`;

const VideoPreview = styled(Box)`
  position: relative;
  display: flex;
  cursor: pointer;
  border-top-right-radius: inherit;
  border-top-left-radius: inherit;

  img {
    width: 100%;
    border-radius: inherit;
    aspect-ratio: 368 / 207;
  }
`;

const PlaySvgOuterCirclePath = styled("path")``;
PlaySvgOuterCirclePath.defaultProps = {
  d: "M43.309 33.7784L38.8545 25.9581L38.8308 25.9716L38.8072 25.9852L43.309 33.7784ZM28.5192 42.3219L33.0167 50.1176L33.0211 50.1151L28.5192 42.3219ZM43.309 32.2121L47.8378 24.4345L47.8225 24.4256L47.8071 24.4167L43.309 32.2121ZM28.5192 23.678L33.0173 15.8827L33.0167 15.8824L28.5192 23.678ZM32.9998 0.666656C15.1475 0.666656 0.666504 15.1477 0.666504 33H18.6665C18.6665 25.0888 25.0887 18.6667 32.9998 18.6667V0.666656ZM65.3332 33C65.3332 15.1477 50.8521 0.666656 32.9998 0.666656V18.6667C40.911 18.6667 47.3332 25.0888 47.3332 33H65.3332ZM32.9998 65.3333C50.8521 65.3333 65.3332 50.8523 65.3332 33H47.3332C47.3332 40.9112 40.911 47.3333 32.9998 47.3333V65.3333ZM0.666504 33C0.666504 50.8523 15.1475 65.3333 32.9998 65.3333V47.3333C25.0887 47.3333 18.6665 40.9112 18.6665 33H0.666504ZM38.8072 25.9852L24.0174 34.5288L33.0211 50.1151L47.8109 41.5716L38.8072 25.9852ZM38.7803 39.9896C37.531 39.2622 34.8715 37.0525 34.8715 32.9988C34.8715 28.917 37.5641 26.6932 38.8545 25.9581L47.7636 41.5987C49.8039 40.4365 52.8715 37.6478 52.8715 32.9988C52.8715 28.3779 49.837 25.5987 47.8378 24.4345L38.7803 39.9896ZM24.0211 31.4734L38.811 40.0074L47.8071 24.4167L33.0173 15.8827L24.0211 31.4734ZM36.1712 24.4565C36.1712 25.97 35.5591 29.364 32.0694 31.3798C28.5791 33.3961 25.3327 32.2301 24.0217 31.4737L33.0167 15.8824C30.9653 14.6989 27.0449 13.4949 23.0657 15.7935C19.0872 18.0918 18.1712 22.0885 18.1712 24.4565H36.1712ZM36.1712 41.534V24.4565H18.1712V41.534H36.1712ZM24.0217 34.5263C25.3296 33.7717 28.5947 32.5948 32.0977 34.6354C35.5849 36.6667 36.1712 40.0622 36.1712 41.534H18.1712C18.1712 43.8697 19.0614 47.8727 23.0374 50.1889C27.0294 52.5143 30.9684 51.2993 33.0167 50.1176L24.0217 34.5263Z",
  fill: "white",
  fillOpacity: "0.2",
};

const VideoPreviewOverlay = styled(Box)`
  position: absolute;
  height: 100%;
  width: 100%;
  display: grid;
  place-items: center;

  ${PlaySvgOuterCirclePath} {
    transition-duration: 0.2s;
    opacity: 0;
  }

  :hover ${PlaySvgOuterCirclePath} {
    opacity: 1;
  }
`;

const InformationalVideo = styled(ReactPlayer)`
  display: flex;
  > * {
    border-radius: 8px;
  }
`;

const Body = styled(HeroSectionCard)`
  width: 370px;
  display: flex;
  flex-direction: column;
  ${(props) => props.theme.breakpoints.down("tablet")} {
    max-width: 50%;
  }
`;

const VideoTitleText = styled(Typography)`
  font-size: 16px;
  font-weight: 300;
  line-height: 20px;
  letter-spacing: 0.02em;
`;
const VideoTimeText = styled(Typography)`
  font-size: 12px;
  font-weight: 300;
  line-height: 20px;
  letter-spacing: 0.02em;
  color: rgba(255, 255, 255, 0.56);
`;

const PlaySvg = () => (
  <svg
    width="66"
    height="66"
    viewBox="0 0 66 66"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <mask
      maskUnits="userSpaceOnUse"
      x="0.666504"
      y="0.666656"
      width="65"
      height="65"
      fill="black"
    >
      <rect fill="white" x="0.666504" y="0.666656" width="65" height="65" />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M9.6665 33C9.6665 20.1182 20.1181 9.66666 32.9998 9.66666C45.8816 9.66666 56.3332 20.1182 56.3332 33C56.3332 45.8817 45.8816 56.3333 32.9998 56.3333C20.1181 56.3333 9.6665 45.8817 9.6665 33ZM28.5192 42.3219L43.309 33.7784C44.059 33.3512 44.059 32.6488 43.309 32.2121L28.5192 23.678C27.7788 23.2509 27.1712 23.6021 27.1712 24.4565V41.534C27.1712 42.3979 27.7788 42.7491 28.5192 42.3219Z"
      />
    </mask>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M9.6665 33C9.6665 20.1182 20.1181 9.66666 32.9998 9.66666C45.8816 9.66666 56.3332 20.1182 56.3332 33C56.3332 45.8817 45.8816 56.3333 32.9998 56.3333C20.1181 56.3333 9.6665 45.8817 9.6665 33ZM28.5192 42.3219L43.309 33.7784C44.059 33.3512 44.059 32.6488 43.309 32.2121L28.5192 23.678C27.7788 23.2509 27.1712 23.6021 27.1712 24.4565V41.534C27.1712 42.3979 27.7788 42.7491 28.5192 42.3219Z"
      fill="white"
      fillOpacity="0.62"
    />
    <PlaySvgOuterCirclePath />
  </svg>
);
