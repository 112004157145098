import { SvgIcon, SvgIconProps } from "@mui/material";
import React from "react";

function ArrowRightIcon(props: SvgIconProps) {
  return (
    <SvgIcon
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <g>
        <path
          d="M19.354 12.0296C19.4006 11.9909 19.4375 11.9449 19.4627 11.8943C19.4879 11.8437 19.5009 11.7894 19.5009 11.7346C19.5009 11.6798 19.4879 11.6255 19.4627 11.5749C19.4375 11.5243 19.4006 11.4783 19.354 11.4396L16.172 8.78791C16.1255 8.74923 16.0703 8.71855 16.0096 8.69764C15.9489 8.67672 15.8838 8.66598 15.8181 8.66602C15.7524 8.66605 15.6874 8.67688 15.6267 8.69786C15.566 8.71885 15.5109 8.74959 15.4645 8.78833C15.4181 8.82707 15.3813 8.87305 15.3562 8.92365C15.3311 8.97424 15.3182 9.02846 15.3182 9.08321C15.3183 9.13796 15.3313 9.19216 15.3564 9.24273C15.3816 9.29329 15.4185 9.33923 15.465 9.37791L18.293 11.7346L15.465 14.0912C15.4185 14.1299 15.3816 14.1759 15.3564 14.2264C15.3313 14.277 15.3183 14.3312 15.3182 14.386C15.3182 14.4407 15.3311 14.4949 15.3562 14.5455C15.3813 14.5961 15.4181 14.6421 15.4645 14.6808C15.5109 14.7196 15.566 14.7503 15.6267 14.7713C15.6874 14.7923 15.7524 14.8031 15.8181 14.8031C15.8838 14.8032 15.9489 14.7924 16.0096 14.7715C16.0703 14.7506 16.1255 14.7199 16.172 14.6812L19.354 12.0296ZM-4 12.1512H19V11.3179H-4V12.1512Z"
          fill="currentColor"
        />
      </g>
      <defs>
        <clipPath>
          <rect
            width="20"
            height="20"
            fill="currentColor"
            transform="translate(0 0.33252)"
          />
        </clipPath>
      </defs>
    </SvgIcon>
  );
}

export { ArrowRightIcon };
