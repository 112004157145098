import { DownloadSection } from "./components/DownloadSection";
import { VideoSection } from "./components/VideoSection";
import { Practice } from "@/types/apiContract/practice";
import { Box, Container, Typography, styled } from "@mui/material";

type Props = {
  provider: {
    name: string;
    type?: Practice["type"];
    address?: {
      street: string;
      city: string;
      state: string;
    };
  };
};

export const Hero = (props: Props) => {
  const { provider } = props;

  const addressString = `${provider.address?.street}, ${provider.address?.city}, ${provider.address?.state}`;

  const providerType = provider.type?.split("_").join(" ").toLowerCase();
  return (
    <Body>
      <Container>
        <Inner>
          {/* LEFT SIDE */}
          <LeftContainer>
            <WelcomeText mb="6px">Welcome,</WelcomeText>
            <BigNameText mb="24px">{provider.name}</BigNameText>
            <Box display="flex" alignItems="center">
              <AttributeText>{providerType}</AttributeText>
              <Typography variant="body2Emphasized" px="4px">
                ⬩
              </Typography>
              <AttributeText>{addressString}</AttributeText>
            </Box>
          </LeftContainer>

          {/* RIGHT SIDE */}
          <RightContainer>
            <DownloadSection provider={{ type: provider.type }} />
            {provider.type !== "DERMATOLOGY" && <VideoSection />}
          </RightContainer>
        </Inner>
      </Container>
    </Body>
  );
};

const Body = styled(Box)`
  background: #171c17;
  color: #fff;
  margin: 0px 8px;
  border-radius: 8px;
`;

const Inner = styled(Box)`
  display: flex;
  gap: 30px;
  justify-content: space-between;
  padding: 50px 8px;
  ${(props) => props.theme.breakpoints.down("tablet")} {
    flex-direction: column;
    align-items: center;
    gap: 48px;
    padding: 48px 0;
  }
`;

const LeftContainer = styled(Box)`
  display: flex;
  flex-direction: column;
  justify-content: center;
  ${(props) => props.theme.breakpoints.down("tablet")} {
    align-items: flex-start;
    width: 100%;
  }
`;

const RightContainer = styled(Box)`
  display: flex;
  gap: 24px;
  ${(props) => props.theme.breakpoints.down("tablet")} {
    gap: 12px;
    width: 100%;
  }
`;

const WelcomeText = styled(Typography)`
  font-size: 24px;
  font-weight: 300;
  line-height: 50px;
  letter-spacing: 0px;
  color: rgba(255, 255, 255, 0.56);
`;

const BigNameText = styled(Typography)`
  font-family: The Seasons;
  font-size: 48px;
  font-weight: 400;
  line-height: 53px;
  letter-spacing: 0px;
  max-width: 500px;
`;

const AttributeText = styled(Typography)`
  font-size: 16px;
  font-weight: 300;
  line-height: 24px;
  letter-spacing: 0;
  text-transform: capitalize;
`;
