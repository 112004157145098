import { SvgIcon, SvgIconProps } from "@mui/material";

function EditIcon(props: SvgIconProps) {
  return (
    <SvgIcon
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M16.5278 6.19018C16.0398 5.70206 15.2485 5.70188 14.7603 6.18978L14.3553 6.59449L17.4051 9.64427L17.8094 9.24008C18.2977 8.75199 18.2978 7.96048 17.8097 7.47225L16.5278 6.19018ZM16.3444 10.7049L13.2945 7.65499L5.84789 15.1024C5.83281 15.1174 5.82422 15.138 5.82422 15.1593V16.9259C5.82422 17.6163 6.38386 18.1759 7.07422 18.1759H8.8408C8.86213 18.1759 8.88258 18.1674 8.89765 18.1523L16.3444 10.7049ZM18.8699 10.3009C19.9441 9.22712 19.9443 7.48581 18.8704 6.4117L17.5886 5.12963C16.515 4.05577 14.7741 4.05537 13.7 5.12874L4.78728 14.0417C4.49094 14.338 4.32431 14.7399 4.32422 15.159V16.9259C4.32422 18.4447 5.55544 19.6759 7.07422 19.6759H8.84096C9.26005 19.6758 9.66211 19.5093 9.95842 19.2129L18.8699 10.3009Z"
        fill="currentColor"
      />
    </SvgIcon>
  );
}

export { EditIcon };
