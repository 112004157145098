// --- NUMBERS ---
export const formatNum = (val: number, options?: { isDollars?: boolean }) => {
  const isNegative = val < 0;
  const abs = Math.abs(val || 0);
  const isInteger = abs % 1 === 0;

  const formattedNum = abs.toLocaleString(
    "en-US",
    options?.isDollars
      ? {
          style: "currency",
          currency: "USD",
        }
      : {}
  );
  const [integer] = formattedNum.split(".");

  let display = isInteger ? integer : formattedNum;
  if (isNegative) {
    display = `-${display}`;
  }

  return display;
};

export const abbrAndFormatNum = (
  val: number,
  options?: { isDollars?: boolean; significantDigits?: number }
) => {
  const { num, suffix } = abbrNum(val, options?.significantDigits);
  const isNegative = num < 0;
  const prefix = (isNegative ? "-" : "") + (options?.isDollars ? "$" : "");
  return `${prefix}${Math.abs(num)}${suffix}`;
};

export const abbrNum: any = (val: number, significantDigits = 3) => {
  if (significantDigits < 3) {
    throw new Error("The significantDigits option must be 3 or greater");
  }

  const abbrParts = {
    num: 0,
    numStr: "",
    suffix: "",
  };

  let factor = 1;
  if (val >= 1000000000000) {
    console.warn(
      "The abbrNum function cannot handle numbers in the trillions."
    );
    abbrParts.num = val;
    abbrParts.numStr = val.toString();
    return abbrParts;
  } else if (val >= 1000000000) {
    factor = 1000000000;
    abbrParts.suffix = "B";
  } else if (val >= 1000000) {
    factor = 1000000;
    abbrParts.suffix = "M";
  } else if (val >= 1000) {
    factor = 1000;
    abbrParts.suffix = "K";
  }

  let number = val / factor;
  const hasDecimal = number % 1 !== 0;
  const isNegative = number < 0;
  const desiredLength =
    significantDigits + (hasDecimal ? 1 : 0) + (isNegative ? 1 : 0);
  let numStr = number.toString();

  if (numStr.length > desiredLength) {
    const wholeNumLength = numStr.indexOf(".");
    const decimalPrecision = desiredLength - wholeNumLength - 1;
    const numTokensReversed = numStr
      .substring(0, desiredLength + 1)
      .split("")
      .reverse();
    if (parseInt(numTokensReversed[0], 10) >= 5) {
      numTokensReversed[0] = "9";
    }
    number = parseFloat(numTokensReversed.reverse().join(""));
    numStr = number.toFixed(decimalPrecision);
    const regEx = factor === 1 ? /\.00$/ : /(?:\.00?|(\.[1-9])0)$/;
    numStr = numStr.replace(regEx, "$1");

    if (
      numStr.length >
      significantDigits + (numStr.indexOf(".") !== -1 ? 1 : 0)
    ) {
      return abbrNum(parseFloat(numStr) * factor, significantDigits);
    }
  }

  abbrParts.num = parseFloat(numStr);

  abbrParts.numStr = numStr;

  return abbrParts;
};

// --- PHONE NUMBERS ---
const DEFAULT_PHONE_NUM_INTL_CODE = "1";
export const formatPhoneNum = (
  value: string,
  {
    autoIntlCode = false,
    intlCode = DEFAULT_PHONE_NUM_INTL_CODE,
  }: { autoIntlCode?: boolean; intlCode?: string } = {}
) => {
  //Filter only numbers from the input
  let cleaned = ("" + value).replace(/\D/g, "");

  // remove intl code to setup for auto autoIntlCode
  // - handles if user manually types in intl code and backspacing issue
  if (autoIntlCode && cleaned.startsWith(intlCode)) {
    cleaned = cleaned.substring(intlCode.length);
  }

  //Check if the input is of correct
  const isPhoneNumRegex = new RegExp(
    `^(${intlCode}|)?(\\d{3})(\\d{3})(\\d{4})$`
  );
  let match = cleaned.match(isPhoneNumRegex);

  if (match) {
    //Remove the matched extension code
    let intlCodeText = "";
    if (autoIntlCode) {
      intlCodeText = match[1] ? `+${match[1]} ` : "";
      intlCodeText = `+${intlCode} `;
    }
    return [intlCodeText, "(", match[2], ") ", match[3], "-", match[4]].join(
      ""
    );
  }

  return value;
};

// transforms something like (714) 333-5555 to +17143335555
export const cleanPhoneNum = (
  val: string,
  {
    applyIntlCode = DEFAULT_PHONE_NUM_INTL_CODE,
  }: { applyIntlCode?: string } = {}
) => {
  const cleaned = val.replace(/\D/g, "");
  if (applyIntlCode) {
    return `+${applyIntlCode}${cleaned}`;
  }
  return cleaned;
};
