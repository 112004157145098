import {
  AuthError,
  confirmResetPassword as cognitoConfirmResetPassword,
  confirmSignIn as cognitoConfirmSignIn,
  resetPassword as cognitoResetPassword,
  signIn as cognitoSignIn,
  signOut as cognitoSignOut,
  updatePassword as cognitoUpdatePassword,
  type UpdatePasswordInput,
  ConfirmResetPasswordInput,
  ConfirmSignInInput,
  ResetPasswordInput,
  type SignInInput,
  fetchAuthSession as cognitoFetchAuthSession,
} from "aws-amplify/auth";

async function signIn(signInInput: SignInInput) {
  try {
    const { isSignedIn, nextStep } = await cognitoSignIn(signInInput);
    return { isSignedIn, nextStep, authErrorMsg: null };
  } catch (err: any) {
    if (err instanceof AuthError) {
      return { isSignedIn: false, nextStep: null, authErrorMsg: err.message };
    }
    throw Error(err);
  }
}

async function signOut() {
  return cognitoSignOut();
}

// attempt to get user's auth tokens, if it fails - log them out
async function fetchAuthSession(
  fetchAuthSessionInput: { forceRefresh?: boolean } = {}
) {
  try {
    const { tokens } = await cognitoFetchAuthSession(fetchAuthSessionInput);
    if (!tokens) throw Error("expected tokens");

    return { tokens };
  } catch (e: any) {
    console.warn(
      "Session expired or failed to fetch auth session, logging out..."
    );
    await signOut();
    throw Error(e); // not necessary, just putting it here to make typescript happy (guarantee tokens are returned)
  }
}

async function confirmSignIn(confirmSignInInput: ConfirmSignInInput) {
  try {
    const { isSignedIn, nextStep } = await cognitoConfirmSignIn(
      confirmSignInInput
    );
    return { isSignedIn, nextStep, authErrorMsg: null };
  } catch (err: any) {
    if (err instanceof AuthError) {
      return { isSignedIn: false, nextStep: null, authErrorMsg: err.message };
    }
    throw Error(err);
  }
}

const resetPassword = async (resetPasswordInput: ResetPasswordInput) => {
  try {
    const { isPasswordReset, nextStep } = await cognitoResetPassword(
      resetPasswordInput
    );
    return { isPasswordReset, nextStep, authErrorMsg: null };
  } catch (err: any) {
    if (err instanceof AuthError) {
      return {
        isPasswordReset: false,
        nextStep: null,
        authErrorMsg: err.message,
      };
    }
    throw Error(err);
  }
};

const confirmResetPassword = async (
  confirmResetPasswordInput: ConfirmResetPasswordInput
) => {
  try {
    await cognitoConfirmResetPassword(confirmResetPasswordInput);
    return { authErrorMsg: null };
  } catch (err: any) {
    if (err instanceof AuthError) {
      return { authErrorMsg: err.message };
    }
    throw Error(err);
  }
};

const updatePassword = async (updatePasswordInput: UpdatePasswordInput) => {
  try {
    await cognitoUpdatePassword(updatePasswordInput);
    return { authErrorMsg: null };
  } catch (err: any) {
    if (err instanceof AuthError) {
      let message = err.message;

      // for some reason cognito returns this message if password is incorrect (even though username is not involved in this call)
      if (err.message === "Incorrect username or password.") {
        message = "Incorrect password.";
      }
      return { authErrorMsg: message };
    }
    throw Error(err);
  }
};

export {
  signIn,
  signOut,
  fetchAuthSession,
  confirmSignIn,
  resetPassword,
  confirmResetPassword,
  updatePassword,
};
