import { MembersTable } from "./components/MembersTable";
import { GlobalContext } from "@/components/GlobalContext";
import { CloseIcon } from "@/components/UIKit/Icons";
import {
  SearchMembersResponse,
  SearchedMember,
} from "@/types/apiContract/member";
import {
  Box,
  Container,
  InputBase,
  Typography,
  styled,
  IconButton,
} from "@mui/material";
import { useInfiniteQuery } from "@tanstack/react-query";
import { useDebounce } from "@uidotdev/usehooks";
import axios from "axios";
import { useContext, useState } from "react";

const SEARCH_PAGE_SIZE = 20;
export const MembersList = () => {
  const { loggedInProvider } = useContext(GlobalContext);
  const [searchVal, setSearchVal] = useState("");
  const [focused, setFocused] = useState(false);
  const debouncedSearchVal = useDebounce(searchVal, 500);

  const searchMembersQuery = useInfiniteQuery({
    queryKey: ["members", debouncedSearchVal],
    queryFn: async ({ pageParam }) => {
      return axios.get<SearchMembersResponse>(
        `/practices/${loggedInProvider.id}/members?search=${debouncedSearchVal}&page=${pageParam}&size=${SEARCH_PAGE_SIZE}`
      );
    },
    initialPageParam: 0,
    getNextPageParam: (lastPage) => lastPage.data.page.number + 1,
  });
  const searchedMembers: SearchedMember[] = [];
  const totalResults =
    searchMembersQuery.data?.pages[0].data.page.totalElements;
  searchMembersQuery.data?.pages.forEach((x, i) => {
    return x.data.content.forEach((y) => {
      return searchedMembers.push(y);
    });
  });
  const hasMoreResults = searchedMembers.length < (totalResults || 0);

  return (
    <Body>
      <Container>
        <SearchText focused={focused}>SEARCH</SearchText>
        <SearchContainer>
          <SearchTextField
            value={searchVal}
            onFocus={() => {
              setFocused(true);
            }}
            onBlur={() => {
              setFocused(false);
            }}
            onChange={(e) => setSearchVal(e.target.value)}
            placeholder="Search by member ID, name or date of birth"
            endAdornment={
              searchVal.length > 0 && (
                <IconButton
                  sx={{ fontSize: "inherit" }}
                  onClick={() => {
                    setSearchVal("");
                  }}
                >
                  <CloseIcon fontSize="inherit" />
                </IconButton>
              )
            }
            fullWidth
          />
        </SearchContainer>
        <TableContainer>
          <MembersTable
            members={searchedMembers}
            isLoading={searchMembersQuery.isLoading}
            hasMoreResults={hasMoreResults}
            onLoadMore={() => {
              searchMembersQuery.fetchNextPage();
            }}
          />
        </TableContainer>
      </Container>
    </Body>
  );
};

const Body = styled(Box)`
  padding-top: 40px;
  padding-bottom: 32px;
`;

const SearchText = styled(Typography, {
  shouldForwardProp: (prop) => prop !== "focused",
})<{
  focused: boolean;
}>`
  font-size: 16px;
  font-weight: 400;
  line-height: 20px;
  letter-spacing: 0.5px;
  color: ${(props) =>
    props.focused ? "#71803C" : props.theme.palette.text.primary};
`;

const SearchContainer = styled(Box)`
  margin-bottom: 24px;
  ${(props) => props.theme.breakpoints.down("tablet")} {
    margin-bottom: 8px;
  }
`;

const SearchTextField = styled(InputBase)`
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);
  font-family: The Seasons;
  font-size: 32px;
  font-weight: 400;
  line-height: 38px;
  letter-spacing: 0;
  && {
    .MuiInputBase-input {
      padding: 16px 0;
      border-right: unset;
      ${(props) => props.theme.breakpoints.down("tablet")} {
        padding: 24px 0 8px 0;
      }
    }
  }
`;

const TableContainer = styled(Box)`
  min-height: 350px;

  ${(props) => props.theme.breakpoints.down("tablet")} {
    min-height: unset;
  }
`;
