import { ForgotPasswordForm } from "./components/ForgotPasswordForm";
import { LoginForm, LoginFormInputs } from "./components/LoginForm";
import {
  SetPasswordForm,
  SetPasswordFormInputs,
} from "./components/SetPasswordForm";
import { confirmSignIn, signIn } from "@/components/GlobalContext/utils/auth";
import { snackIt } from "@/components/SnackbarManager";
import { REMEMBER_ME_EXPIRY_TIME_LOCAL_STORAGE_KEY } from "@/constants/localStorage";
import { addHours } from "date-fns";
import { useState } from "react";

export const LoginView = () => {
  const [isOnSetPasswordStep, setIsOnSetPasswordStep] = useState(false);
  const [forgotPasswordEmail, setForgotPasswordEmail] = useState<string>();
  const [isRememberMeChecked, setIsRememberMeChecked] = useState(false);

  const onLoginSubmit = async (data: LoginFormInputs) => {
    const { isSignedIn, nextStep, authErrorMsg } = await signIn({
      username: data.email.toLowerCase(), // TODO: this is a temporary fix - case sensitivity should be handled on the backend
      password: data.password,
    });
    if (isSignedIn) {
      applyRememberMeLogout(data);
      return; // success
    }
    if (nextStep?.signInStep === "CONFIRM_SIGN_IN_WITH_NEW_PASSWORD_REQUIRED") {
      setIsRememberMeChecked(data.isRememberMeChecked);
      setIsOnSetPasswordStep(true);
      return;
    }

    // figure out why sign in failed
    let signInFeedbackMsg = "Failed sign in";
    if (nextStep?.signInStep && nextStep.signInStep !== "DONE") {
      signInFeedbackMsg = `Unexpected sign in step: ${nextStep.signInStep}`;
    }
    if (authErrorMsg) {
      signInFeedbackMsg = authErrorMsg;
    }

    if (!isSignedIn) {
      snackIt.default({
        severity: "error",
        message: signInFeedbackMsg,
      });
    }
  };

  const onSetPasswordSubmit = async (data: SetPasswordFormInputs) => {
    const { isSignedIn, nextStep, authErrorMsg } = await confirmSignIn({
      challengeResponse: data.newPassword,
    });
    if (isSignedIn) {
      applyRememberMeLogout({ isRememberMeChecked });
      return; // success
    }

    // figure out why sign in failed
    let confirmSignInFeedbackMsg = "Failed to confirm sign in";
    if (nextStep?.signInStep && nextStep.signInStep !== "DONE") {
      confirmSignInFeedbackMsg = `Unexpected sign in step: ${nextStep.signInStep}`;
    }
    if (authErrorMsg) {
      confirmSignInFeedbackMsg = authErrorMsg;
    }

    if (!isSignedIn) {
      snackIt.default({
        severity: "error",
        message: confirmSignInFeedbackMsg,
      });
    }
  };

  if (isOnSetPasswordStep) {
    return <SetPasswordForm onSubmit={onSetPasswordSubmit} />;
  }

  if (forgotPasswordEmail !== undefined) {
    return (
      <ForgotPasswordForm
        email={forgotPasswordEmail}
        onExit={() => setForgotPasswordEmail(undefined)}
      />
    );
  }

  return (
    <LoginForm
      onSubmit={onLoginSubmit}
      onForgotPasswordClick={(email) => setForgotPasswordEmail(email || "")}
    />
  );
};

// user will get logged out after 1 hour if "remember me" is unchecked
const applyRememberMeLogout = (data: { isRememberMeChecked: boolean }) => {
  if (!data.isRememberMeChecked) {
    const logoutTime = addHours(new Date(), 1);
    window.localStorage.setItem(
      REMEMBER_ME_EXPIRY_TIME_LOCAL_STORAGE_KEY,
      logoutTime.getTime().toString()
    );
  } else {
    window.localStorage.removeItem(REMEMBER_ME_EXPIRY_TIME_LOCAL_STORAGE_KEY); // should never be the case on login, but clearing just to be sure
  }
};
