import {
  FormControl,
  FormControlLabel,
  FormControlLabelProps,
  FormHelperText,
  FormLabel,
  RadioGroup as MuiRadioGroup,
  RadioGroupProps as MuiRadioGroupProps,
  Radio,
  styled,
} from "@mui/material";
import { useId } from "react";
import {
  Controller,
  UseControllerProps,
  UseFormRegisterReturn,
} from "react-hook-form";

export type RadioGroupProps = MuiRadioGroupProps & {
  control?: UseControllerProps<any>["control"];
  register?: UseFormRegisterReturn<any>;
  label?: string | React.ReactElement;
  options: {
    value: string | number;
    label?: string | number | React.ReactElement;
  }[];
  formControlLabelProps?: Partial<FormControlLabelProps>;
  allowDeselect?: boolean;
};

// <RadioGroup /> component that can be easily integrated with react-hook-form (ie: errors)
// - goal is to reduce as much boilerplate in forms as possible
export const RadioGroup = (props: RadioGroupProps) => {
  const {
    control,
    register,
    label,
    options,
    formControlLabelProps,
    sx,
    allowDeselect = false,
    ...radioGroupProps
  } = props;

  const inputId = useId();

  // Ensure options have unique values
  const uniqueOptions = options.filter(
    (option, index, self) =>
      index === self.findIndex((t) => t.value === option.value)
  );

  if (control && register) {
    return (
      <Controller
        control={control}
        name={register.name}
        defaultValue={null} // Fix for uncontrolled to controlled warning
        render={({ field, fieldState: { error } }) => {
          const { onChange, onBlur, name, value, ref } = field;

          // Render radio options with onClick handler
          const renderedOptions = uniqueOptions.map((option) => (
            <FormControlLabel
              key={option.value}
              value={option.value}
              control={
                <Radio
                  onClick={() => {
                    if (allowDeselect && value === option.value) {
                      // Deselect the radio button
                      onChange(null);
                    }
                  }}
                />
              }
              label={option.label || option.value}
              {...formControlLabelProps}
            />
          ));

          return (
            <FormControl focused={false} error={Boolean(error)} sx={sx}>
              {label && (
                <FormLabel id={inputId} sx={{ mb: "14px" }}>
                  {label}
                </FormLabel>
              )}
              <MuiRadioGroup
                name={name}
                value={value ?? ""}
                onChange={(e) => {
                  const selectedValue = e.target.value;
                  if (!(allowDeselect && selectedValue === value)) {
                    // Only change if it's a new selection
                    onChange(selectedValue);
                  }
                }}
                onBlur={onBlur}
                ref={ref} // Fix for focus issue on error
                aria-labelledby={inputId}
                {...radioGroupProps}
                sx={(theme) => ({
                  ...(error?.message && {
                    "&& .MuiFormControlLabel-root": {
                      borderColor: theme.palette.error.main,
                    },
                  }),
                })}
              >
                {renderedOptions}
              </MuiRadioGroup>
              {error?.message && (
                <FormHelperText sx={{ mt: "12px" }}>
                  {error.message}
                </FormHelperText>
              )}
            </FormControl>
          );
        }}
      />
    );
  }

  // If control and register are not provided, render without them
  return (
    <MuiRadioGroup sx={sx} {...radioGroupProps}>
      {uniqueOptions.map((option) => (
        <FormControlLabel
          key={option.value}
          value={option.value}
          control={<Radio />}
          label={option.label || option.value}
          {...formControlLabelProps}
        />
      ))}
    </MuiRadioGroup>
  );
};

export const BoxedRadioGroup = styled(RadioGroup, {
  shouldForwardProp: (prop) => prop !== "styledProps",
})<{
  styledProps?: { isOutlined?: boolean };
}>`
  margin-left: 12px;
  display: flex;
  justify-content: space-between;
  gap: 20px;

  .MuiFormControlLabel-root {
    flex: 1;
    background: #fff;
    padding: 10px;
    border-radius: 8px;
    margin-right: 0px;
    box-shadow: 0px 2px 12px 0px rgba(181, 176, 168, 0.08);
    border: 1px solid rgba(0, 0, 0, 0);

    ${(props) =>
      props.styledProps?.isOutlined &&
      `
        border: 1px solid rgba(233, 235, 237, 1);
        box-shadow: none;
      `}
  }
`;
