import joyaLogo from "@/assets/joya-logo-white.svg";
import useBreakpointCheck from "@/hooks/useBreakpointCheck";
import { shadowedTextFieldsSx } from "@/themes/customization/inputs";
import { shouldForwardProp } from "@/utils/shouldForwardProp";
import { Box, Typography } from "@mui/material";
import { styled } from "@mui/material/styles";
import { PropsWithChildren } from "react";

const CONTACT_EMAIL = "provider@joyahealth.com";

export const AuthLayout = (props: PropsWithChildren) => {
  const isMobile = useBreakpointCheck("tablet");

  const d = new Date();
  const year = d.getFullYear();

  return (
    <Body $isMobile={isMobile}>
      <BrandSection $isMobile={isMobile}>
        <Box
          height="100%"
          display="flex"
          flexDirection="column"
          alignItems="center"
        >
          <img src={joyaLogo} height="38px" alt="joya logo" />
          {!isMobile && (
            <>
              <Box flex="1" display="grid" sx={{ placeItems: "center" }}>
                <Typography variant="h2" textAlign="center" width="390px">
                  Your personalized growth partner
                </Typography>
              </Box>
              <Box>
                <Typography variant="body3">
                  {year} Joya © All rights reserved
                </Typography>
              </Box>
            </>
          )}
        </Box>
      </BrandSection>
      <FormSection $isMobile={isMobile} sx={shadowedTextFieldsSx}>
        <ChildWrapper $isMobile={isMobile}>{props.children}</ChildWrapper>
        <Footer />
      </FormSection>
      {isMobile && (
        <Box mt="24px" display="flex" justifyContent="center" color="white">
          <Typography variant="body3">
            {year} Joya © All rights reserved
          </Typography>
        </Box>
      )}
    </Body>
  );
};

function Footer() {
  return (
    <FooterContainer>
      <FooterTypography>
        <Typography
          variant="inherit"
          component="span"
          pr="8px"
          color="text.secondary"
        >
          Need Help? Contact us at
        </Typography>
        <Typography
          variant="inherit"
          component="span"
          sx={{ textDecoration: "underline" }}
        >
          <AnchorTag href={`mailto:${CONTACT_EMAIL}`}>
            {CONTACT_EMAIL}
          </AnchorTag>
        </Typography>
      </FooterTypography>
    </FooterContainer>
  );
}

type MobileProps = { $isMobile: boolean };

const BODY_PADDING = 16;
const Body = styled("div", { shouldForwardProp })<MobileProps>`
  display: flex;
  width: 100%;
  height: 100vh;
  background: #171c17;
  padding: ${BODY_PADDING}px;
  gap: 16px;

  > * {
    ${(props) =>
      !props.$isMobile &&
      `
      height: 100%;
    `}
    box-sizing: border-box;
  }

  ${(props) => props.$isMobile && "flex-direction: column;"}
  ${(props) => props.$isMobile && "min-height: 100vh;"}
  ${(props) => props.$isMobile && "height: auto;"}
  ${(props) => props.$isMobile && "gap: 0px;"}
`;

const BrandSection = styled("div", { shouldForwardProp })<MobileProps>`
  background-size: cover;
  padding: ${(props) => (props.$isMobile ? "20px 0 44px" : "16px 32px")};
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
  flex: 1;
  box-sizing: border-box;
  color: white;
  ${(props) => props.$isMobile && "margin-bottom: -24px;"}
  ${(props) => props.$isMobile && "flex: 0 1 auto;"}
`;

const FormSection = styled(Box, { shouldForwardProp })<MobileProps>`
  background: #f6f5f4;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  width: 800px;
  border-radius: 16px;
  ${(props) => !props.$isMobile && "overflow: auto;"}
  ${(props) => props.$isMobile && "height: 100%;"}
  ${(props) => props.$isMobile && "width: 100%;"}
  ${(props) => props.$isMobile && "max-width: 100%;"}
  ${(props) => props.$isMobile && "flex: 1;"}
  > * {
    width: 100%;
  }
`;

const FOOTER_HEIGHT = "77px";
const ChildWrapper = styled("div", { shouldForwardProp })<MobileProps>`
  padding: ${(props) => (props.$isMobile ? "24px" : `${FOOTER_HEIGHT} 48px 0`)};
  box-sizing: border-box;
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;

  > * {
    width: 560px;
    max-width: 100%;
    margin: 0 auto;
  }
`;

const FooterContainer = styled(Box)`
  padding: 32px 20px;
  box-sizing: border-box;
  text-align: center;
`;

const FooterTypography = styled(Typography)`
  font-size: 13px;
  font-weight: 400;
  line-height: 13px;
  letter-spacing: 0.06;
`;

const AnchorTag = styled("a")`
  color: inherit;
`;
