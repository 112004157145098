import { GlobalContext } from "../../components/GlobalContext";
import { LoginView } from "./View";
import { useContext } from "react";
import { Navigate } from "react-router";

export const LoginContainer = () => {
  const { loggedInProvider } = useContext(GlobalContext);

  if (loggedInProvider) {
    if (loggedInProvider.name) return <Navigate to="/dashboard" replace />;
  }

  return <LoginView />;
};
