import { EditProfileModal } from "./components/EditProfileModal";
import { LoggedInProvider } from "@/components/GlobalContext";
import { signOut } from "@/components/GlobalContext/utils/auth";
import { AddInsuranceModal } from "@/components/Modals/AddInsuranceModal";
import { ChangePasswordModal } from "@/components/Modals/ChangePasswordModal";
import { ManageAccessModal } from "@/components/Modals/ManageAccessModal";
import { PracticePromotionModal } from "@/components/Modals/PracticePromotionModal";
import {
  AddIcon,
  ChevronDownIcon,
  EditIcon,
  InfoIcon,
  PeopleIcon,
} from "@/components/UIKit/Icons";
import useOpenable from "@/hooks/useOpenable";
import { LoadingButton } from "@mui/lab";
import {
  Box,
  Button,
  Divider,
  Fade,
  Tooltip,
  Typography,
  styled,
} from "@mui/material";
import { useState } from "react";
import { useSearchParams } from "react-router-dom";

type Props = {
  user: Pick<LoggedInProvider, "email" | "roles" | "type">;
};
export const UserSection = (props: Props) => {
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [isLoggingOut, setIsLoggingOut] = useState(false);
  const [searchParams, setSearchParams] = useSearchParams();

  const {
    isOpen: isPracticePromotionOpen,
    onOpen: onPracticePromotionOpen,
    onClose: onPracticePromotionClose,
  } = useOpenable();

  const {
    isOpen: isInsuranceOpen,
    onOpen: onInsuranceOpen,
    onClose: onInsuranceClose,
  } = useOpenable();

  const {
    isOpen: isManageAccessOpen,
    onOpen: onManageAccessOpen,
    onClose: onManageAccessClose,
  } = useOpenable();

  const {
    isOpen: isChangePasswordOpen,
    onOpen: onChangePasswordOpen,
    onClose: onChangePasswordClose,
  } = useOpenable();

  const shouldEditProfileStartOpened = Boolean(
    searchParams.get("editingProfile") === "1"
  );
  const {
    isOpen: isEditProfileOpen,
    onOpen: onEditProfileOpen,
    onClose: onEditProfileCloseFn,
  } = useOpenable({
    shouldStartOpen: shouldEditProfileStartOpened,
  });
  const onEditProfileClose = () => {
    onEditProfileCloseFn();
    searchParams.delete("editingProfile");
    setSearchParams(searchParams);
  };
  const onLogOutClick = async () => {
    setIsLoggingOut(true);
    try {
      await signOut();
    } catch (e: any) {
      setIsLoggingOut(false);
      throw Error(e);
    }

    // now just wait for log out to redirect...
  };
  const isAdmin = props.user.roles.includes("ProviderAdmin");
  const isDermatology = props.user.type?.includes("DERMATOLOGY");
  return (
    <>
      {isManageAccessOpen && (
        <ManageAccessModal onClose={onManageAccessClose} />
      )}
      {isPracticePromotionOpen && (
        <PracticePromotionModal
          onComplete={async () => onPracticePromotionClose()}
          onClose={onPracticePromotionClose}
        />
      )}
      {isInsuranceOpen && <AddInsuranceModal onClose={onInsuranceClose} />}
      {isEditProfileOpen && (
        <EditProfileModal
          hasNoOpenAnimation={shouldEditProfileStartOpened}
          onClose={onEditProfileClose}
          isDermatology={isDermatology}
        />
      )}
      {isChangePasswordOpen && (
        <ChangePasswordModal onClose={onChangePasswordClose} />
      )}
      <Body
        justifySelf="end"
        onMouseOver={() => setIsMenuOpen(true)}
        onMouseLeave={() => setIsMenuOpen(false)}
      >
        <Box display="flex" alignItems="center" gap="4px">
          <Typography variant="body3Emphasized">{props.user.email}</Typography>
          <ChevronDownIcon
            sx={{
              marginTop: "3px",
              fontSize: "22px",
              transition: "0.2s",
              ...(isMenuOpen && {
                transform: "rotate(180deg)",
              }),
            }}
          />
        </Box>
        <Fade in={isMenuOpen} timeout={{ enter: 0, exit: 0 }}>
          <UserMenuContainer>
            <UserMenu>
              <Box mb="12px">
                <UserMenuAction>
                  <UserMenuActionButton
                    startIcon={<AddIcon />}
                    onClick={() => onPracticePromotionOpen()}
                  >
                    Add Promotion
                  </UserMenuActionButton>
                </UserMenuAction>
                {isDermatology && (
                  <UserMenuAction>
                    <UserMenuActionButton
                      startIcon={<AddIcon />}
                      onClick={() => onInsuranceOpen()}
                    >
                      Add Insurance
                    </UserMenuActionButton>
                  </UserMenuAction>
                )}
                <UserMenuAction>
                  <UserMenuActionButton
                    startIcon={<PeopleIcon />}
                    onClick={() => onManageAccessOpen()}
                    disabled={!isAdmin}
                  >
                    Manage Access
                  </UserMenuActionButton>
                  {!isAdmin && (
                    <Tooltip
                      placement="bottom-start"
                      PopperProps={{
                        modifiers: [
                          { name: "offset", options: { offset: [10, 0] } },
                        ],
                      }}
                      componentsProps={{
                        tooltip: { sx: { maxWidth: "215px" } },
                      }}
                      title="This is disabled because you do not have an admin permission to manage access. Please ask an admin to grant you the necessary permission."
                    >
                      <Box display="flex">
                        <InfoIcon sx={{ fontSize: "16px" }} color="secondary" />
                      </Box>
                    </Tooltip>
                  )}
                </UserMenuAction>
                <UserMenuAction>
                  <UserMenuActionButton
                    startIcon={<EditIcon />}
                    onClick={() => onChangePasswordOpen()}
                  >
                    Change Password
                  </UserMenuActionButton>
                </UserMenuAction>
                <UserMenuAction>
                  <UserMenuActionButton
                    startIcon={<EditIcon />}
                    onClick={() => onEditProfileOpen()}
                  >
                    Edit Profile
                  </UserMenuActionButton>
                </UserMenuAction>
              </Box>
              <Divider sx={{ mb: "16px" }} />
              <Box pb="8px" mb="12px">
                <NeedCustomerServiceText color="text.secondary" mb="8px">
                  Do you have issues and need customer service? Contact us
                </NeedCustomerServiceText>
                <PhoneNumberText href="tel:866-414-5692">
                  866-414-JOYA
                </PhoneNumberText>
              </Box>
              <LoadingButton
                variant="contained"
                color="secondary"
                onClick={onLogOutClick}
                fullWidth
                loading={isLoggingOut}
              >
                Sign Out
              </LoadingButton>
            </UserMenu>
          </UserMenuContainer>
        </Fade>
      </Body>
    </>
  );
};

const UserMenuContainer = styled(Box)`
  padding-top: 16px;
  position: absolute;
  right: 0px;
  transition-duration: 0.2s;
  z-index: 10;
`;

const UserMenu = styled(Box)`
  background: #fff;
  width: 240px;
  padding: 16px;
  border-radius: 10px;
  box-shadow: 0px 2px 16px 0px #b5b0a81a;
  color: ${(props) => props.theme.palette.text.primary};
`;

const Body = styled(Box)`
  position: relative;
`;

const UserMenuAction = styled(Box)`
  display: flex;
  align-items: center;
  margin-bottom: 2px;
  :last-of-type {
    margin-bottom: 0px;
  }
`;

const UserMenuActionButton = styled(Button)`
  padding: 8px 12px;
  justify-content: start;
  opacity: 0.8;
  border-radius: 8px;
  :hover {
    background: #f6f5f4;
    color: inherit;
    opacity: 1;
  }
`;
UserMenuActionButton.defaultProps = {
  variant: "text",
  fullWidth: true,
};

const NeedCustomerServiceText = styled(Typography)`
  font-size: 12px;
  font-weight: 400;
  line-height: 19px;
  letter-spacing: 0px;
`;
const PhoneNumberText = styled("a")`
  font-size: 16px;
  font-weight: 600;
  line-height: 24px;
  letter-spacing: 0;
  text-decoration: none;
  color: ${(props) => props.theme.palette.text.primary};
`;
