import { Box } from "@mui/material";
import { styled } from "@mui/material/styles";

export const FormRow = styled(Box)`
  display: flex;
  gap: 12px;

  > * {
    flex: 1;
  }
`;
FormRow.defaultProps = {
  mb: "20px", // doing it here so it can overriden
};

export const FormCard = styled(Box)`
  background: #fff;
  border: 1px solid #e9ebed;
  border-radius: 12px;
`;
FormCard.defaultProps = {
  p: "20px", // doing it here so it can overriden
  mb: "12px",
};
