import { Box, Typography, styled } from "@mui/material";
import { PropsWithChildren } from "react";

type Props = {
  title: string;
  subTitle?: string;
};

export const Result = (props: PropsWithChildren<Props>) => {
  return (
    <Body>
      <Box>
        <Typography variant="h3">{props.title}</Typography>
        <Typography>{props.subTitle}</Typography>
        {props.children}
      </Box>
    </Body>
  );
};

const Body = styled(Box)`
  display: flex;
  align-items: center;
  justify-content: center;
  min-height: calc(100svh - var(--app-header-height, 0px));
`;
