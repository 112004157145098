import { SetPasswordForm } from "../SetPasswordForm";
import { ForgotPasswordRequestForm } from "./components/ForgotPasswordRequestForm";
import {
  confirmResetPassword,
  resetPassword,
} from "@/components/GlobalContext/utils/auth";
import { snackIt } from "@/components/SnackbarManager";
import { useState } from "react";

type Props = {
  onExit: () => void;
  email: string;
};
export const ForgotPasswordForm = (props: Props) => {
  const [sentToEmail, setSentToEmail] = useState("");

  // step 2 (Set new password with code)
  if (sentToEmail) {
    return (
      <SetPasswordForm
        hasConfirmCode
        onResendCode={async () => sendResetPasswordCode(sentToEmail)}
        onSubmit={async (data) => {
          if (!data.code) throw Error("expected code");

          const { authErrorMsg } = await confirmResetPassword({
            confirmationCode: data.code,
            newPassword: data.newPassword,
            username: sentToEmail,
          });

          // success
          if (!authErrorMsg) {
            snackIt.default({
              severity: "success",
              message: "Password reset successfully.",
            });
            props.onExit();
            return;
          }

          // display error
          snackIt.default({ severity: "error", message: authErrorMsg });
        }}
      />
    );
  }

  // step 1 (Request code via email)
  return (
    <ForgotPasswordRequestForm
      defaultValues={{ email: props.email }}
      onReturnToSignInClick={() => {
        props.onExit();
      }}
      onSubmit={async (data) => {
        const { success } = await sendResetPasswordCode(data.email);
        if (success) setSentToEmail(data.email);
      }}
    />
  );
};

const sendResetPasswordCode = async (email: string) => {
  const { isPasswordReset, nextStep, authErrorMsg } = await resetPassword({
    username: email,
  });

  // success
  if (nextStep?.resetPasswordStep === "CONFIRM_RESET_PASSWORD_WITH_CODE") {
    return { success: true };
  }

  // figure out why reset password failed
  let errMsg = "Failed to request password reset";
  if (nextStep?.resetPasswordStep) {
    errMsg = `Unexpected reset password step: ${nextStep.resetPasswordStep}`;
  }
  if (authErrorMsg) {
    errMsg = authErrorMsg;
  }

  if (!isPasswordReset) {
    snackIt.default({
      severity: "error",
      message: errMsg,
    });
  }
  return { success: false };
};
