import { NeedHelpText } from "./UI";
import joyaLogoWhite from "@/assets/joya-logo-white.svg";
import { LoggedInProvider } from "@/components/GlobalContext";
import { signOut } from "@/components/GlobalContext/utils/auth";
import { LogoutIcon } from "@/components/UIKit/Icons";
import useBreakpointCheck from "@/hooks/useBreakpointCheck";
import {
  Box,
  GlobalStyles,
  IconButton,
  Container as MuiContainer,
  Typography,
  styled,
} from "@mui/material";
import { useState } from "react";

type Props = {
  provider: Pick<LoggedInProvider, "email" | "roles">;
};
export const OnboardingHeader = (props: Props) => {
  const isTablet = useBreakpointCheck("tablet");
  const [isLoggingOut, setIsLoggingOut] = useState(false);

  const onLogOutClick = async () => {
    setIsLoggingOut(true);
    try {
      await signOut();
    } catch (e: any) {
      setIsLoggingOut(false);
      throw Error(e);
    }

    // now just wait for log out to redirect...
  };

  return (
    <div>
      <GlobalStyles
        styles={() => ({
          body: {
            background: "#171c17",
          },
          ":root": {
            "--app-header-height": "80px",
          },
          "@media (max-width: 640px)": {
            ":root": {
              "--app-header-height": "80px",
            },
          },
        })}
      />
      <Body sx={{ height: "var(--app-header-height)" }}>
        <Container>
          <NeedHelpText visibility={isTablet ? "hidden" : "visible"} />
          <Box justifySelf="center">
            <Box display="flex">
              <img src={joyaLogoWhite} alt="joya logo" />
              <SeparatorLine />
              <ProviderPortalText>
                Provider
                <br />
                Portal
              </ProviderPortalText>
            </Box>
          </Box>

          <Box display="flex" alignItems="center" justifyContent="flex-end">
            <Typography variant="body3Emphasized">
              {props.provider.email}
            </Typography>
            <IconButton
              disabled={isLoggingOut}
              onClick={onLogOutClick}
              sx={{ p: "4px 0px 4px 4px", ml: "4px" }}
              color="inherit"
            >
              <LogoutIcon sx={{ fontSize: "14px" }} />
            </IconButton>
          </Box>
        </Container>
      </Body>
    </div>
  );
};

const Body = styled(Box)`
  background: #171c17;
  color: #fff;
`;

const Container = styled(MuiContainer)`
  && {
    padding: 0px 32px;
  }
  height: 100%;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  align-items: center;
`;

const ProviderPortalText = styled(Typography)`
  font-size: 16px;
  font-weight: 400;
  line-height: 20px;
  letter-spacing: -0.5px;
  text-align: left;
`;

const SeparatorLine = styled(Box)`
  background: #f6f5f4;
  opacity: 0.4;
  border-radius: 24px;
  width: 1px;
  margin: 0px 20px;
`;
