import { StepContainer } from "../../StepContainer";
import { CardButton } from "../../UI/buttons";
import { GlobalContext } from "@/components/GlobalContext";
import { PracticePromotionModal } from "@/components/Modals/PracticePromotionModal";
import { AddIcon, EditIcon, TrashIcon } from "@/components/UIKit/Icons";
import useOpenable from "@/hooks/useOpenable";
import {
  GetPromotionsResponse,
  Promotion,
} from "@/types/apiContract/promotion";
import { handleApiError } from "@/utils/feedback";
import { Box, Card, IconButton, Typography, styled } from "@mui/material";
import { useMutation, useQuery } from "@tanstack/react-query";
import axios from "axios";
import { useContext, useState } from "react";

type Props = {
  onBack: () => void;
  onContinue: () => void;
};
export const PracticePromotions = (props: Props) => {
  const { loggedInProvider } = useContext(GlobalContext);
  const { isOpen, onClose, onOpen } = useOpenable();
  const [selectedPromotion, setSelectedPromotion] = useState<Promotion>();

  // fetch list
  const promotionsQuery = useQuery({
    queryKey: ["promotions"],
    queryFn: async () => {
      return axios.get<GetPromotionsResponse>(
        `practices/${loggedInProvider.id}/promotions`
      );
    },
    throwOnError: true, // will trigger react error boundary
  });
  const promotions = promotionsQuery.data?.data || [];

  // delete item
  const deletePromotionMutation = useMutation({
    onError: handleApiError,
    mutationFn: async (promotionId: string) => {
      return axios.delete(
        `practices/${loggedInProvider.id}/promotions/${promotionId}`
      );
    },
    onSuccess: async () => await promotionsQuery.refetch(),
  });

  const onSubmit = async () => {
    props.onContinue();
  };

  return (
    <StepContainer
      heading="Practice promotions"
      description="JOYA members will receive marketing on your behalf."
      onBack={props.onBack}
      onContinue={onSubmit}
    >
      {isOpen && (
        <PracticePromotionModal
          onClose={onClose}
          onComplete={async () => {
            await promotionsQuery.refetch();
            onClose();
          }}
        />
      )}
      {selectedPromotion && (
        <PracticePromotionModal
          currentPromotion={selectedPromotion}
          onClose={() => setSelectedPromotion(undefined)}
          onComplete={async () => {
            await promotionsQuery.refetch();
            setSelectedPromotion(undefined);
          }}
        />
      )}
      <Box mb="24px" display="flex" flexDirection="column" gap="12px">
        {promotions.map((pp) => {
          return (
            <PromotionCard key={pp.id}>
              <Box>
                <Typography variant="body2" mb="4px">
                  {pp.product}
                </Typography>
                <Typography color="text.secondary" variant="body3">
                  {pp.dateType === "PERPETUAL"
                    ? pp.dateType
                    : `${pp.startDate} - ${pp.endDate}`}
                </Typography>
              </Box>
              <Box display="flex" alignItems="center" gap="20px">
                <IconButton onClick={() => setSelectedPromotion(pp)}>
                  <EditIcon />
                </IconButton>
                <IconButton
                  disabled={deletePromotionMutation.isPending}
                  onClick={() => deletePromotionMutation.mutate(pp.id)}
                >
                  <TrashIcon />
                </IconButton>
              </Box>
            </PromotionCard>
          );
        })}
      </Box>
      <CardButton startIcon={<AddIcon />} onClick={onOpen}>
        <Typography variant="body2Emphasized">Add a promotion</Typography>
      </CardButton>
    </StepContainer>
  );
};

const PromotionCard = styled(Card)`
  padding: 17px 20px;
  display: flex;
  justify-content: space-between;
`;
