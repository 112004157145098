// import rollbar from "../../rollbar";
import { ErrorView } from "./View";
import React, { PropsWithChildren } from "react";
import { ErrorBoundary as ReactErrorBoundary } from "react-error-boundary";

const errorHandler = (
  error: Error,
  info: { componentStack?: string | null }
) => {
  // console.error(error, info);
  // rollbar.error(error); NOTE: this doesnt have rollbar setup, so not erroring here
};

export const ErrorBoundary: React.FC<PropsWithChildren> = ({ children }) => {
  return (
    <ReactErrorBoundary FallbackComponent={ErrorView} onError={errorHandler}>
      {children}
    </ReactErrorBoundary>
  );
};

export default ErrorBoundary;
