import { ThemeOptions } from "@mui/material";

const typography: ThemeOptions["typography"] = {
  fontFamily: "alfabet, sans-serif",
  h2: {
    fontFamily: "the-seasons, sans-serif",
    fontSize: "48px",
    fontWeight: 400,
    lineHeight: "58px",
    letterSpacing: "0px",
  },
  h3: {
    fontFamily: "the-seasons, sans-serif",
    fontSize: "40px",
    fontWeight: 400,
    lineHeight: "48px",
  },
  h4: {
    fontFamily: "the-seasons, sans-serif",
    fontSize: "22px",
    fontWeight: 400,
    lineHeight: "28px",
    letterSpacing: "0.6px",
  },
  h6: {
    fontSize: "14px",
    fontWeight: 400,
    lineHeight: "20px",
    letterSpacing: "0.5px",
  },
  body1Light: {
    fontSize: "16px",
    fontWeight: 300,
    lineHeight: "24px",
    letterSpacing: "0.02em",
  },
  body1Emphasized: {
    fontSize: "18px",
    fontWeight: 400,
    lineHeight: "27px",
    letterSpacing: "0.02em",
  },
  body2: {
    fontSize: "16px",
    fontWeight: 300,
    lineHeight: "24px",
    letterSpacing: "0.02em",
  },
  body2Light: {
    fontSize: "16px",
    fontWeight: 300,
    lineHeight: "24px",
    letterSpacing: "0.02em",
  },
  body2Medium: {
    fontSize: "14px",
    fontWeight: 400,
    lineHeight: "22px",
  },
  body2Emphasized: {
    fontSize: "16px",
    fontWeight: 400,
    lineHeight: "24px",
    letterSpacing: "0px",
  },
  body3: {
    fontSize: "14px",
    fontWeight: 300,
    lineHeight: "22px",
    letterSpacing: "0px",
  },
  body3Regular: {
    fontSize: "14px",
    fontWeight: 300,
    lineHeight: "28px",
    letterSpacing: "0.28px",
  },
  body3Medium: {
    fontSize: "12px",
    fontWeight: 400,
    lineHeight: "22px",
    letterSpacing: "0px",
  },
  body3Emphasized: {
    fontSize: "14px",
    fontWeight: 400,
    lineHeight: "22px",
    letterSpacing: "0px",
  },
  button: {
    textTransform: "capitalize",
    fontSize: "14px",
    fontWeight: 500,
    lineHeight: "24px",
    letterSpacing: "0.2px",
  },
  button2: {
    fontSize: "14px",
    fontWeight: 700,
    lineHeight: "20px",
    letterSpacing: "0.2px",
  },
};

export { typography };
