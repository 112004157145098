const config = {
  nodeEnv: process.env.NODE_ENV!,
  appEnv: process.env.REACT_APP_ENV!,
  stripePK: process.env.REACT_APP_STRIPE_PK!,
  cognitoIdentityPoolId: process.env.REACT_APP_COGNITO_IDENTITY_POOL_ID!,
  cognitoUserPoolId: process.env.REACT_APP_COGNITO_USER_POOL_ID!,
  cognitoWebClientId: process.env.REACT_APP_COGNITO_WEB_CLIENT_ID!,
  cognitoRegion: process.env.REACT_APP_COGNITO_AWS_REGION!,
  loginDomain: process.env.REACT_APP_COGNITO_LOGIN_DOMAIN!,
  faroUrl: process.env.REACT_APP_FARO_URL!,
  datadogApplicationId: process.env.REACT_APP_DATADOG_APPLICATION_ID!,
  datadogClientToken: process.env.REACT_APP_DATADOG_CLIENT_TOKEN!,
  cognitoOauthSignInPath: "/auth/callback",
  cookieDomain:
    window.location.protocol === "http:" ||
    !process.env.REACT_APP_COGNITO_COOKIE_DOMAIN
      ? window.location.hostname
      : process.env.REACT_APP_COGNITO_COOKIE_DOMAIN!,
  isStaticApp: process.env.REACT_APP_STATIC_WEB === "true",
  rollbarAccessToken: process.env.REACT_APP_ROLLBAR_ACCESS_TOKEN!,
  rollbarEnvironment: process.env.REACT_APP_ROLLBAR_ENV!,
  rollbarDevMode: process.env.REACT_APP_ROLLBAR_DEV_MODE === "true",
  restApiEndpoint: process.env.REACT_APP_REST_API_ENDPOINT!,
  version: process.env.REACT_APP_VERSION || "local",
};

export default config;
