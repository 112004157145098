import { format, isBefore } from "date-fns";
import { useState } from "react";

type OptionalDate = Date | null;

type DateStates = {
  startDate: OptionalDate;
  endDate: OptionalDate;
  selectedStartDate: OptionalDate;
  selectedEndDate: OptionalDate;
};

type Props = {
  dateFormat?: string;
  value?: {
    startDate: Date;
    endDate: Date;
  };
};

function useDateStates(props: Props) {
  const { dateFormat = "MMM d, yyyy" } = props;

  const [dates, setDates] = useState<DateStates>({
    startDate: props.value?.startDate || null,
    endDate: props.value?.endDate || null,
    selectedStartDate: props.value?.startDate || null,
    selectedEndDate: props.value?.endDate || null,
  });

  const setStartDate = (startDate: OptionalDate) =>
    setDates((prev) => ({ ...prev, startDate }));
  const setEndDate = (endDate: OptionalDate) =>
    setDates((prev) => ({ ...prev, endDate }));

  const hasValue = dates.selectedStartDate && dates.selectedEndDate;
  let selectedRangeDisplay = "";
  if (hasValue) {
    selectedRangeDisplay = `${format(
      dates.selectedStartDate!,
      dateFormat
    )} - ${format(dates.selectedEndDate!, dateFormat)}`;
  }

  const setDateRange = () => {
    setDates((prev) => ({
      ...prev,
      selectedEndDate: prev.endDate || prev.startDate,
      selectedStartDate: prev.startDate,
    }));
  };

  const handlePickedDate = (value: OptionalDate) => {
    if (!dates.startDate) {
      setStartDate(value);
      setEndDate(null);
    } else if (dates.startDate && !dates.endDate) {
      if (value && isBefore(value, dates.startDate)) {
        setDates((prev) => ({
          ...prev,
          startDate: value,
          endDate: prev.startDate,
        }));
      } else {
        setEndDate(value);
      }
    } else if (dates.startDate && dates.endDate) {
      setStartDate(value);
      setEndDate(null);
    }
  };

  const clearDates = () => {
    setDates({
      startDate: null,
      endDate: null,
      selectedStartDate: null,
      selectedEndDate: null,
    });
  };

  return {
    startDate: dates.startDate,
    endDate: dates.endDate,
    selectedStartDate: dates.selectedStartDate,
    selectedEndDate: dates.selectedEndDate,
    setStartDate,
    setEndDate,
    setDateRange,
    handlePickedDate,
    clearDates,
    selectedRangeDisplay,
  };
}

export { useDateStates };
