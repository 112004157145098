import { AuthLayout } from "../../../AuthLayout";
import { AuthFormHeading } from "../../../UI/misc";
import { FormRow } from "@/components/UIKit/Form/styled";
import { TextField } from "@/components/UIKit/Inputs/TextField/TextField";
import { yupEmail } from "@/utils/yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { LoadingButton } from "@mui/lab";
import { Box, Button, Typography } from "@mui/material";
import { useState } from "react";
import { UseFormProps, useForm } from "react-hook-form";
import * as yup from "yup";

type Props = {
  onSubmit: (data: FormInputs) => Promise<void>;
  onReturnToSignInClick: () => void;
  defaultValues?: Partial<FormInputs>;
};
export const ForgotPasswordRequestForm = (props: Props) => {
  const { control, register, handleSubmit } = useResetPasswordRequestForm({
    defaultValues: props.defaultValues,
  });
  const [isLoading, setIsLoading] = useState(false);

  const onSubmit = async (data: FormInputs) => {
    try {
      setIsLoading(true);
      await props.onSubmit(data);
    } catch (e: any) {
      throw Error(e);
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <AuthLayout>
      <form onSubmit={handleSubmit(onSubmit)}>
        <AuthFormHeading
          title="Forgot your password"
          description="We’ll send you instructions to rest your password."
        />
        <FormRow mb="24px">
          <TextField
            control={control}
            register={register("email")}
            label="Email"
            placeholder="Email"
          />
        </FormRow>
        <Box mt="32px">
          <LoadingButton
            variant="contained"
            type="submit"
            fullWidth
            loading={isLoading}
            color="primary"
          >
            Email me
          </LoadingButton>
          <Box mt="24px" display="flex" justifyContent="center">
            <Button onClick={props.onReturnToSignInClick}>
              <Typography variant="button2">Return to Sign In</Typography>
            </Button>
          </Box>
        </Box>
      </form>
    </AuthLayout>
  );
};

type FormInputs = {
  email: string;
};
export type { FormInputs as LoginFormInputs };

const useResetPasswordRequestForm = (props?: UseFormProps<FormInputs>) => {
  let defaultValues = props?.defaultValues;
  const validationSchema: yup.SchemaOf<FormInputs> = yup.object({
    email: yupEmail().required(),
  });

  return useForm<FormInputs>({
    ...props,
    resolver: yupResolver(validationSchema),
    defaultValues: defaultValues,
  });
};
