import {
  TextField as MuiTextField,
  TextFieldProps as MuiTextFieldProps,
} from "@mui/material";
import {
  Controller,
  UseControllerProps,
  UseFormRegisterReturn,
} from "react-hook-form";

export type TextFieldProps = MuiTextFieldProps & {
  defaultValue?: string;
  control?: UseControllerProps<any>["control"];
  register?: UseFormRegisterReturn<any>;
  onChangeFilter?: (value: string, prevValue: string) => string;
  onBlurFilter?: (value: string) => string;
};

// <TextField /> component that can be easily integrated with react-hook-form (ie: errors)
// - goal is to reduce as much boilerplate in forms as possible
export const TextField = (props: TextFieldProps) => {
  const {
    control,
    register,
    defaultValue = "", // fix uncontrolled input error when value is undefined
    onChangeFilter,
    onBlurFilter,
    ...textFieldProps
  } = props;

  if (control && register) {
    return (
      <Controller
        control={control}
        name={register.name}
        defaultValue={defaultValue}
        render={({ field, fieldState: { error } }) => {
          return (
            <MuiTextField
              {...field}
              {...textFieldProps}
              ref={register.ref}
              error={Boolean(error)}
              helperText={error?.message || textFieldProps.helperText}
              onChange={(e) => {
                const value = onChangeFilter
                  ? onChangeFilter(e.target.value, field.value)
                  : e.target.value;
                field.onChange(value);
              }}
              onBlur={() => {
                if (onBlurFilter) {
                  const value = onBlurFilter(field.value);
                  field.onChange(value);
                }
                field.onBlur();
              }}
            />
          );
        }}
      />
    );
  }

  return <MuiTextField {...textFieldProps} />;
};
