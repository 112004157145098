import { snackIt } from "@/components/SnackbarManager";
import { GENERIC_API_ERR } from "@/constants/messages";
import { downloadFile as downloadStandardFile } from "@/utils/file/downloadFile";
import { useState } from "react";

type FileToDownload = { url: string; fileName: string };

const downloadFile = async (file: FileToDownload) => {
  return downloadStandardFile(file);
};

export const useDownloadManager = () => {
  const [filesLoading, setFilesLoading] = useState(false);

  const download = async (file: FileToDownload) => {
    setFilesLoading(true);
    try {
      await downloadFile(file);
    } catch (e: any) {
      snackIt.default({ severity: "error", message: GENERIC_API_ERR });
      throw Error(e);
    } finally {
      setFilesLoading(false);
    }
  };

  return {
    loading: filesLoading,
    download,
  };
};
