import { StepContainer } from "../../StepContainer";
import { FormRow } from "@/components/UIKit/Form/styled";
import { Select } from "@/components/UIKit/Inputs/Select/Select";
import { PhoneTextField } from "@/components/UIKit/Inputs/TextField/PhoneTextField";
import { TextField } from "@/components/UIKit/Inputs/TextField/TextField";
import { SurveySectionFields } from "@/scenes/Onboarding/types";
import { cleanPhoneNum, formatPhoneNum } from "@/utils/formatting";
import { yupEmail, yupPhoneNumber } from "@/utils/yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { Box, Typography } from "@mui/material";
import { UseFormProps, useForm } from "react-hook-form";
import * as yup from "yup";

type Props = {
  onBack?: () => void;
  onContinue: (values: FormInputs) => Promise<void>;
  defaultValues?: Partial<FormInputs>;
};

const ROLE_OPTIONS = [
  "Practice manager",
  "Provider",
  "Practice Administrator",
  "Care Coordinator",
  "Esthetician",
  "Medical Assistant",
  "Other",
];

// FIELDS
// IMPORTANT: treat this as a contract, this determines which values gets passed up to the backend
const FIELDS: SurveySectionFields<FormInputs> = {
  name: {
    label: "Who is completing this questionnaire?",
    type: "string",
    example: "John Doe",
  },
  role: {
    label: "Role",
    type: "single-select",
    selectOptions: ROLE_OPTIONS,
    example: ROLE_OPTIONS[0],
  },
  email: { label: "Email", type: "string", example: "asd@gmail.com" },
  phoneNum: { label: "Phone Number", type: "string", example: "1231231234" },
};
export { FIELDS as GettingToKnowYouFields };

export const GettingToKnowYou = (props: Props) => {
  const { control, register, handleSubmit } = useContactForm({
    defaultValues: {
      ...props.defaultValues,
      phoneNum: formatPhoneNum(props.defaultValues?.phoneNum || ""),
    },
  });

  const onSubmit = async (values: FormInputs) => {
    await props.onContinue({
      ...values,
      phoneNum: cleanPhoneNum(values.phoneNum),
    });
  };

  return (
    <StepContainer
      heading="Getting to know you"
      description="Please provide us with your contact information."
      onBack={props.onBack}
      onContinue={() => handleSubmit(onSubmit)()}
    >
      <FormRow>
        <TextField
          control={control}
          register={register("name")}
          label={FIELDS["name"].label}
          placeholder="First and last name"
        />
        <Select
          control={control}
          register={register("role")}
          label={FIELDS["role"].label}
          defaultValue={ROLE_OPTIONS[0]}
          options={ROLE_OPTIONS}
        />
      </FormRow>
      <FormRow>
        <Box display="flex" flexDirection="column">
          <TextField
            control={control}
            register={register("email")}
            type="email"
            label={FIELDS["email"].label}
            placeholder="Enter Email"
          />
          <Typography variant="body2Light" color="text.secondary" mt="12px">
            We'll send you quarterly reports and analytics on JOYA patients at
            your practice.
          </Typography>
        </Box>
      </FormRow>
      <FormRow>
        <PhoneTextField
          control={control}
          register={register("phoneNum")}
          label={FIELDS["phoneNum"].label}
        />
      </FormRow>
    </StepContainer>
  );
};
GettingToKnowYou.defaultProps = {
  onContinue: async () => {},
};

// form fields
// IMPORTANT: treat this as a contract, this is how values are read from the backend (see defaultValues prop)
type FormInputs = {
  name: string;
  email: string;
  phoneNum: string;
  role: string;
};

const useContactForm = (props?: UseFormProps<FormInputs>) => {
  let defaultValues = props?.defaultValues;
  const validationSchema: yup.SchemaOf<FormInputs> = yup.object({
    name: yup.string().required(),
    email: yupEmail().required(),
    phoneNum: yupPhoneNumber().required(),
    role: yup.string().required(),
  });

  return useForm<FormInputs>({
    ...props,
    resolver: yupResolver(validationSchema),
    defaultValues: defaultValues,
  });
};
