import "./muiTypeOverrides";
import { breakpoints, components, palette, typography } from "./themeOptions";
import { Theme, createTheme } from "@mui/material/styles";

const themeBase: Theme = createTheme({
  palette,
  typography,
  breakpoints,
});

const theme = createTheme(themeBase, {
  components: {
    ...themeBase,
    ...components(themeBase),
  },
});

export { theme };
