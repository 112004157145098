import { SvgIcon, SvgIconProps } from "@mui/material";

function ReloadIcon(props: SvgIconProps) {
  return (
    <SvgIcon
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M21 12.1368C21 17.1074 16.9706 21.1368 12 21.1368C8.66873 21.1368 5.76018 19.3269 4.20404 16.6368M4 20.6368V16.6368H8M3 12.1368C3 7.16622 7.02944 3.13678 12 3.13678C15.3313 3.13678 18.2398 4.94667 19.796 7.63678M20 3.63678V7.63678H16"
        stroke="currentColor"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
        fill="none"
      />
    </SvgIcon>
  );
}

export { ReloadIcon };
