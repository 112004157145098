import { theme } from "@/themes/theme";
import { useMediaQuery } from "@mui/material";

type BreakpointKey = keyof typeof theme.breakpoints.values;

const useBreakpointCheck = (breakpointKey: BreakpointKey) => {
  return useMediaQuery(
    `(max-width: ${theme.breakpoints.values[breakpointKey]}px)`
  );
};

export default useBreakpointCheck;
