import { ManageAccessModalView } from "./View";
import type { ChangeRole } from "./types";
import { GlobalContext } from "@/components/GlobalContext";
import {
  Assistant,
  CreateAssistantRequest,
  GetAssistantsResponse,
} from "@/types/apiContract/assistant";
import { getApiErrorMessage, handleApiError } from "@/utils/feedback";
import { useMutation, useQuery } from "@tanstack/react-query";
import axios from "axios";
import { useContext } from "react";

type Props = {
  onClose: () => void;
};

export const ManageAccessModalContainer = (props: Props) => {
  const { loggedInProvider } = useContext(GlobalContext);

  const getAssistantsQuery = useQuery({
    queryKey: ["assistants"],
    queryFn: async () => {
      return axios.get<GetAssistantsResponse>(
        `/practices/${loggedInProvider.id}/assistants`
      );
    },
  });
  const assistants = getAssistantsQuery.data?.data || [];

  // create new assistant with default role "USER"
  const createAssistantMutation = useMutation({
    onSuccess: async () => {
      await getAssistantsQuery.refetch();
    },
    mutationFn: (email: string) => {
      const createAssistant: CreateAssistantRequest = {
        email: email.toLowerCase(), // TODO: this is a temporary fix - case sensitivity should be handled on the backend
        role: "USER",
      };
      return axios.post<Assistant>(
        `/practices/${loggedInProvider.id}/assistants`,
        createAssistant
      );
    },
  });

  // delete assistant mutation on endpoint /practice/{practiceId}/assistants/{assistantId}
  const deleteAssistantMutation = useMutation({
    onError: handleApiError,
    onSuccess: async () => {
      await getAssistantsQuery.refetch();
    },
    mutationFn: (assistantId: string) => {
      return axios.delete(
        `/practices/${loggedInProvider.id}/assistants/${assistantId}`
      );
    },
  });

  // change role mutation on endpoint /practice/{practiceId}/assistants/{assistantId}
  const changeRoleMutation = useMutation({
    onError: handleApiError,
    onSuccess: async () => {
      await getAssistantsQuery.refetch();
    },
    mutationFn: (params: { assistantId: string; role: Assistant["role"] }) => {
      const { assistantId, role } = params;
      return axios.patch(
        `/practices/${loggedInProvider.id}/assistants/${assistantId}`,
        { role }
      );
    },
  });

  const onAddContact = async (values: { email: string }) => {
    try {
      await createAssistantMutation.mutateAsync(values.email);
      return { isSuccess: true };
    } catch (e: any) {
      const errMessage = getApiErrorMessage(e);

      // show on field if email already exists (instead of snackit)
      if (
        errMessage.startsWith("An account with the given email already exists.")
      ) {
        return {
          isSuccess: false,
          fieldErrMessage: "An account with the given email already exists.",
        };
      }

      handleApiError(e);
      return { isSuccess: false };
    }
  };

  const onSave = async (params: {
    toDeleteAssistants: string[];
    toChangeRolesAssistants: ChangeRole[];
  }) => {
    const toChangeRoles = params.toChangeRolesAssistants.map((assistantObj) => {
      return {
        assistantId: assistantObj.assistantId,
        role: assistantObj.roleTo,
      };
    });

    await Promise.all(
      params.toDeleteAssistants.map((assistantId) =>
        deleteAssistantMutation.mutateAsync(assistantId)
      )
    );
    await Promise.all(
      toChangeRoles.map((assistantObj) =>
        changeRoleMutation.mutateAsync(assistantObj)
      )
    );
  };

  return (
    <ManageAccessModalView
      loggedInProvider={loggedInProvider}
      assistants={assistants}
      isLoadingAssistants={getAssistantsQuery.isLoading}
      onClose={props.onClose}
      onAddContact={onAddContact}
      onSave={onSave}
    />
  );
};
