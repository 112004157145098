import { Insurance } from "../types";
import { NewInsuranceRequestModal } from "./NewInsuranceRequestModal";
import { GrayButton } from "@/components/UIKit/Button/styled";
import { ChevronDownIcon, SearchIcon } from "@/components/UIKit/Icons";
import useOpenable from "@/hooks/useOpenable";
import {
  Box,
  Checkbox,
  Collapse,
  FormControlLabel,
  IconButton,
  InputAdornment,
  Skeleton,
  TextField,
  Typography,
  styled,
} from "@mui/material";
import { useRef, useState } from "react";
import InfiniteScroll from "react-infinite-scroller";

type Props = {
  searchValue: string;
  onSearchChange: (searchVal: string) => void;
  value: Insurance[];
  onInsuranceChange: (insurance: Insurance) => void;
  searchResults: Insurance[];
  isLoading?: boolean;
  onLoadMore: () => void;
  totalResults?: number;
  isLoadingMoreResults: boolean;
};

export const SearchSection = (props: Props) => {
  const {
    isOpen: isNewInsuranceModalOpen,
    onOpen: onNewInsuranceModalOpen,
    onClose: onNewInsuranceModalClose,
  } = useOpenable();
  const bodyRef = useRef<HTMLDivElement>(null);

  const onCheckedPlansChange = (params: {
    plans: Set<string>;
    insurance: Insurance;
  }) => {
    const { plans, insurance } = params;

    const newInsurance = {
      ...insurance,
      plans: insurance.plans.filter((x) => plans.has(x.id)),
    };

    // remove insurance if no plans are selected
    if (plans.size === 0) {
      props.onInsuranceChange(newInsurance);
      return;
    }

    // update plans of insurance and replace current insurance in the array
    props.onInsuranceChange(newInsurance);
  };

  const hasFoundZeroResults = props.totalResults === 0;
  const hasMoreResults = props.searchResults.length < (props.totalResults || 0);

  return (
    <>
      {isNewInsuranceModalOpen && (
        <NewInsuranceRequestModal onClose={onNewInsuranceModalClose} />
      )}
      <Body ref={bodyRef}>
        <Box mb="16px">
          <Typography variant="h4" mb="24px">
            Search
          </Typography>
          <TextField
            placeholder="Search by carrier or plan"
            value={props.searchValue}
            onChange={(e) => props.onSearchChange(e.target.value)}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <SearchIcon />
                </InputAdornment>
              ),
            }}
            fullWidth
            sx={{
              "&&": { input: { borderLeft: "0px", paddingLeft: "0px" } },
            }}
          />
        </Box>

        {/* ---SEARCH INFO--- */}
        {hasFoundZeroResults && (
          <Box>
            <Typography
              display="block"
              variant="body3"
              mb="24px"
              color="text.secondary"
            >
              Looks like we can’t find “{props.searchValue}”
            </Typography>

            <GrayButton
              fullWidth
              variant="contained"
              onClick={() => onNewInsuranceModalOpen()}
            >
              Tell us about your insurance carrier
            </GrayButton>
          </Box>
        )}
        {!hasFoundZeroResults && props.totalResults !== undefined && (
          <Box display="flex" justifyContent="space-between" mb="16px">
            <Typography variant="body3">
              {props.totalResults} insurance carrier
              {props.totalResults !== 1 ? "s" : null}
            </Typography>
          </Box>
        )}

        {/* ---RESULTS--- */}
        {props.isLoading ? (
          <LoadingSkeleton />
        ) : (
          <InfiniteScroll
            loadMore={() => {
              props.onLoadMore();
            }}
            hasMore={hasMoreResults}
            loader={
              <div key={0}>
                <LoadingSkeleton />
              </div>
            }
            useWindow={false}
            getScrollParent={() => bodyRef.current}
            style={{ display: "flex", flexDirection: "column", gap: "8px" }}
          >
            {props.searchResults.map((insurance) => {
              const selectedInsurance = props.value.find(
                (x) => x.id === insurance.id
              );
              return (
                <InsuranceTile
                  key={insurance.id}
                  insurance={insurance}
                  checkedPlans={
                    new Set(selectedInsurance?.plans.map((x) => x.id))
                  }
                  onCheckedPlansChange={(plans) =>
                    onCheckedPlansChange({ plans, insurance })
                  }
                />
              );
            })}
          </InfiniteScroll>
        )}
      </Body>
    </>
  );
};

const Body = styled(Box)`
  border: 1px solid #e9ebed;
  padding: 24px 20px;
  border-radius: 12px;
  overflow: auto;
`;

const InsuranceTile = (props: {
  insurance: Insurance;
  checkedPlans: Set<string>;
  onCheckedPlansChange: (value: Set<string>) => void;
}) => {
  const { insurance, checkedPlans } = props;
  const [isOpen, setIsOpen] = useState(false);

  const noPlansChecked = checkedPlans.size === 0;
  const allPlansChecked = insurance.plans.length === checkedPlans.size;
  const hasSomePlansChecked = checkedPlans.size > 0 && !allPlansChecked;

  const onInsuranceCheckboxClick = () => {
    if (allPlansChecked) {
      props.onCheckedPlansChange(new Set());
      return;
    }
    if (hasSomePlansChecked || noPlansChecked) {
      setIsOpen(true);
      props.onCheckedPlansChange(new Set(insurance.plans.map((p) => p.id)));
      return;
    }
  };

  const onPlanCheckboxClick = (params: {
    planId: string;
    isChecked: boolean;
  }) => {
    const { isChecked, planId } = params;
    const newPlans = new Set(props.checkedPlans);
    if (isChecked) {
      newPlans.delete(planId);
    } else {
      newPlans.add(planId);
    }
    props.onCheckedPlansChange(new Set(newPlans));
  };

  return (
    <InsuranceTileCard>
      <Box display="flex" justifyContent="space-between" alignItems="center">
        <Box display="flex" gap="12px" alignItems="center">
          <FormControlLabel
            sx={{ py: "14px" }}
            control={
              <Checkbox
                checked={allPlansChecked}
                indeterminate={hasSomePlansChecked}
                onChange={onInsuranceCheckboxClick}
              />
            }
            label={
              <Typography variant="body2">{props.insurance.name}</Typography>
            }
          />
        </Box>
        <IconButton
          sx={{ p: "14px" }}
          onClick={() => setIsOpen((curr) => !curr)}
        >
          <ChevronDownIcon
            sx={{
              transition: "transform 0.2s",
              ...(isOpen && {
                transform: "rotate(180deg)",
              }),
            }}
          />
        </IconButton>
      </Box>
      {/* unmountOnExit greatly improves performance (ie: no lag when updating search input)
          - <Checkbox /> in particular is expensive it looks like 
      */}
      <Collapse in={isOpen} unmountOnExit>
        <Box pl="35px">
          {insurance.plans.map((plan) => {
            const isChecked = checkedPlans.has(plan.id);
            return (
              <InsurancePlan key={plan.id} display="flex" gap="12px">
                <FormControlLabel
                  sx={{ py: "20px" }}
                  control={
                    <Checkbox
                      checked={isChecked}
                      onChange={() =>
                        onPlanCheckboxClick({ isChecked, planId: plan.id })
                      }
                    />
                  }
                  label={<Typography variant="body2">{plan.name}</Typography>}
                />
              </InsurancePlan>
            );
          })}
        </Box>
      </Collapse>
    </InsuranceTileCard>
  );
};

const InsuranceTileCard = styled(Box)`
  border: 0.5px solid #e9ebed;
  padding-left: 14px;
  border-radius: 8px;
`;

const InsurancePlan = styled(Box)`
  border-bottom: 0.5px solid #e9ebed;

  &:last-of-type {
    border-bottom: 0px;
  }
`;

const LoadingSkeleton = () => {
  return (
    <Box pb="20px">
      {Array(3)
        .fill(null)
        .map((_, i) => (
          <Skeleton
            key={i}
            variant="rectangular"
            height={52}
            sx={{ my: "8px", borderRadius: "8px" }}
            width="100%"
          />
        ))}
    </Box>
  );
};
