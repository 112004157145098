import axios from "axios";

export const downloadFile = async (params: {
  url: string;
  fileName: string;
}) => {
  const { blob, fileName } = await fetchFile(params.url, params.fileName);
  mimicDownloadLinkClick(blob, fileName);
};

const fetchFile = async (url: string, fileName: string) => {
  const res = await axios.get<Blob>(url, {
    responseType: "blob",
    baseURL: "",
  });
  const blob = res.data;

  if (blob.type === "application/pdf") {
    // application/octet-stream forces file to be downloaded (ex: pdfs will download instead of opened for ios)
    // - seems to only be an issue for pdfs on ios/ipadOS
    const octetStreamBlob = new Blob([blob], {
      type: "application/octet-stream",
    });
    return { blob: octetStreamBlob, fileName: fileName + ".pdf" };
  }

  return { blob, fileName };
};

const mimicDownloadLinkClick = (blob: Blob, fileName: string) => {
  const dlUrl = URL.createObjectURL(blob);
  const link = document.createElement("a");
  link.href = dlUrl;
  link.setAttribute("download", fileName);
  document.body.appendChild(link);
  link.click();
  link.parentNode!.removeChild(link);
  window.URL.revokeObjectURL(dlUrl);
};
