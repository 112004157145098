import { CloseIcon } from "../../Icons/CloseIcon";
import { ReloadIcon } from "../../Icons/ReloadIcon";
import { TrashIcon } from "../../Icons/TrashIcon";
import {
  Box,
  Button,
  ButtonBase,
  IconButton,
  LinearProgress,
  Typography,
} from "@mui/material";
import { styled } from "@mui/material/styles";
import { useCallback, useEffect, useState } from "react";
import { DropzoneOptions, useDropzone } from "react-dropzone";

// can be used as a controlled input (if "value" is provided) or not - similar to a regular text input
type Props = {
  value?: File;
  onChange: (file: File | undefined) => void;
  uploadProgress?: number;
  subtitle: string;
  errorMsg?: string;
  options: {
    accept: DropzoneOptions["accept"];
    maxSizeInMB: number;
  };
  emptyFilePreviewImg?: string;
  onValidChange: (params: { isValid: boolean }) => void;
};
export const FileDropzone = (props: Props) => {
  const { onChange } = props;
  const [previewImg, setPreviewImg] = useState("");
  const [internalFileValue, setInternalFileValue] = useState<File>();

  const updateValue = useCallback(
    (file: File | undefined) => {
      setInternalFileValue(file);
      onChange(file);
    },
    [onChange]
  );

  const loadedFile = props.value || internalFileValue;
  const {
    getRootProps,
    getInputProps,
    isDragAccept,
    isDragReject,
    fileRejections,
  } = useDropzone({
    disabled: Boolean(loadedFile),
    multiple: false,
    accept: props.options.accept,
    maxSize: props.options.maxSizeInMB * 1024 * 1024,
    onDrop: useCallback(
      (files: File[]) => {
        updateValue(files[0]);
      },
      [updateValue]
    ),
    onDropRejected: () => {
      props.onValidChange({ isValid: false });
    },
    onDropAccepted: () => {
      props.onValidChange({ isValid: true });
    },
    onFileDialogCancel: () => {
      props.onValidChange({ isValid: true });
    },
  });
  const rejectedFile =
    (fileRejections.length > 0 && fileRejections[0]) || undefined;

  // apply image preview
  useEffect(() => {
    if (!loadedFile) {
      setPreviewImg(props.emptyFilePreviewImg || "");
      return;
    }

    const reader = new FileReader();
    reader.readAsDataURL(loadedFile);
    reader.onload = () => {
      if (typeof reader.result !== "string") {
        setPreviewImg("");
        return;
      }
      setPreviewImg(reader.result);
    };
  }, [loadedFile, props.emptyFilePreviewImg]);

  const errorMsg = props.errorMsg || rejectedFile?.errors[0].message;
  const errorFileName = rejectedFile?.file.name || loadedFile?.name;

  const renderInner = () => {
    if (errorMsg) {
      return (
        <Inner>
          <InnerLeft>
            <ButtonBase onClick={() => updateValue(undefined)}>
              <ReloadIcon color="error" />
            </ButtonBase>
          </InnerLeft>
          <InnerCenter>
            <Typography variant="body2Medium">{errorFileName}</Typography>
            <Typography variant="subtitle2" color="error.400">
              {errorMsg}
            </Typography>
          </InnerCenter>
        </Inner>
      );
    }

    // case where we want just want a preview image (ie: current file value is given as a url src instead of a File object)
    if (previewImg && !loadedFile) {
      return (
        <Inner>
          <InnerLeft>
            <img src={previewImg} alt={previewImg} />
          </InnerLeft>
          <InnerCenter>
            <Typography variant="body2Medium" textAlign="right">
              {getFileNameFromURL(previewImg)}
            </Typography>
          </InnerCenter>
        </Inner>
      );
    }

    // case where we want just want a preview image (ie: current file value is given as a url src instead of a File object)
    if (previewImg && !loadedFile) {
      return (
        <Inner>
          <InnerLeft>
            <img src={previewImg} alt={previewImg} />
          </InnerLeft>
          <InnerCenter>
            <Typography variant="body2Medium" textAlign="right">
              {getFileNameFromURL(previewImg)}
            </Typography>
          </InnerCenter>
        </Inner>
      );
    }

    if (!loadedFile) {
      return (
        <Inner>
          <InnerCenter>
            <Typography variant="body2Medium">
              Click to select file or drag and drop
            </Typography>
            <Typography variant="subtitle2" color="grey.500">
              {props.subtitle}
            </Typography>
          </InnerCenter>
          <Button size="small" variant="contained" color="primary">
            Select File
          </Button>
        </Inner>
      );
    }

    if (props.uploadProgress !== undefined && props.uploadProgress < 100) {
      return (
        <Inner>
          <InnerCenter>
            <Box display="flex" justifyContent="space-between" mb="8px">
              <Typography variant="body2Medium">{loadedFile.name}</Typography>
              <Typography variant="body2Medium" color="grey.500">
                {props.uploadProgress}%
              </Typography>
            </Box>
            <LinearProgress
              variant="determinate"
              value={props.uploadProgress}
              sx={{ height: "8px", borderRadius: "4px" }}
            />
          </InnerCenter>
          <BorderedIconButton onClick={() => updateValue(undefined)}>
            <CloseIcon />
          </BorderedIconButton>
        </Inner>
      );
    }

    if (props.uploadProgress === 100 || loadedFile) {
      return (
        <Inner>
          <InnerLeft>
            <img src={previewImg} alt={loadedFile.name} />
          </InnerLeft>
          <InnerCenter>
            <Typography variant="body2Medium" textAlign="right">
              {loadedFile.name}
            </Typography>
          </InnerCenter>
          <BorderedIconButton onClick={() => updateValue(undefined)}>
            <TrashIcon />
          </BorderedIconButton>
        </Inner>
      );
    }
  };

  let className = "";
  if (isDragAccept) className += " is-drag-accept";
  if (isDragReject || rejectedFile) className += " is-drag-reject";
  return (
    <Body>
      <Main
        {...getRootProps()}
        className={className}
        isReadyForUpload={!loadedFile}
        isError={Boolean(errorMsg)}
      >
        <input {...getInputProps()} data-testid="uploader" />
        {renderInner()}
      </Main>
    </Body>
  );
};
FileDropzone.defaultProps = {
  onChange: () => {
    /* Do nothing */
  },
  onValidChange: () => {},
};

const getFileNameFromURL = (url: string) => {
  let filename = "";

  try {
    filename = new URL(url).pathname.split("/").pop() || "";
  } catch (e) {
    console.error(e);
  }
  return filename;
};

const Body = styled(Box)`
  padding: 2px; // 2px to prevent flashing effect when hovering (because we use a custom image style border stroke via getBorderImg)
`;

const getBorderImg = (params: {
  borderColor: string;
  borderDashGap?: number;
}) => {
  const { borderColor, borderDashGap } = params;
  return `url("data:image/svg+xml,%3csvg width='100%25' height='100%25' xmlns='http://www.w3.org/2000/svg'%3e%3crect width='100%25' height='100%25' fill='none' rx='8' ry='8' stroke='${encodeURIComponent(
    borderColor
  )}' stroke-width='2' stroke-dasharray='4%2c ${
    borderDashGap || 0
  }' stroke-dashoffset='0' stroke-linecap='square'/%3e%3c/svg%3e");`;
};
const Main = styled("div")<{ isReadyForUpload: boolean; isError: boolean }>`
  max-width: 700px;
  border-radius: 8px;
  padding: 20px;
  transition-duration: 0.2s;
  background-image: ${(props) =>
    getBorderImg({ borderColor: props.theme.palette.grey[200] })};

  ${(props) =>
    props.isReadyForUpload &&
    `
    cursor: pointer;
    background-image: ${getBorderImg({
      borderColor: props.theme.palette.grey[300],
      borderDashGap: 8,
    })};

    :hover,
    &.is-drag-accept {
      background-image: ${getBorderImg({
        borderColor: props.theme.palette.success.main || "",
        borderDashGap: 8,
      })};
    }

    &.is-drag-reject {
      background-image: ${getBorderImg({
        borderColor: props.theme.palette.error.main || "",
        borderDashGap: 8,
      })};
    }
  `}

  ${(props) =>
    props.isError &&
    `
    background-image: ${getBorderImg({
      borderColor: props.theme.palette.error.main || "",
    })};
  `}
`;

const Inner = styled("div")`
  display: flex;
  gap: 16px;
  align-items: center;
  width: 100%;
`;

const InnerLeft = styled(Box)`
  display: flex;
  color: ${(props) => props.theme.palette.grey[500]};

  img {
    height: 48px;
    max-width: 130px;
  }
`;

const InnerCenter = styled(Box)`
  display: flex;
  flex-direction: column;
  flex: 1;
  gap: 2px;
`;

const BorderedIconButton = styled(IconButton)`
  border: 1px solid ${(props) => props.theme.palette.grey[200]};
`;
