import { LoadingSpinner } from "@/components/UIKit/Feedback/LoadingSpinner";
import { Box, Typography } from "@mui/material";
import { styled } from "@mui/material/styles";

type Props = {
  details?: string;
};
export const LoadingScreen = (props: Props) => {
  return (
    <Body>
      <LoadingSpinner sx={{ height: "60px" }} />
      {props.details && <Typography>{props.details}</Typography>}
    </Body>
  );
};

const Body = styled(Box)`
  background: #fff;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100vh;
  gap: 20px;
  flex-direction: column;
`;
