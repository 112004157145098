import { Components, Theme } from "@mui/material";

const dataDisplay = (theme: Theme): Components<Theme> => ({
  MuiDivider: {
    styleOverrides: {
      root: {
        borderColor: "#E9EBED",
      },
    },
  },
  MuiSvgIcon: {
    styleOverrides: {
      colorSecondary: {
        color: "#78756E",
      },
    },
  },
  MuiTableContainer: {
    styleOverrides: {
      root: {
        border: "1px solid rgba(218, 215, 213, 0.24)",
        borderRadius: "12px",
      },
    },
  },
  MuiTableCell: {
    styleOverrides: {
      root: {
        padding: "14px 16px",
        borderColor: "rgba(218, 215, 213, 0.24)",
      },
      head: {
        color: theme.palette.text.secondary,
        paddingTop: "8px",
        paddingBottom: "8px",
        borderBottom: "none",
        background: "#FAFAFA",
        ...theme.typography.body3Emphasized,
      },
    },
  },
  MuiTableRow: {
    styleOverrides: {
      root: {
        ":last-child": {
          td: {
            borderWidth: "0px",
          },
        },
      },
    },
  },
  MuiTooltip: {
    defaultProps: {
      arrow: true,
    },
    styleOverrides: {
      tooltip: {
        color: "inherit",
        background: "#fff",
        borderRadius: "4px",
        boxSizing: "border-box",
        boxShadow:
          "0px 4px 5px -2px rgba(16, 24, 40, 0.05), 0px 12px 16px -4px rgba(16, 24, 40, 0.04);",
        border: "1px solid rgba(210, 206, 198, 0.16)",
        padding: "8px",
        fontSize: "12px",
        fontWeight: 400,
        lineHeight: "17px",
        letterSpacing: "0px",
      },
      arrow: {
        color: "#fff",
        "&:before": {
          border: "1px solid rgba(210, 206, 198, 0.16)",
        },
      },
    },
  },
});

export { dataDisplay };
