import {
  PracticePromotionModalView,
  PracticePromotionsFormInputs,
} from "./View";
import { PromotionTimingType, PromotionType } from "./constants";
import { GlobalContext } from "@/components/GlobalContext";
import {
  CreatePromotionRequest,
  Promotion,
  UpdatePromotionRequest,
  UploadTermsOrExclusionsRequest,
} from "@/types/apiContract/promotion";
import { handleApiError } from "@/utils/feedback";
import { useMutation } from "@tanstack/react-query";
import axios from "axios";
import { useContext, useState } from "react";

type Props = {
  currentPromotion?: Promotion;
  onClose: () => void;
  onComplete: () => Promise<void>;
};

export const PracticePromotionModalContainer = (props: Props) => {
  const { loggedInProvider } = useContext(GlobalContext);
  const [uploadProgress, setUploadProgress] = useState<number>();

  // create new or edit current (if currentProvider exists)
  const createPracticePromotionMutation = useMutation({
    onError: handleApiError,
    mutationFn: (values: PracticePromotionsFormInputs) => {
      const promotion = formValsToPromotion(values);

      // create new
      if (!props.currentPromotion) {
        const createPromotionRequest: CreatePromotionRequest = promotion;
        return axios.post<Promotion>(
          `practices/${loggedInProvider.id}/promotions`,
          createPromotionRequest
        );
      }

      // edit current
      const updatePromotionRequest: UpdatePromotionRequest = {
        id: props.currentPromotion.id,
        ...promotion,
      };
      return axios.put<Promotion>(
        `/practices/${loggedInProvider.id}/promotions/${props.currentPromotion.id}`,
        updatePromotionRequest
      );
    },
  });

  const uploadTermsOrExclusionsMutation = useMutation({
    onError: handleApiError,
    mutationFn: async (params: {
      promotionId: string;
      termsOrExclusionsFile: File;
    }) => {
      const { promotionId, termsOrExclusionsFile } = params;
      const formData = new FormData();
      formData.append(
        "terms-or-exclusions" satisfies keyof UploadTermsOrExclusionsRequest,
        termsOrExclusionsFile
      );
      return axios.post(
        `practices/${loggedInProvider.id}/promotions/${promotionId}/terms-or-exclusions`,
        formData,
        {
          onUploadProgress: (progressEvent) => {
            if (!progressEvent.total) return;
            const percentCompleted = Math.round(
              (progressEvent.loaded * 100) / progressEvent.total
            );
            setUploadProgress(percentCompleted);
          },
        }
      );
    },
  });

  const onSubmit = async (values: PracticePromotionsFormInputs) => {
    const { data } = await createPracticePromotionMutation.mutateAsync(values);

    if (values.termsOrExclusionsFile) {
      // doing try catch since still want to give feedback that the promotion was created even if file upload failed
      try {
        await uploadTermsOrExclusionsMutation.mutateAsync({
          promotionId: data.id,
          termsOrExclusionsFile: values.termsOrExclusionsFile,
        });
      } catch (e: any) {
        throw Error(e);
      } finally {
        await props.onComplete();
        props.onClose();
        return;
      }
    }

    await props.onComplete();
    props.onClose();
  };

  return (
    <PracticePromotionModalView
      defaultValues={promotionToFormVals(props.currentPromotion)}
      defaultTermsOrExclusionsSrc={props.currentPromotion?.termsOrExclusions} // just displaying the url into img (not actually downloading the file)
      termsOrExclusionsUploadProgress={uploadProgress}
      onClose={props.onClose}
      onSubmit={onSubmit}
    />
  );
};

const formValsToPromotion = (values: PracticePromotionsFormInputs) => {
  return {
    type: values.promotionType,
    product: values.product,
    description: values.promotionDescription,
    dateType: values.promotionTiming,
    startDate: values.promotionDateRange?.startDate,
    endDate: values.promotionDateRange?.endDate,
  };
};
const promotionToFormVals = (
  promotion?: Promotion
): Partial<PracticePromotionsFormInputs> | undefined => {
  if (!promotion) return undefined;
  return {
    promotionType: promotion.type as PromotionType,
    product: promotion.product,
    promotionDescription: promotion.description,
    promotionTiming: promotion.dateType as PromotionTimingType,
    promotionDateRange: {
      startDate: promotion.startDate,
      endDate: promotion.endDate,
    },
  };
};
