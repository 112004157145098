import { SvgIcon, SvgIconProps } from "@mui/material";

function TrashIcon(props: SvgIconProps) {
  return (
    <SvgIcon {...props}>
      <path
        d="M10.2265 10.4046C10.6181 10.4046 10.9356 10.7221 10.9356 11.1137V16.4319C10.9356 16.8235 10.6181 17.141 10.2265 17.141C9.83487 17.141 9.5174 16.8235 9.5174 16.4319V11.1137C9.5174 10.7221 9.83487 10.4046 10.2265 10.4046Z"
        fill="currentColor"
      />
      <path
        d="M14.481 11.1137C14.481 10.7221 14.1636 10.4046 13.7719 10.4046C13.3803 10.4046 13.0629 10.7221 13.0629 11.1137V16.4319C13.0629 16.8235 13.3803 17.141 13.7719 17.141C14.1636 17.141 14.481 16.8235 14.481 16.4319V11.1137Z"
        fill="currentColor"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M8.63104 6.85918V4.90918C8.63104 4.48603 8.79913 4.08022 9.09834 3.78102C9.39754 3.48181 9.80335 3.31372 10.2265 3.31372H13.7719C14.1951 3.31372 14.6009 3.48181 14.9001 3.78102C15.1993 4.08022 15.3674 4.48603 15.3674 4.90918V6.85918H19.0901C19.4817 6.85918 19.7992 7.17665 19.7992 7.56827C19.7992 7.95989 19.4817 8.27736 19.0901 8.27736H18.8562L18.0263 18.2366C18.018 18.8833 17.7575 19.5017 17.2996 19.9595C16.8342 20.425 16.2029 20.6865 15.5447 20.6865H8.45376C7.79555 20.6865 7.16429 20.425 6.69885 19.9595C6.24096 19.5016 5.98047 18.8833 5.97215 18.2366L5.14222 8.27736H4.90831C4.51669 8.27736 4.19922 7.95989 4.19922 7.56827C4.19922 7.17665 4.51669 6.85918 4.90831 6.85918H8.63104ZM10.2265 4.7319C10.1795 4.7319 10.1344 4.75058 10.1011 4.78382C10.0679 4.81707 10.0492 4.86216 10.0492 4.90918V6.85918H13.9492V4.90918C13.9492 4.86216 13.9305 4.81707 13.8973 4.78382C13.8641 4.75058 13.819 4.7319 13.7719 4.7319H10.2265ZM6.56531 8.27736L7.38768 18.1457C7.38931 18.1653 7.39013 18.185 7.39013 18.2046C7.39013 18.4867 7.50219 18.7573 7.70166 18.9567C7.90113 19.1562 8.17167 19.2683 8.45376 19.2683H15.5447C15.8268 19.2683 16.0973 19.1562 16.2968 18.9567C16.4963 18.7573 16.6083 18.4867 16.6083 18.2046C16.6083 18.185 16.6091 18.1653 16.6108 18.1457L17.4331 8.27736H6.56531Z"
        fill="currentColor"
      />
    </SvgIcon>
  );
}

export { TrashIcon };
