import { StepContainer } from "../../StepContainer";
import { AddMoreButton } from "../../UI/buttons";
import Facebook from "@/assets/socialLinks/facebook.svg";
import GoogleBusiness from "@/assets/socialLinks/google-business-profile.svg";
import Instagram from "@/assets/socialLinks/instagram.svg";
import Other from "@/assets/socialLinks/other.svg";
import Phone from "@/assets/socialLinks/phone.svg";
import Pinterest from "@/assets/socialLinks/pinterest.svg";
import RealSelf from "@/assets/socialLinks/realself.svg";
import TikTok from "@/assets/socialLinks/tiktok.svg";
import Twitter from "@/assets/socialLinks/twitter.svg";
import Website from "@/assets/socialLinks/website.svg";
import ZocDoc from "@/assets/socialLinks/zocdoc.svg";
import { ExpandableCheckbox } from "@/components/UIKit/Inputs/Checkbox/ExpandableCheckbox";
import { PhoneTextField } from "@/components/UIKit/Inputs/TextField/PhoneTextField";
import { URLTextField } from "@/components/UIKit/Inputs/TextField/URLTextField";
import { SurveySectionFields } from "@/scenes/Onboarding/types";
import {
  yupGoogleMapsUrl,
  yupPhoneNumber,
  yupURLWithoutHttps,
} from "@/utils/yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { Box, FormHelperText } from "@mui/material";
import { useEffect, useState } from "react";
import { UseFormProps, useForm } from "react-hook-form";
import * as yup from "yup";

type SubmitData = { [key in keyof FormInputs]: string };
type Props = {
  onBack: () => void;
  onContinue: (values: SubmitData) => Promise<void>;
  defaultValues?: Partial<SubmitData>;
};

const WEBSITE_URLS = {
  "google business profile": {
    label: "Google Map Link",
    icon: GoogleBusiness,
    required: true,
  },
  website: { label: "Your Website", icon: Website, required: true },
  phone: { label: "Phone Number", icon: Phone, required: false },
  facebook: { label: "Facebook", icon: Facebook, required: false },
  instagram: { label: "Instagram", icon: Instagram, required: false },
  twitter: { label: "Twitter", icon: Twitter, required: false },
  tiktok: { label: "TikTok", icon: TikTok, required: false },
  pinterest: { label: "Pinterest", icon: Pinterest, required: false },
  realself: { label: "Real Self", icon: RealSelf, required: false },
  zocdoc: { label: "Zoc Doc", icon: ZocDoc, required: false },
  other: { label: "Other", icon: Other, required: false },
  other2: { label: "Other", icon: Other, required: false },
  other3: { label: "Other", icon: Other, required: false },
};
type WebsiteUrlKey = keyof typeof WEBSITE_URLS;

// FIELDS
// IMPORTANT: treat this as a contract, this determines which values gets passed up to the backend
const generateFields = () => {
  const fields = {} as SurveySectionFields<SubmitData>;
  const websiteUrls = Object.entries(WEBSITE_URLS);
  websiteUrls.forEach((entry) => {
    const url = entry[0] as WebsiteUrlKey;
    const val = entry[1];
    fields[url] = {
      label: val.label,
      type: "string",
      example: "https://www.example.com",
    };
  });
  return fields;
};
const FIELDS = generateFields();
export { FIELDS as PracticeLinksFields };

const HTTPS_AUTO_PREFIX = "https://";

export const PracticeLinks = (props: Props) => {
  const [isValid, setIsValid] = useState(true);
  // map submitted data to form input data
  const defaultVals: Partial<FormInputs> = {};
  Object.entries(props.defaultValues || {}).forEach((entry) => {
    const url = entry[0] as WebsiteUrlKey;
    const val = entry[1];

    let strippedVal = val;
    if (val.startsWith(HTTPS_AUTO_PREFIX)) {
      strippedVal = val.slice(HTTPS_AUTO_PREFIX.length);
    }
    defaultVals[url] = {
      isChecked: Boolean(val),
      value: strippedVal,
    };
  });
  const {
    control,
    handleSubmit,
    register,
    formState: { isValid: isValidYup },
  } = usePracticeLinksForm({
    defaultValues: defaultVals,
    mode: "onChange",
  });

  useEffect(() => {
    setIsValid(isValidYup);
  }, [isValidYup]);

  // "other" fields should be instantly displayed if they have values from defaultValues
  let defaultOtherFieldCount = 0;
  if (defaultVals.other?.value) {
    defaultOtherFieldCount = 1;
  }
  if (defaultVals.other2?.value) {
    defaultOtherFieldCount = 2;
  }
  if (defaultVals.other3?.value) {
    defaultOtherFieldCount = 3;
  }

  const [formErrMessage, setFormErrMessage] = useState<string>();
  const [otherFieldCount, setOtherFieldCount] = useState(
    defaultOtherFieldCount
  );

  const onSubmit = async (values: FormInputs) => {
    const hasOneChecked = Object.values(values).some((val) => val.isChecked);
    if (!hasOneChecked) {
      setFormErrMessage("Please provide at least one link");
      return;
    }

    // map form input data to submitted data
    const submitValues: SubmitData = {};
    Object.entries(values).forEach((entry) => {
      const url = entry[0] as WebsiteUrlKey;
      const val = entry[1];
      if (val.isChecked && val.value) {
        submitValues[url] = `${HTTPS_AUTO_PREFIX}${val.value}`;
      }
    });

    setFormErrMessage(undefined);
    await props.onContinue(submitValues);
  };

  return (
    <StepContainer
      heading="Practice Links"
      description="After matching a patient to your practice, we would like to provide them with more information. Please provide any relevant links you'd like for us to include."
      onBack={props.onBack}
      onContinue={() => handleSubmit(onSubmit)()}
      isMainButtonDisable={isValid}
    >
      {Object.entries(WEBSITE_URLS).map((entry) => {
        const url = entry[0] as WebsiteUrlKey;
        const val = entry[1];

        const isPhoneField = Boolean(url === "phone");

        let isHidingField = false;
        if (
          (url === "other" && otherFieldCount < 1) ||
          (url === "other2" && otherFieldCount < 2) ||
          (url === "other3" && otherFieldCount < 3)
        ) {
          isHidingField = true;
        }

        return (
          <Box key={url} {...(isHidingField && { display: "none" })}>
            <ExpandableCheckbox
              label={val.label}
              icon={val.icon}
              required={val?.required}
              mb="12px"
              control={control}
              register={register(`${url}.isChecked`)}
            >
              {isPhoneField ? (
                <PhoneTextField
                  control={control}
                  register={register(`${url}.value`)}
                  fullWidth
                />
              ) : (
                <URLTextField
                  control={control}
                  register={register(`${url}.value`)}
                  fullWidth
                />
              )}
            </ExpandableCheckbox>
          </Box>
        );
      })}
      {otherFieldCount < 3 && (
        <AddMoreButton
          onClick={() => setOtherFieldCount((curr) => curr + 1)}
          btnText="Add link"
        />
      )}
      {formErrMessage && (
        <FormHelperText error>{formErrMessage}</FormHelperText>
      )}
    </StepContainer>
  );
};
PracticeLinks.defaultProps = {
  onBack: () => {},
  onContinue: async () => {},
};

type CheckboxString = {
  isChecked?: boolean;
  required?: boolean;
  value?: string;
};

// form fields
// IMPORTANT: treat this as a contract, this is how values are read from the backend (see defaultValues prop)
type FormInputs = {
  website?: CheckboxString;
  instagram?: CheckboxString;
  phone?: CheckboxString;
  facebook?: CheckboxString;
  twitter?: CheckboxString;
  tiktok?: CheckboxString;
  pinterest?: CheckboxString;
  realself?: CheckboxString;
  zocdoc?: CheckboxString;
  "google business profile"?: CheckboxString;
  other?: CheckboxString;
  other2?: CheckboxString;
  other3?: CheckboxString;
};

const yupCheckboxUrl = () => {
  return yup.object({
    isChecked: yup.boolean(),
    value: yupURLWithoutHttps().when("isChecked", {
      is: true,
      then: yupURLWithoutHttps().required(),
    }),
  });
};

const yupCheckboxPhone = () => {
  return yup.object({
    isChecked: yup.boolean(),
    value: yupPhoneNumber().when("isChecked", {
      is: true,
      then: yupPhoneNumber().required(),
    }),
  });
};

const yupCheckboxUrlRequired = () => {
  return yup.object({
    isChecked: yup.boolean().required().oneOf([true]),
    value: yupURLWithoutHttps().required(),
  });
};

const yupCheckboxGoogleMapsRequired = () => {
  return yup.object({
    isChecked: yup.boolean().required().oneOf([true]),
    value: yupGoogleMapsUrl().required(),
  });
};

const usePracticeLinksForm = (props?: UseFormProps<FormInputs>) => {
  let defaultValues = props?.defaultValues;
  const validationSchema: yup.SchemaOf<FormInputs> = yup.object({
    website: yupCheckboxUrlRequired(),
    instagram: yupCheckboxUrl(),
    "google business profile": yupCheckboxGoogleMapsRequired(),
    realself: yupCheckboxUrl(),
    zocdoc: yupCheckboxUrl(),
    other: yupCheckboxUrl(),
    other2: yupCheckboxUrl(),
    other3: yupCheckboxUrl(),
    phone: yupCheckboxPhone(),
    facebook: yupCheckboxUrl(),
    twitter: yupCheckboxUrl(),
    tiktok: yupCheckboxUrl(),
    pinterest: yupCheckboxUrl(),
  });

  return useForm<FormInputs>({
    ...props,
    resolver: yupResolver(validationSchema),
    defaultValues: defaultValues,
  });
};
