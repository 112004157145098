import { signOut } from "@/components/GlobalContext/utils/auth";
import { Typography, Button } from "@mui/material";
import React from "react";

export type PropTypes = {
  error: Error;
  resetErrorBoundary: () => void;
};

export const ErrorView: React.FC<PropTypes> = ({ resetErrorBoundary }) => {
  return (
    <div>
      <Typography variant="h3">Sorry.. there was an error</Typography>
      <Button onClick={resetErrorBoundary} color="primary">
        Try again
      </Button>
      <Button
        onClick={async () => {
          await signOut();
          window.location.reload();
        }}
      >
        Log out
      </Button>
    </div>
  );
};
