import { UserSection } from "../UserSection";
import joyaLogo from "@/assets/joya-logo.svg";
import { LoggedInProvider } from "@/components/GlobalContext";
import {
  Box,
  GlobalStyles,
  Link,
  Container as MuiContainer,
  Typography,
  styled,
} from "@mui/material";
import { Link as RouterLink } from "react-router-dom";

type Props = {
  provider: Pick<LoggedInProvider, "email" | "roles" | "type">;
};
export const Header = (props: Props) => {
  return (
    <div>
      <GlobalStyles
        styles={() => ({
          body: {
            background: "#fff",
          },
          ":root": {
            "--app-header-height": "80px",
          },
          "@media (max-width: 640px)": {
            ":root": {
              "--app-header-height": "80px",
            },
          },
        })}
      />
      <Body>
        <Container>
          <Box justifySelf="center">
            <Link
              component={RouterLink}
              to="/dashboard"
              sx={{ display: "block", color: "inherit" }}
              underline="none"
            >
              <Box display="flex">
                <img src={joyaLogo} height="40px" alt="joya logo" />
                <SeparatorLine />
                <ProviderPortalText>
                  Provider
                  <br />
                  Portal
                </ProviderPortalText>
              </Box>
            </Link>
          </Box>
          <UserSection user={props.provider} />
        </Container>
      </Body>
    </div>
  );
};

const Body = styled(Box)`
  background: #fff;
`;

const Container = styled(MuiContainer)`
  && {
    padding: 20px 32px;
  }
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const ProviderPortalText = styled(Typography)`
  font-size: 16px;
  font-weight: 400;
  line-height: 20px;
  letter-spacing: -0.5px;
  text-align: left;
  color: #272724;
`;

const SeparatorLine = styled(Box)`
  background: #272724;
  opacity: 0.4;
  border-radius: 24px;
  width: 1px;
  margin: 0px 20px;
`;
