import "../App.css";
import SnackbarManager from "../components/SnackbarManager";
import { theme } from "./theme";
import { CssBaseline } from "@mui/material";
import { ThemeProvider as MuiThemeProvider } from "@mui/material/styles";
import { LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import React, { PropsWithChildren } from "react";
import * as yup from "yup";

yup.setLocale({
  mixed: {
    required: "Required", // Show generic "Required" message message when field is required (instead of showing the camelCased name from useForm)
  },
});

const ThemeProvider: React.FC<PropsWithChildren> = ({ children }) => (
  <MuiThemeProvider theme={theme}>
    <LocalizationProvider dateAdapter={AdapterDateFns}>
      <CssBaseline />
      <SnackbarManager />
      {children}
    </LocalizationProvider>
  </MuiThemeProvider>
);

export default ThemeProvider;
