import { SvgIcon, SvgIconProps } from "@mui/material";

function SearchIcon(props: SvgIconProps) {
  return (
    <SvgIcon
      viewBox="0 0 22 22"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M2 8.83366C2 4.87562 5.20863 1.66699 9.16667 1.66699C13.1247 1.66699 16.3333 4.87562 16.3333 8.83366C16.3333 10.5425 15.7352 12.1117 14.7369 13.3433L19.7803 18.3867C20.0732 18.6796 20.0732 19.1544 19.7803 19.4473C19.4874 19.7402 19.0126 19.7402 18.7197 19.4473L13.6763 14.4039C12.4447 15.4022 10.8755 16.0003 9.16667 16.0003C5.20863 16.0003 2 12.7917 2 8.83366ZM9.16667 3.16699C6.03706 3.16699 3.5 5.70405 3.5 8.83366C3.5 11.9633 6.03706 14.5003 9.16667 14.5003C12.2963 14.5003 14.8333 11.9633 14.8333 8.83366C14.8333 5.70405 12.2963 3.16699 9.16667 3.16699Z"
        fill="currentColor"
      />
    </SvgIcon>
  );
}

export { SearchIcon };
