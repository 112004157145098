import {
  FormControlLabel,
  Checkbox as MuiCheckbox,
  CheckboxProps as MuiCheckboxProps,
} from "@mui/material";
import {
  Controller,
  UseControllerProps,
  UseFormRegisterReturn,
} from "react-hook-form";

export type CheckboxProps = MuiCheckboxProps & {
  control?: UseControllerProps<any>["control"];
  register?: UseFormRegisterReturn<any>;
  label?: string | React.ReactElement;
};

// <Checkbox /> component that can be easily integrated with react-hook-form (ie: errors)
// - goal is to reduce as much boilerplate in forms as possible
export const Checkbox = (props: CheckboxProps) => {
  const {
    control,
    register,
    label,
    defaultChecked = false,
    ...checkboxProps
  } = props;

  // react hook form version
  if (control && register) {
    return (
      <Controller
        control={control}
        name={register.name}
        defaultValue={defaultChecked} // fix "MUI: A component is changing the uncontrolled checked state of SwitchBase to be controlled."
        render={({ field: { value, ...rest } }) => (
          <FormControlLabel
            control={
              <MuiCheckbox {...checkboxProps} {...rest} checked={value} />
            }
            label={label}
          />
        )}
      />
    );
  }

  return (
    <FormControlLabel
      control=<MuiCheckbox {...checkboxProps} />
      label={label}
    />
  );
};
