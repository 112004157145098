import { useCallback, useState } from "react";

type UseOpenableOptions = {
  shouldStartOpen?: boolean;
  isOnCloseDisabled?: boolean;
};

const useOpenable = (options?: UseOpenableOptions) => {
  const [isOpen, setIsOpen] = useState(!!options?.shouldStartOpen);

  const onOpen = useCallback(() => {
    setIsOpen(true);
  }, []);

  const onClose = useCallback(() => {
    if (options?.isOnCloseDisabled) return;
    setIsOpen(false);
  }, [options?.isOnCloseDisabled]);

  const onToggle = useCallback(() => {
    setIsOpen(!isOpen);
  }, [isOpen]);

  return {
    isOpen,
    onOpen,
    onClose,
    onToggle,
  };
};

export default useOpenable;
