import { CloseIcon } from "../Icons";
import { ButtonProps, IconButton } from "@mui/material";
import { styled } from "@mui/material/styles";

export const DialogCloseBtn = (props: ButtonProps) => {
  return (
    <CloseBtn {...props}>
      <CloseIcon />
    </CloseBtn>
  );
};

const CloseBtn = styled(IconButton)`
  position: absolute;
  right: 24px;
  top: 24px;
  opacity: 0.8;
  transition: all 0.2s;
  z-index: 1;

  .MuiSvgIcon-root {
    font-size: 32px;
  }
  :hover {
    opacity: 1;
  }
`;
