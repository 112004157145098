import { DialogCloseBtn } from "@/components/UIKit/Modal/misc";
import useOpenable from "@/hooks/useOpenable";
import { SectionIdType } from "@/scenes/Onboarding/utils/survey";
import {
  Box,
  Dialog,
  DialogContent,
  Link,
  LinkProps,
  Typography,
  styled,
  useTheme,
} from "@mui/material";
import {
  Link as RouterLink,
  LinkProps as RouterLinkProps,
  useLocation,
} from "react-router-dom";

type Props = {
  onClose: () => void;
  hasNoOpenAnimation?: boolean;
  isDermatology?: boolean;
};

const SURVEY_SECTIONS: { sectionId: SectionIdType; title: string }[] = [
  {
    sectionId: "getting-to-know-you",
    title: "Getting to know you",
  },
  {
    sectionId: "getting-to-know-your-practice",
    title: "Getting to know your practice",
  },
  {
    sectionId: "practice-links",
    title: "Practice links",
  },
  {
    sectionId: "providers-in-your-practice",
    title: "Providers in your practice",
  },
  {
    sectionId: "our-offerings",
    title: "Our offerings",
  },
  {
    sectionId: "neurotoxin-offers",
    title: "Neurotoxins offered",
  },
  {
    sectionId: "fillers-offers",
    title: "Fillers offered",
  },
  {
    sectionId: "practice-energy-based-devices",
    title: "Practice energy-based devices",
  },
  {
    sectionId: "practice-promotions",
    title: "Practice promotions",
  },
  {
    sectionId: "scheduling-method",
    title: "Which scheduling method do you prefer for JOYA patients?",
  },
  {
    sectionId: "your-superpowers",
    title: "Your superpowers",
  },
  {
    sectionId: "practice-languages",
    title: "Practice languages",
  },
  {
    sectionId: "lets-learn-about-your-practice",
    title: "Let's learn about your practice",
  },
  {
    sectionId: "insurance-details",
    title: "Insurance details",
  },
];
export const EditProfileModal = (props: Props) => {
  const location = useLocation();
  const theme = useTheme();
  const { isOpen, onClose } = useOpenable({ shouldStartOpen: true });

  const filteredSections = SURVEY_SECTIONS.filter(
    (section) =>
      props.isDermatology || section.sectionId !== "insurance-details"
  );

  return (
    <EditProfileDialog
      open={isOpen}
      onClose={onClose}
      onTransitionExited={props.onClose}
      {...(props.hasNoOpenAnimation && {
        transitionDuration: {
          exit: theme.transitions.duration.leavingScreen,
          enter: 0,
        },
      })}
    >
      <DialogContent sx={{ px: "24px" }}>
        <DialogCloseBtn onClick={onClose} />
        <Box mb="24px" pl="16px">
          <Typography variant="h3" mb="12px">
            Edit Profile
          </Typography>
          <Typography
            display="block"
            variant="body3Emphasized"
            color="text.secondary"
          >
            Please select the step you want to edit.
          </Typography>
        </Box>
        <Box display="flex" flexDirection="column" gap="2px">
          {filteredSections.map((section, i) => {
            return (
              <LinkItem
                key={section.sectionId}
                display="block"
                component={RouterLink}
                to={`/onboarding/${section.sectionId}`}
                state={{ from: location.pathname }}
                underline="none"
                color="text.primary"
                onClick={onClose}
              >
                {i + 1}. {section.title}
              </LinkItem>
            );
          })}
        </Box>
      </DialogContent>
    </EditProfileDialog>
  );
};

const LinkItem = styled(Link)<LinkProps & RouterLinkProps>`
  padding: 10px 16px;
  border-radius: 8px;
  :hover {
    background: #f6f5f4;
  }
`;

const EditProfileDialog = styled(Dialog)`
  .MuiDialog-paper {
    max-width: 700px;
    width: 100%;
  }
`;
