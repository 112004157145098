import { QUESTION_IDS_SET, SURVEY_SECTIONS } from "../constants";
import { SurveySectionFieldData } from "../types";
import { UpdateSurveyRequest } from "@/types/apiContract/survey";
import { isJSONString } from "@/utils/validation";
import { isBoolean, isObject } from "lodash-es";

export type SectionIdType = keyof typeof SURVEY_SECTIONS;

// get questionId based on specific field (fieldId) within a section (sectionId)
const getQuestionId = (params: { sectionId: string; fieldId: string }) => {
  const questionId = `${params.sectionId}_${params.fieldId}`;
  if (!QUESTION_IDS_SET.has(questionId)) {
    throw Error("question id not found");
  }
  return questionId;
};

// get survey section data by id (sectionId)
const getSurveySection = (params: { sectionId: SectionIdType }) => {
  const surveySection = SURVEY_SECTIONS[params.sectionId];
  if (!surveySection) {
    throw Error("survey section not found, invalid sectionId");
  }
  return surveySection;
};

// check if section id is valid
const isValidSectionId = (params: { sectionId: string }) => {
  return Object.keys(SURVEY_SECTIONS).includes(params.sectionId);
};

// parse values pulled from the backend (backend can only take in array of strings)
// - if single value array, just return the val (no array). if multiple values in array return as array (w/ each value parsed)
const parseAnswer = (answer: string[]) => {
  if (answer.length === 1) {
    return parseVal(answer[0]);
  } else {
    return answer.map((val) => parseVal(val));
  }
};
const parseVal = (val: string) => {
  let parsedVal: string | boolean = val;
  if (isJSONString(val)) {
    parsedVal = JSON.parse(val);
  }
  if (val.toLowerCase() === "true") {
    parsedVal = true;
  } else if (val.toLowerCase() === "false") {
    parsedVal = false;
  }
  return parsedVal;
};

// pull out values from an answered set of questions (surveyQuestions) for a given survey section (sectionId)
// NOTE: backend brings in an array of strings as the answer
const getSurveyAnswers = (params: {
  surveyQuestions?: { answer: string[]; meta: { id: string } }[];
  sectionId: SectionIdType;
}) => {
  const { surveyQuestions, sectionId } = params;
  if (!surveyQuestions) {
    return {};
  }

  const defaultVals: { [key: string]: any } = {};
  const pulledQuestionIds = new Set();
  surveyQuestions.forEach((q) => {
    const surveySection = getSurveySection({ sectionId });
    Object.entries(surveySection.fields).forEach((entry) => {
      const [fieldId] = entry;
      const questionId = getQuestionId({ sectionId, fieldId });
      if (questionId === q.meta.id) {
        // check if question ids are unique (console.warn only)
        if (pulledQuestionIds.has(questionId)) {
          console.warn(
            `duplicate question id found: ${questionId} while getting survey answers, will use the last one found`
          );
        }
        pulledQuestionIds.add(questionId);

        defaultVals[fieldId] = parseAnswer(q.answer);
      }
    });
  });
  return defaultVals;
};

const getStep = (sectionId: SectionIdType) => {
  return getSurveySection({ sectionId }).step;
};

// get current step based on given survey section
// NOTE: steps start at 1
const getCurrentSurveyStep = (params: {
  surveyQuestions?: { answer: string[]; meta: { id: string } }[];
  isDermatology?: boolean;
}) => {
  const { surveyQuestions, isDermatology } = params;
  // filter out insurance details if provider of type dermatology
  const filteredSurveySection = Object.entries(SURVEY_SECTIONS).filter(
    ([sectionId]) => !(!isDermatology && sectionId === "insurance-details")
  );
  let FINAL_STEP = 1;
  filteredSurveySection.forEach(([_sectionId, section]) => {
    FINAL_STEP = Math.max(FINAL_STEP, section.step);
  });

  let currStep = 1;

  if (!surveyQuestions) {
    return currStep;
  }

  const lastQuestionDone = surveyQuestions.slice(-1);
  const lastStepId = lastQuestionDone[0].meta.id.split("_")[0];
  filteredSurveySection.some(([stepId, step]) => {
    if (stepId === lastStepId) {
      currStep = step.step + 1;
      return true;
    }
    return false;
  });

  // cant go past final step
  if (currStep > FINAL_STEP) {
    return FINAL_STEP;
  }

  return currStep;
};

// format values to be pushed to the backend (backend can only take in array of strings)
const formatVal = (val: any): string[] => {
  let formattedVal = val;
  if (isObject(val)) {
    formattedVal = JSON.stringify(val);
  }
  if (isBoolean(val)) {
    formattedVal = val.toString();
  }
  return [formattedVal];
};

// map form values to survey answers to push to the backend (UpdateSurveyRequest.questions)
// NOTE: this gets field data (ie: label, field id) via getSurveySection()
const valuesToAnsweredSurveyQuestions = (params: {
  sectionId: SectionIdType;
  values: { [key: string]: any };
}): UpdateSurveyRequest["questions"] => {
  const surveySection = getSurveySection({ sectionId: params.sectionId });
  // looping by value here because then it can overwrite fields
  // - ie: say a field has a value, they clear it out -> becomes undefined -> that value doesnt get passed up because it was undefined,
  //       but even if that {field: value} pair wasnt passed up by the form it will still overwrite the value as undefined because this loops through fields and not value
  const surveyQuestions = Object.entries(surveySection.fields).map((entry) => {
    const fieldId = entry[0];
    const field = entry[1] as SurveySectionFieldData<any>;
    const questionId = getQuestionId({
      sectionId: params.sectionId,
      fieldId,
    });

    const answer = formatVal(params.values[fieldId]);
    return {
      meta: {
        id: questionId,
        title: field.label,
      },
      answer: answer,
      options: field.selectOptions?.map((opt) => {
        return { value: opt };
      }),
    };
  });
  return surveyQuestions;
};

export {
  getSurveyAnswers,
  getCurrentSurveyStep,
  valuesToAnsweredSurveyQuestions,
  getStep,
  isValidSectionId,
};
