// Promotion Type Options
const PROMOTION_TYPE_OPTIONS_CONST = [
  { label: "Practice Sponsored", value: "PRACTICE_SPONSORED" },
  { label: "Manufacturer Sponsored", value: "MANUFACTURER_SPONSORED" },
] as const;
export const PROMOTION_TYPE_OPTIONS = [...PROMOTION_TYPE_OPTIONS_CONST];
export type PromotionType = (typeof PROMOTION_TYPE_OPTIONS)[number]["value"];

// Timing Options
const PROMOTION_TIMING_OPTIONS_CONST = [
  { label: "Date", value: "DATE" },
  { label: "Perpetual", value: "PERPETUAL" },
] as const;
export const PROMOTION_TIMING_OPTIONS = [...PROMOTION_TIMING_OPTIONS_CONST];
export type PromotionTimingType =
  (typeof PROMOTION_TIMING_OPTIONS)[number]["value"];
