import { ChangeRole } from "./types";
import { Assistant } from "@/types/apiContract/assistant";

export const getAssistantsUpdateInfo = (params: {
  assistants: { id: string; role: Assistant["role"] }[];
  toDeleteAssistants: string[];
  toChangeRolesAssistants: Pick<ChangeRole, "assistantId" | "roleTo">[];
}) => {
  const { assistants, toChangeRolesAssistants, toDeleteAssistants } = params;

  let adminCount = 0;
  assistants.forEach((assistant) => {
    const willBeDeleted = toDeleteAssistants.includes(assistant.id);
    if (willBeDeleted) return;

    let finalRole = assistant.role;
    const updatedRole = toChangeRolesAssistants.find((changeRole) => {
      return changeRole.assistantId === assistant.id;
    })?.roleTo;
    if (updatedRole) finalRole = updatedRole;
    if (finalRole === "ADMIN") {
      adminCount++;
    }
  });

  return { adminCount };
};
