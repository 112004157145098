import { Box, BoxProps, Typography, styled } from "@mui/material";

const CONTACT_EMAIL = "provider@joyahealth.com";
export const NeedHelpText = (props: BoxProps) => {
  return (
    <Box {...props}>
      <Typography>
        <Typography variant="body3" sx={{ color: "#F6F5F4" }}>
          Need Help? Contact us at
        </Typography>{" "}
        <Typography variant="body3Emphasized" color="#fff">
          <AnchorTag href={`mailto:${CONTACT_EMAIL}`}>
            {CONTACT_EMAIL}
          </AnchorTag>
        </Typography>
      </Typography>
    </Box>
  );
};

const AnchorTag = styled("a")`
  color: inherit;
  text-decoration: none;
`;
