// formats the business day for provider api (remove unnecessary values)
export const cleanBusinessDay = (businessDay: {
  start: string;
  end: string;
}) => {
  if (!businessDay || !businessDay.start || !businessDay.end) {
    return undefined;
  }
  return {
    start: businessDay.start,
    end: businessDay.end,
  };
};
