import { SvgIcon, SvgIconProps } from "@mui/material";

function CheckCircleOutlineIcon(props: SvgIconProps) {
  return (
    <SvgIcon {...props} viewBox="0 0 18 19" fill="none">
      <g>
        <path
          d="M7.74 11.371L5.805 9.43598C5.64 9.27098 5.43 9.18848 5.175 9.18848C4.92 9.18848 4.71 9.27098 4.545 9.43598C4.38 9.60098 4.2975 9.81098 4.2975 10.066C4.2975 10.321 4.38 10.531 4.545 10.696L7.11 13.261C7.29 13.441 7.5 13.531 7.74 13.531C7.98 13.531 8.19 13.441 8.37 13.261L13.455 8.17598C13.62 8.01098 13.7025 7.80098 13.7025 7.54598C13.7025 7.29098 13.62 7.08098 13.455 6.91598C13.29 6.75098 13.08 6.66848 12.825 6.66848C12.57 6.66848 12.36 6.75098 12.195 6.91598L7.74 11.371ZM9 18.751C7.755 18.751 6.585 18.5146 5.49 18.0418C4.395 17.569 3.4425 16.9279 2.6325 16.1185C1.8225 15.3085 1.1814 14.356 0.7092 13.261C0.237 12.166 0.0006 10.996 0 9.75098C0 8.50598 0.2364 7.33598 0.7092 6.24098C1.182 5.14598 1.8231 4.19348 2.6325 3.38348C3.4425 2.57348 4.395 1.93238 5.49 1.46018C6.585 0.987977 7.755 0.751577 9 0.750977C10.245 0.750977 11.415 0.987377 12.51 1.46018C13.605 1.93298 14.5575 2.57408 15.3675 3.38348C16.1775 4.19348 16.8189 5.14598 17.2917 6.24098C17.7645 7.33598 18.0006 8.50598 18 9.75098C18 10.996 17.7636 12.166 17.2908 13.261C16.818 14.356 16.1769 15.3085 15.3675 16.1185C14.5575 16.9285 13.605 17.5699 12.51 18.0427C11.415 18.5155 10.245 18.7516 9 18.751ZM9 16.951C11.01 16.951 12.7125 16.2535 14.1075 14.8585C15.5025 13.4635 16.2 11.761 16.2 9.75098C16.2 7.74098 15.5025 6.03848 14.1075 4.64348C12.7125 3.24848 11.01 2.55098 9 2.55098C6.99 2.55098 5.2875 3.24848 3.8925 4.64348C2.4975 6.03848 1.8 7.74098 1.8 9.75098C1.8 11.761 2.4975 13.4635 3.8925 14.8585C5.2875 16.2535 6.99 16.951 9 16.951Z"
          fill="currentColor"
        />
      </g>
      <defs>
        <clipPath>
          <rect
            width="18"
            height="18"
            fill="white"
            transform="translate(0 0.750977)"
          />
        </clipPath>
      </defs>
    </SvgIcon>
  );
}

export { CheckCircleOutlineIcon };
