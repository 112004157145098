import { StepContainer } from "../../StepContainer";
import { CardButton } from "../../UI/buttons";
import { GlobalContext } from "@/components/GlobalContext";
import { AddInsuranceModal } from "@/components/Modals/AddInsuranceModal";
import {
  AddIcon,
  ChevronDownIcon,
  EditIcon,
  TrashIcon,
} from "@/components/UIKit/Icons";
import useOpenable from "@/hooks/useOpenable";
import {
  GetInsurancesResponse,
  Insurance,
  UpdateInsurancesRequest,
} from "@/types/apiContract/insurance";
import { handleApiError } from "@/utils/feedback";
import {
  Box,
  Card,
  Collapse,
  IconButton,
  Skeleton,
  Typography,
  styled,
} from "@mui/material";
import { useMutation, useQuery } from "@tanstack/react-query";
import axios from "axios";
import { useContext, useState } from "react";

type Props = {
  onBack: () => void;
  onContinue: () => Promise<void>;
};

export const InsuranceDetails = (props: Props) => {
  const { isOpen, onClose, onOpen } = useOpenable();
  const { loggedInProvider } = useContext(GlobalContext);

  const putInsuranceMutation = useMutation({
    onError: handleApiError,
    onSuccess: async () => {
      await pickedInsurancesQuery.refetch();
    },
    mutationFn: async (params: { insurances: Insurance[] }) => {
      const pickedPlanIds: string[] = [];
      params.insurances.forEach((x) => {
        x.plans.forEach((y) => {
          pickedPlanIds.push(y.id);
        });
      });
      const req: UpdateInsurancesRequest = {
        insuranceIds: [...pickedPlanIds],
      };
      return axios.put(`/practices/${loggedInProvider.id}/insurances`, req);
    },
  });

  const pickedInsurancesQuery = useQuery({
    queryKey: ["insurances"],
    queryFn: async () => {
      return axios.get<GetInsurancesResponse>(
        `/practices/${loggedInProvider.id}/insurances`
      );
    },
    throwOnError: true, // will trigger react error boundary
  });
  const pickedInsurances = pickedInsurancesQuery.data?.data || [];

  const onSubmit = async () => {
    await props.onContinue();
  };

  const onInsuranceDelete = (insurance: Insurance) => {
    const newInsurances = {
      insurances: pickedInsurances.filter((i) => i.id !== insurance.id),
    };
    putInsuranceMutation.mutate(newInsurances);
  };

  return (
    <StepContainer
      heading="Insurance details"
      description="Please choose all types of insurance that you accept."
      onBack={props.onBack}
      onContinue={onSubmit}
    >
      {isOpen && (
        <AddInsuranceModal
          onClose={onClose}
          onComplete={async () => {
            await pickedInsurancesQuery.refetch();
          }}
        />
      )}
      {pickedInsurancesQuery.isLoading ? (
        <LoadingSkeleton />
      ) : (
        <Box>
          <Box mb="24px" display="flex" flexDirection="column" gap="12px">
            {pickedInsurances.map((insurance) => {
              return (
                <InsuranceCard
                  key={insurance.id}
                  insurance={insurance}
                  onOpen={onOpen}
                  onDelete={() => onInsuranceDelete(insurance)}
                  disable={putInsuranceMutation.isPending}
                />
              );
            })}
          </Box>
          <CardButton startIcon={<AddIcon />} onClick={onOpen}>
            <Typography variant="body2Emphasized">Add an insurance</Typography>
          </CardButton>
        </Box>
      )}
    </StepContainer>
  );
};

type InsuranceProps = {
  insurance: Insurance;
  onOpen: () => void;
  onDelete: () => void;
  disable: boolean;
};

const InsuranceCard = (props: InsuranceProps) => {
  const { insurance, onOpen, onDelete, disable } = props;
  const [isOpen, setIsOpen] = useState(false);

  return (
    <InsuranceStyledCard key={insurance.id}>
      <Box>
        <Typography variant="body2" mb="4px">
          {insurance.name}
        </Typography>
        <Typography color="text.secondary" variant="body3">
          <Box display="flex" alignItems="center" gap="4px">
            {insurance.plans.length} plan
            {insurance.plans.length !== 1 && "s"} selected
            <IconButton onClick={() => setIsOpen((curr) => !curr)}>
              <ChevronDownIcon
                sx={{
                  transition: "transform 0.2s",
                  ...(isOpen && {
                    transform: "rotate(180deg)",
                  }),
                }}
              />
            </IconButton>
          </Box>
          <Collapse in={isOpen}>
            <Box>
              {insurance.plans.map((plan) => {
                return (
                  <Box
                    key={plan.name}
                    sx={{ borderLeft: "2px solid #eee", pl: "10px", mt: "6px" }}
                  >
                    <Typography variant="body3" color="text.secondary">
                      {plan.name}
                    </Typography>
                  </Box>
                );
              })}
            </Box>
          </Collapse>
        </Typography>
      </Box>
      <Box display="flex" alignItems="center" gap="20px">
        <IconButton onClick={onOpen}>
          <EditIcon />
        </IconButton>
        <IconButton onClick={onDelete} disabled={disable}>
          <TrashIcon />
        </IconButton>
      </Box>
    </InsuranceStyledCard>
  );
};

const InsuranceStyledCard = styled(Card)`
  padding: 17px 20px;
  display: flex;
  justify-content: space-between;
`;

const LoadingSkeleton = () => {
  return (
    <Box pb="20px">
      {Array(3)
        .fill(null)
        .map((_, i) => (
          <Skeleton
            key={i}
            variant="rectangular"
            height={84}
            sx={{ my: "12px", borderRadius: "12px" }}
            width="100%"
          />
        ))}
    </Box>
  );
};
