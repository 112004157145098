import { CheckIcon } from "@/components/UIKit/Icons";
import { BENEFITS_DATA } from "@/scenes/Checkout/constants";
import { Member } from "@/types/apiContract/member";
import { Box, Typography, styled } from "@mui/material";
import { format, parseISO } from "date-fns";
import { startCase } from "lodash-es";

type Props = {
  benefitsInfo: Member["consumerClaimHistory"]["benefitsInfo"];
};
export const BenefitsCard = (props: Props) => {
  return (
    <Card>
      <Typography variant="h6" mb="24px">
        BENEFITS
      </Typography>
      <Box>
        {props.benefitsInfo.map((benefit, index) => {
          const name = startCase(benefit.type.toLowerCase());
          let status = "Available";

          if (
            benefit.claimedOn &&
            benefit.type !== "SKIN_CARE_PRODUCT_DISCOUNT"
          ) {
            status = `Claimed on ${format(
              parseISO(benefit.claimedOn),
              "MMMM d"
            )}`;
          }
          const isClaimed =
            benefit.claimed && benefit.type !== "SKIN_CARE_PRODUCT_DISCOUNT";

          return (
            <BenefitsRow key={index}>
              <Box display="flex" alignItems="center" gap="16px">
                <BenefitsIcon
                  {...(isClaimed && {
                    sx: { background: "#C0BDB7", color: "#fff" },
                  })}
                >
                  {isClaimed ? (
                    <CheckIcon />
                  ) : (
                    <img
                      src={BENEFITS_DATA[benefit.type].iconSrc}
                      alt={benefit.type}
                    />
                  )}
                </BenefitsIcon>
                <Typography variant="body2">
                  {isClaimed ? <s>{name}</s> : name}
                </Typography>
              </Box>
              <Typography variant="body3" color="textSecondary">
                {status}
              </Typography>
            </BenefitsRow>
          );
        })}
      </Box>
    </Card>
  );
};

const BenefitsRow = styled(Box)`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-bottom: 12px;
  margin-bottom: 12px;
  border-bottom: 0.5px solid #eeeef1;

  &:last-child {
    padding-bottom: 0px;
    margin-bottom: 0px;
    border-bottom: none;
  }

  s {
    text-decoration-thickness: 1px;
  }
`;

const BenefitsIcon = styled(Box)`
  background: #f2f3f0;
  padding: 8px;
  display: flex;
  border-radius: 100%;
`;

const Card = styled(Box)`
  border: 1px solid #e9ebed;
  padding: 20px;
  border-radius: 12px;
`;
