import {
  PROMOTION_TIMING_OPTIONS,
  PROMOTION_TYPE_OPTIONS,
  PromotionTimingType,
  PromotionType,
} from "./constants";
import { FormRow } from "@/components/UIKit/Form/styled";
import { DateRangeSelect } from "@/components/UIKit/Inputs/Date/DateRangeSelect";
import { FileDropzone } from "@/components/UIKit/Inputs/File/FileDropzone";
import { RadioGroup } from "@/components/UIKit/Inputs/RadioGroup/RadioGroup";
import { TextField } from "@/components/UIKit/Inputs/TextField/TextField";
import { DialogCloseBtn } from "@/components/UIKit/Modal/misc";
import useOpenable from "@/hooks/useOpenable";
import { yupResolver } from "@hookform/resolvers/yup";
import { LoadingButton } from "@mui/lab";
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  FormLabel,
  Typography,
} from "@mui/material";
import { formatISO, parseISO } from "date-fns";
import isToday from "date-fns/isToday";
import { useEffect, useState } from "react";
import { UseFormProps, useForm } from "react-hook-form";
import * as yup from "yup";

type Props = {
  defaultValues?: Partial<FormInputs>;
  defaultTermsOrExclusionsSrc?: string;
  termsOrExclusionsUploadProgress?: number;
  onSubmit: (data: FormInputs) => Promise<void>;
  onClose: () => void;
};

export const PracticePromotionModalView = (props: Props) => {
  const defaultTermsOrExclusionsSrc =
    props.defaultTermsOrExclusionsSrc &&
    props.defaultTermsOrExclusionsSrc + "?version=" + new Date().getTime(); // this url doesnt actually change, so need datetime to force refetch (bypass old cached image)

  const [isLoading, setIsLoading] = useState(false);
  const [isFileValid, setIsFileValid] = useState(true);
  const { isOpen, onClose } = useOpenable({
    shouldStartOpen: true,
    isOnCloseDisabled: isLoading,
  });

  const {
    control,
    handleSubmit,
    register,
    formState: { errors },
    watch,
    setValue,
  } = usePracticePromotionsForm({
    defaultValues: {
      promotionType: "PRACTICE_SPONSORED",
      promotionTiming: "DATE",
      promotionDateRange: { startDate: null, endDate: null },
      ...props.defaultValues,
    },
  });
  const promotionTiming = watch("promotionTiming");
  const promotionDateRange = watch("promotionDateRange");
  const termsOrExclusionsFile = watch("termsOrExclusionsFile");

  useEffect(() => {
    if (promotionTiming === "PERPETUAL") {
      setValue("promotionDateRange", { startDate: null, endDate: null });
    }
  }, [promotionTiming, setValue]);

  const onSubmit = async (data: FormInputs) => {
    setIsLoading(true);

    try {
      await props.onSubmit({
        ...data,
        ...(data.promotionDateRange &&
          data.promotionDateRange.startDate &&
          data.promotionDateRange.endDate && {
            promotionDateRange: {
              startDate: formatDateToISO(data.promotionDateRange.startDate),
              endDate: formatDateToISO(data.promotionDateRange.endDate),
            },
          }),
      });
    } catch (e: any) {
      throw Error(e);
    } finally {
      setIsLoading(false);
    }
  };

  const isNew = props.defaultValues === undefined;
  return (
    <Dialog
      open={isOpen}
      onClose={onClose}
      onTransitionExited={props.onClose}
      maxWidth={false}
    >
      <DialogContent sx={{ width: "700px" }}>
        <DialogCloseBtn onClick={onClose} disabled={isLoading} />
        <Typography variant="h3" mb="12px">
          {isNew ? "Add" : "Edit"} a promotion
        </Typography>
        <Typography
          display="block"
          variant="body3Emphasized"
          mb="24px"
          color="text.secondary"
        >
          Promotions will be surfaced to your matched patients for appointment
          booking.
          <br />
          You can add or edit promotions from your dashboard after this
          questionnaire.
        </Typography>
        <FormRow>
          <RadioGroup
            control={control}
            register={register("promotionType")}
            label="Promotion type"
            row
            options={PROMOTION_TYPE_OPTIONS}
          />
        </FormRow>
        <FormRow>
          <TextField
            control={control}
            register={register("product")}
            label="Product"
            placeholder="Product"
          />
        </FormRow>
        <FormRow>
          <TextField
            control={control}
            register={register("promotionDescription")}
            label="Promotion description"
            placeholder="Include quantity, % discount and/or $ discount"
          />
        </FormRow>
        <FormRow mb="16px">
          <RadioGroup
            control={control}
            register={register("promotionTiming")}
            label="Promotion date"
            row
            options={PROMOTION_TIMING_OPTIONS}
          />
        </FormRow>
        {promotionTiming === "DATE" && (
          <FormRow>
            <DateRangeSelect
              control={control}
              name="promotionDateRange"
              format="PP"
              value={
                promotionDateRange &&
                promotionDateRange.startDate &&
                promotionDateRange.endDate
                  ? {
                      startDate: parseISO(promotionDateRange.startDate),
                      endDate: parseISO(promotionDateRange.endDate),
                    }
                  : undefined
              }
              textFieldProps={{
                placeholder: "Select start and end date",
                error: Boolean(errors?.promotionDateRange),
                helperText:
                  errors?.promotionDateRange?.message ||
                  errors?.promotionDateRange?.startDate?.message ||
                  errors?.promotionDateRange?.endDate?.message,
              }}
              dualCalendars
              dateCalendarProps={{ disablePast: true }}
            />
          </FormRow>
        )}
        <Box mb="16px">
          <FormLabel>
            <Typography component="span" variant="inherit">
              Terms or exclusions
            </Typography>
            <Typography
              component="span"
              variant="body3"
              color="text.secondary"
              ml="8px"
            >
              Optional
            </Typography>
          </FormLabel>
        </Box>
        <FileDropzone
          subtitle="PDF, SVG, JPG or GIF (max. 10MB)"
          options={{
            accept: {
              "image/svg": [".svg"],
              "image/jpg": [".jpg"],
              "image/jpeg": [".jpeg"],
              "image/gif": [".gif"],
              "application/pdf": [".pdf"],
            },
            maxSizeInMB: 10,
          }}
          value={termsOrExclusionsFile}
          onChange={(file) => {
            setValue("termsOrExclusionsFile", file);
          }}
          emptyFilePreviewImg={defaultTermsOrExclusionsSrc}
          uploadProgress={props.termsOrExclusionsUploadProgress}
          onValidChange={({ isValid }) => {
            setIsFileValid(isValid);
          }}
        />
      </DialogContent>
      <DialogActions>
        <Button variant="text" onClick={onClose} disabled={isLoading}>
          Cancel
        </Button>
        <LoadingButton
          onClick={() => handleSubmit(onSubmit)()}
          loading={isLoading}
          variant="contained"
          disabled={!isFileValid}
        >
          {isNew ? "Add" : "Save"}
        </LoadingButton>
      </DialogActions>
    </Dialog>
  );
};
PracticePromotionModalView.defaultProps = {
  onSubmit: async () => {},
  onClose: () => {},
};

const formatDateToISO = (date: string) => {
  return formatISO(new Date(date), { representation: "date" });
};

// form fields
type FormInputs = {
  promotionType: PromotionType;
  product: string;
  promotionDescription: string;
  promotionTiming: PromotionTimingType;
  promotionDateRange?: { startDate?: string | null; endDate?: string | null };
  termsOrExclusionsFile?: File;
};
export type { FormInputs as PracticePromotionsFormInputs };

const usePracticePromotionsForm = (props?: UseFormProps<FormInputs>) => {
  let defaultValues = props?.defaultValues;
  const validationSchema: yup.SchemaOf<FormInputs> = yup.object({
    promotionType: yup
      .mixed<PromotionType>()
      .oneOf(PROMOTION_TYPE_OPTIONS.map((o) => o.value))
      .required(),
    product: yup.string().required(),
    promotionDescription: yup.string().required(),
    promotionTiming: yup
      .mixed<PromotionTimingType>()
      .oneOf(PROMOTION_TIMING_OPTIONS.map((o) => o.value))
      .required(),
    promotionDateRange: yup
      .object({
        startDate: yup.string().nullable(),
        endDate: yup.string().nullable(),
      })
      .default(undefined)
      .optional()
      .when("promotionTiming", {
        is: "DATE",
        then: yup.object({
          startDate: yup.string().required().typeError("Required"),
          endDate: yup
            .string()
            .required()
            .typeError("Required")
            .test("is-future", "End date cannot be today", (value) => {
              if (value && isToday(new Date(value))) return false;
              return true;
            }),
        }),
      }),
    termsOrExclusionsFile: yup.mixed().nullable(),
  });

  return useForm<FormInputs>({
    ...props,
    resolver: yupResolver(validationSchema),
    defaultValues: defaultValues,
  });
};
