import { NeedHelpText } from "./UI";
import { Box, styled } from "@mui/material";

export const OnboardingFooter = () => {
  return (
    <Body>
      <NeedHelpText />
    </Body>
  );
};

const Body = styled(Box)`
  display: none;
  padding: 8px 0px 16px;

  ${(props) => props.theme.breakpoints.down("tablet")} {
    display: grid;
    place-items: center;
  }
`;
