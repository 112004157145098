import { StepContainer } from "../../StepContainer";
import { OFFERINGS_OPTIONS } from "./constants";
import { FormCard } from "@/components/UIKit/Form/styled";
import { SurveySectionFields } from "@/scenes/Onboarding/types";
import { Offering } from "@/types/apiContract/practice";
import {
  Box,
  Checkbox,
  FormHelperText,
  Typography,
  styled,
} from "@mui/material";
import { useState } from "react";

type Props = {
  isDermatology?: boolean;
  offeringOptions: Offering[];
  defaultValues?: Partial<FormInputs>;
  onBack: () => void;
  onContinue: (data: FormInputs) => Promise<void>;
};
const TITLE = "Our offerings";
const SUBTITLE = `Does your practice offer services in the following ${OFFERINGS_OPTIONS.length} categories?`;

// FIELDS
// IMPORTANT: treat this as a contract, this determines which values gets passed up to the backend
const FIELDS: SurveySectionFields<FormInputs> = {
  offerings: {
    label: TITLE,
    subtitle: SUBTITLE,
    type: "json",
    example: [
      {
        id: "id123",
        name: "name123",
        type: "JOYA",
      },
      {
        id: "id345",
        name: "name345",
        type: "JOYA",
      },
    ],
  },
};
export { FIELDS as OurOfferingsFields };

export const OurOfferings = (props: Props) => {
  const offeringIds = new Set(props.defaultValues?.offerings?.map((x) => x.id));
  const [offerings, setOfferings] = useState(new Set<string>(offeringIds));
  const [formErrMessage, setFormErrMessage] = useState<string>();

  const onSubmit = async () => {
    const offeringsArr = props.offeringOptions.filter((of) => {
      return (
        offerings.has(of.id) &&
        (props.isDermatology || of.name !== "Medical Dermatology")
      );
    });
    if (offeringsArr.length < 1) {
      setFormErrMessage("Please select at least one offering");
      return;
    }

    setFormErrMessage(undefined);
    await props.onContinue({ offerings: offeringsArr });
  };

  return (
    <StepContainer
      heading={TITLE}
      description={SUBTITLE}
      onBack={props.onBack}
      onContinue={onSubmit}
    >
      {props.offeringOptions.map((item) => {
        if (!props.isDermatology && item.name === "Medical Dermatology") {
          return null;
        }

        const isSelected = offerings.has(item.id);
        const onCheckboxChange = () => {
          setOfferings((prev) => {
            const newOfferings = new Set(prev);
            if (!isSelected) {
              newOfferings.add(item.id);
            } else {
              newOfferings.delete(item.id);
            }
            return newOfferings;
          });
        };
        const info = OFFERINGS_OPTIONS.find((x) => x.name === item.name);

        return (
          <OurOfferingCard
            key={item.id}
            isChecked={isSelected}
            onClick={() => onCheckboxChange()}
          >
            <Box display="flex" alignItems="center" gap="17px">
              <Box display="flex">
                <img src={info?.iconSrc} alt={item.name} />
              </Box>
              <Box display="flex" flexDirection="column">
                <Typography variant="body1Medium">{item.name}</Typography>
                <Typography variant="body3" color="text.secondary">
                  {info?.description}
                </Typography>
              </Box>
            </Box>
            <Box>
              <Checkbox sx={{ m: 0 }} checked={isSelected} />
            </Box>
          </OurOfferingCard>
        );
      })}
      {formErrMessage && (
        <FormHelperText error>{formErrMessage}</FormHelperText>
      )}
    </StepContainer>
  );
};

// IMPORTANT: treat this as a contract, this is how values are read from the backend (see defaultValues prop)
type FormInputs = { offerings: Offering[] };

const OurOfferingCard = styled(FormCard, {
  shouldForwardProp: (prop) => prop !== "isChecked",
})<{ isChecked?: boolean }>`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px 10px 10px 25px;
  min-height: 82px;
  margin-bottom: 12px;
  cursor: pointer;
  transition-duration: 0.2s;
  border-color: rgba(0, 0, 0, 0);
  box-shadow: 0px 2px 16px 0px rgba(181, 176, 168, 0.1);

  :hover {
    border-color: #ffd700;
  }

  ${(props) =>
    props.isChecked &&
    `
    box-shadow: 0px 0px 0px 3px rgba(255, 217, 0, 0.16);
    border-color: #FFD700;
  `}
`;
