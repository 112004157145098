import { ArrowLeftIcon, ArrowRightIcon } from "@/components/UIKit/Icons";
import { shadowedTextFieldsSx } from "@/themes/customization/inputs";
import { LoadingButton } from "@mui/lab";
import { Box, Button, Typography, styled } from "@mui/material";
import { PropsWithChildren, useState } from "react";
import { useParams } from "react-router-dom";

type Props = {
  heading: string;
  description: string;
  isMainButtonDisable?: boolean;
  onBack?: () => void;
  onContinue: () => Promise<void>;
};
export const StepContainer = (props: PropsWithChildren<Props>) => {
  const { onBack, onContinue, isMainButtonDisable = true } = props;
  const [isLoading, setIsLoading] = useState(false);
  const { sectionId: sectionIdFromURL } = useParams<{ sectionId: string }>();

  const onContinueClick = async () => {
    setIsLoading(true);
    try {
      await onContinue();
    } catch (e: any) {
      throw Error(e);
    } finally {
      setIsLoading(false);
    }
  };
  return (
    <Body sx={shadowedTextFieldsSx}>
      <Container>
        <HeadingContainer>
          <Typography variant="h2" mb="16px">
            {props.heading}
          </Typography>
          <Typography
            display="block"
            variant="body1Medium"
            sx={{ textWrap: "pretty", maxWidth: "700px" }}
          >
            {props.description}
          </Typography>
        </HeadingContainer>
        <Box flex="1" p="16px">
          {props.children}
        </Box>
        <Box display="flex" justifyContent="center" gap="20px" mt="40px">
          {onBack && (
            <Button
              disabled={isLoading}
              onClick={onBack}
              {...(!sectionIdFromURL && {
                startIcon: <ArrowLeftIcon />,
              })}
            >
              {!sectionIdFromURL ? "Back" : "Cancel"}
            </Button>
          )}
          <LoadingButton
            disabled={isLoading || !isMainButtonDisable}
            variant="contained"
            color="primary"
            onClick={onContinueClick}
            loading={isLoading}
            {...(!sectionIdFromURL && {
              endIcon: <ArrowRightIcon />,
            })}
          >
            {!sectionIdFromURL ? "Continue" : "Save"}
          </LoadingButton>
        </Box>
      </Container>
    </Body>
  );
};

const Body = styled("div")`
  display: flex;
  flex-direction: column;
  flex: 1;
  border-radius: 20px;
  margin: 0px 16px 16px 16px;
  background: #f6f5f4;
`;

const Container = styled("div")`
  margin: 0 auto;
  width: 732px;
  max-width: 100%;
  display: flex;
  flex-direction: column;
  padding-top: 120px;
  padding-bottom: 56px;
  flex: 1;
`;

const HeadingContainer = styled(Box)`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 45px;
  text-align: center;
`;
