import { SPECIALIZATION_OPTIONS, SpecializationType } from "./constants";
import { FormRow } from "@/components/UIKit/Form/styled";
import { Select } from "@/components/UIKit/Inputs/Select/Select";
import { PhoneTextField } from "@/components/UIKit/Inputs/TextField/PhoneTextField";
import { TextField } from "@/components/UIKit/Inputs/TextField/TextField";
import { DialogCloseBtn } from "@/components/UIKit/Modal/misc";
import useOpenable from "@/hooks/useOpenable";
import { cleanPhoneNum, formatPhoneNum } from "@/utils/formatting";
import { yupEmail, yupPhoneNumber } from "@/utils/yup";
import { yupResolver } from "@hookform/resolvers/yup";
import {
  Box,
  Button,
  Dialog,
  DialogContent,
  Typography,
  styled,
} from "@mui/material";
import { UseFormProps, useForm } from "react-hook-form";
import * as yup from "yup";

type Props = {
  defaultValues?: Partial<FormInputs>;
  onClose: () => void;
  isLoading: boolean;
  onSubmit: (data: FormInputs) => void;
};

export const ProviderModalView = (props: Props) => {
  const { control, handleSubmit, register } = useProviderForm({
    defaultValues: {
      ...props.defaultValues,
      phone: formatPhoneNum(props.defaultValues?.phone || ""),
    },
  });
  const { isOpen, onClose } = useOpenable({
    shouldStartOpen: true,
    isOnCloseDisabled: props.isLoading,
  });

  const onSubmit = (values: FormInputs) => {
    props.onSubmit({
      ...values,
      phone: cleanPhoneNum(values.phone),
    });
  };

  const isNew = props.defaultValues === undefined;
  return (
    <FormDialog
      open={isOpen}
      onClose={onClose}
      onTransitionExited={props.onClose}
    >
      <form onSubmit={handleSubmit(onSubmit)}>
        <DialogContent>
          <DialogCloseBtn onClick={onClose} disabled={props.isLoading} />
          <Box mb="24px">
            <Typography variant="h3">
              {isNew ? "Add" : "Edit"} a provider
            </Typography>
            <Typography variant="body2Medium" color="text.secondary">
              Provide information about aestheticians who will provide care for
              Joya patients.
            </Typography>
          </Box>
          <FormRow>
            <TextField
              control={control}
              register={register("firstName")}
              label="First Name"
              placeholder="First Name"
            />
            <TextField
              control={control}
              register={register("lastName")}
              label="Last Name"
              placeholder="Last Name"
            />
          </FormRow>
          <FormRow>
            <TextField
              control={control}
              register={register("email")}
              label="Email"
              placeholder="Email"
            />
          </FormRow>
          <FormRow>
            <PhoneTextField
              control={control}
              register={register("phone")}
              label="Phone Number "
            />
          </FormRow>
          <FormRow mb="0px">
            <Select
              control={control}
              register={register("specialization")}
              label="Specialization"
              defaultValue={"DERMATOLOGIST" satisfies SpecializationType}
              options={SPECIALIZATION_OPTIONS}
            />
          </FormRow>
          <ActionButtons>
            <Button variant="text" onClick={onClose} disabled={props.isLoading}>
              Cancel
            </Button>
            <Button
              variant="contained"
              type="submit"
              disabled={props.isLoading}
            >
              {isNew ? "Add" : "Save"}
            </Button>
          </ActionButtons>
        </DialogContent>
      </form>
    </FormDialog>
  );
};

const FormDialog = styled(Dialog)`
  .MuiDialog-paper {
    width: 700px;
    max-width: 100%;
  }
`;

const ActionButtons = styled(Box)`
  margin-top: 40px;
  display: flex;
  gap: 10px;
  > * {
    flex: 1;
  }
`;

// form fields
type FormInputs = {
  firstName: string;
  lastName: string;
  email: string;
  phone: string;
  specialization: SpecializationType;
};
export type { FormInputs as ProviderFormInputs };

const useProviderForm = (props?: UseFormProps<FormInputs>) => {
  let defaultValues = props?.defaultValues;
  const validationSchema: yup.SchemaOf<FormInputs> = yup.object({
    firstName: yup.string().required(),
    lastName: yup.string().required(),
    email: yupEmail().required(),
    phone: yupPhoneNumber().required(),
    specialization: yup
      .mixed<SpecializationType>()
      .oneOf(SPECIALIZATION_OPTIONS.map((opt) => opt.value))
      .required(),
  });

  return useForm<FormInputs>({
    ...props,
    resolver: yupResolver(validationSchema),
    defaultValues: defaultValues,
  });
};
