import { FormCard } from "../../Form/styled";
import { borderedTextFieldsSx } from "@/themes/customization/inputs";
import {
  Box,
  BoxProps,
  Checkbox,
  CheckboxProps,
  Collapse,
  FormControlLabel as MuiFormControlLabel,
  Typography,
  styled,
  useTheme,
} from "@mui/material";
import { PropsWithChildren, useState } from "react";
import {
  Controller,
  UseControllerProps,
  UseFormRegisterReturn,
} from "react-hook-form";

type Variant = "outlined";
type BaseProps = BoxProps & {
  label: string | JSX.Element;
  icon?: string;
  required?: boolean;
  variant?: Variant;
};
type Props = BaseProps & {
  control?: UseControllerProps<any>["control"];
  register?: UseFormRegisterReturn<any>;
  defaultChecked?: boolean;
  checkboxProps?: CheckboxProps;
};
export const ExpandableCheckbox = (props: PropsWithChildren<Props>) => {
  const {
    control,
    register,
    defaultChecked = false,
    checkboxProps,
    ...baseProps
  } = props;
  const [isChecked, setIsChecked] = useState(defaultChecked);

  // react hook form version
  if (control && register) {
    return (
      <Controller
        control={control}
        name={register.name}
        defaultValue={defaultChecked} // fix "MUI: A component is changing the uncontrolled checked state of SwitchBase to be controlled."
        render={({ field: { value, ...rest } }) => (
          <BaseExpandableCheckbox
            {...baseProps}
            checkboxProps={{ ...rest, checked: value, ...checkboxProps }}
          />
        )}
      />
    );
  }

  return (
    <BaseExpandableCheckbox
      {...baseProps}
      checkboxProps={{
        checked: isChecked,
        onChange: (e) => setIsChecked(e.target.checked),
        ...checkboxProps,
      }}
    />
  );
};

type BaseCheckboxProps = PropsWithChildren<
  BaseProps & {
    checkboxProps: CheckboxProps;
  }
>;
export const BaseExpandableCheckbox = (props: BaseCheckboxProps) => {
  const {
    label,
    icon,
    required,
    checkboxProps,
    children,
    variant,
    ...boxProps
  } = props;
  const isString = typeof label === "string";
  const labelElement = isString ? (
    <Typography variant="body1Medium">{label}</Typography>
  ) : (
    label
  );
  const isChildrenExpanded = Boolean(children && checkboxProps.checked);

  const theme = useTheme();

  return (
    <Body
      {...boxProps}
      variant={variant}
      isChecked={checkboxProps.checked}
      isChildrenExpanded={isChildrenExpanded}
    >
      <Box display="flex" alignItems="center" gap="14px">
        <FormControlLabel
          control={<Checkbox {...checkboxProps} />}
          label={
            <Box display="flex" alignItems="center" gap={2}>
              {icon && (
                <img
                  src={icon}
                  height="40px"
                  alt={`${isString && label} icon`}
                />
              )}
              {labelElement}
              {required && (
                <Box
                  ml="auto"
                  px={1}
                  py={0.5}
                  bgcolor="#F6F5F4"
                  borderRadius="6px"
                  justifyContent="center"
                  alignItems="center"
                  display="inline-flex"
                >
                  <Typography
                    variant="body3Regular"
                    color={theme.palette.text.primary}
                  >
                    Required
                  </Typography>
                </Box>
              )}
            </Box>
          }
        />
      </Box>
      <Collapse in={isChildrenExpanded} sx={borderedTextFieldsSx}>
        {children && <Box px="20px">{children}</Box>}
      </Collapse>
    </Body>
  );
};

// For <Body> & <FormControlLabel>
// - when children are expanded, only top part of card (where checkbox is) will be clickable
// - when children are hidden, the whole card is clickable
const Body = styled(FormCard, {
  shouldForwardProp: (prop) =>
    prop !== "isChecked" && prop !== "isChildrenExpanded" && prop !== "variant",
})<{
  isChecked?: boolean;
  isChildrenExpanded?: boolean;
  variant?: Variant;
}>`
  transition-duration: 0.2s;
  padding: 0px;
  border-color: rgba(0, 0, 0, 0);
  box-shadow: 0px 2px 12px 0px rgba(181, 176, 168, 0.08);

  ${(props) =>
    props.variant === "outlined" &&
    `
    border-color: #E9EBED;
    box-shadow: none;
  `}

  :hover {
    border-color: #ffd700;
  }

  ${(props) =>
    props.isChecked &&
    `
    border-color: #FFD700;
  `}

  ${(props) => props.isChildrenExpanded && `padding-bottom: 20px;`}
`;

const FormControlLabel = styled(MuiFormControlLabel, {
  shouldForwardProp: (prop) => prop !== "isChildrenExpanded",
})<{ isChildrenExpanded?: boolean }>`
  width: 100%;
  padding: 20px; // makes the whole card clickable
  margin-left: 0px;
  margin-right: 0px;
  > .MuiFormControlLabel-label {
    flex: 1;
  }
  > .MuiCheckbox-root {
    margin-left: -11px;
  }
  transition-duration: 0.2s;

  ${(props) => props.isChildrenExpanded && `padding-bottom: 12px;`}
`;
