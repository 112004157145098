import { CheckIcon, LockIcon } from "@/components/UIKit/Icons";
import { formatNum } from "@/utils/formatting";
import {
  Box,
  Typography,
  TypographyProps,
  styled,
  useTheme,
} from "@mui/material";
import {
  addDays,
  format,
  isAfter,
  isBefore,
  isWithinInterval,
  parseISO,
  subDays,
} from "date-fns";
import { Fragment, ReactNode } from "react";

type Props = {
  hasClaimedSkinCheck: boolean;
  claimHistory: {
    creditInfo: {
      order: number;
      claimed: boolean;
      availableTill: string;
      availableFrom: string;
      totalCreditAmount: number;
    }[];
    endDate: string;
  };
  pickedDate: Date;
};
export const JoyaWalletCard = (props: Props) => {
  const { claimHistory, hasClaimedSkinCheck, pickedDate } = props;
  const theme = useTheme();

  const creditInfoInOrder = claimHistory.creditInfo.sort((a, b) => {
    return a.order - b.order;
  });

  return (
    <Card>
      <Box display="flex" justifyContent="space-between" mb="40px">
        <Typography variant="h6">JOYA WALLET</Typography>
        <Typography variant="body3" color="text.secondary">
          Expires on {format(parseISO(claimHistory.endDate), "MMMM d y")}
        </Typography>
      </Box>
      <Box display="flex" alignItems="start" mb="20px">
        {creditInfoInOrder.map((credit, index) => {
          const isClaimed = credit.claimed;
          const { isExpired, isAvailable, availableFrom, availableTill } =
            getCreditStatus(credit, { hasClaimedSkinCheck }, pickedDate);
          let displayedValue: JSX.Element | string = formatNum(
            credit.totalCreditAmount,
            { isDollars: true },
          );

          let label: ReactNode = `Starting ${format(availableFrom, "MMMM d")}`;
          if (index === 0 && !hasClaimedSkinCheck) {
            label = "Unlocked with skin cancer check";
          }
          if (isAvailable) {
            label = (
              <>
                Available until
                <br />
                {format(availableTill, "MMMM d")}
              </>
            );
          }
          if (isExpired && !isClaimed) {
            label = `Expired on ${format(availableTill, "MMMM d")}`;
          }
          if (isClaimed) {
            displayedValue = (
              <IconContainer>
                <CheckIcon fontSize="inherit" />
              </IconContainer>
            );
            label = "Claimed";
          }

          const isLabelGrayedOut = !isAvailable && !isExpired && !isClaimed;
          return (
            <Fragment key={index}>
              <Box
                display="flex"
                flex={1}
                minWidth="136px"
                justifyContent="center"
              >
                <Box
                  textAlign="center"
                  {...(!isAvailable && { color: "text.secondary" })}
                >
                  <CouponValueText component="div" mb="8px">
                    {displayedValue}
                  </CouponValueText>
                  <Typography
                    display="block"
                    variant="body3"
                    color="text.primary"
                    {...(isLabelGrayedOut && { color: "text.secondary" })}
                  >
                    {label}
                  </Typography>
                </Box>
              </Box>
              {index !== creditInfoInOrder.length - 1 && (
                <Box
                  borderBottom="1px dashed #82828F"
                  pt="20px"
                  width="20px"
                  mx="4px"
                />
              )}
            </Fragment>
          );
        })}
      </Box>
      <Box display="flex" gap="3px" mb="16px">
        {creditInfoInOrder.map((credit, index) => {
          const isClaimed = credit.claimed;
          const isFirstChild = index === 0;
          const isLastChild = index === creditInfoInOrder.length - 1;

          const { isAvailable } = getCreditStatus(
            credit,
            {
              hasClaimedSkinCheck,
            },
            pickedDate,
          );
          let sectionColor = "rgba(88, 105, 68, 0.08)";
          if (isAvailable) sectionColor = theme.palette.primary.main;
          if (isClaimed) sectionColor = theme.palette.primary.light;
          return (
            <Box
              key={index}
              flex={1}
              height="12px"
              bgcolor={sectionColor}
              sx={{
                ...(isFirstChild && {
                  borderRadius: "32px 0px 0px 32px",
                }),
                ...(isLastChild && {
                  borderRadius: "0px 32px 32px 0px",
                }),
              }}
            />
          );
        })}
      </Box>
      {!hasClaimedSkinCheck && (
        <Typography
          variant="body3"
          color="text.secondary"
          display="flex"
          gap="8px"
        >
          <LockIcon sx={{ fontSize: "20px" }} />
          Patient need to perform skin check to unlock all the benefits.
        </Typography>
      )}
    </Card>
  );
};

const getCreditStatus = (
  credit: {
    availableTill: string;
    availableFrom: string;
    order: number;
  },
  memberData: { hasClaimedSkinCheck: boolean },
  pickedDate: Date,
) => {
  const availableFrom = parseISO(credit.availableFrom);
  const availableTill = parseISO(credit.availableTill);

  // subtract 1 day from availableFrom to make it inclusive
  const availableFromInclusive = subDays(availableFrom, 1);
  // add 1 day to availableTill to make it inclusive
  const availableTillInclusive = addDays(availableTill, 1);

  // // Check if the availableTill date is in the previous quarter
  // const previousQuarterStart = startOfQuarter(subQuarters(pickedDate, 1));
  // const previousQuarterEnd = endOfQuarter(subQuarters(pickedDate, 1));

  const dateWithinInterval = isWithinInterval(pickedDate, {
    start: availableFromInclusive,
    end: availableTillInclusive,
  });
  const isExpired =
    isAfter(pickedDate, availableFromInclusive) && !dateWithinInterval;
  // Check if the pickedDate is within the available interval
  const isAvailable =
    memberData.hasClaimedSkinCheck &&
    (dateWithinInterval ||
      (credit.order === 1 &&
        isBefore(pickedDate, parseISO(credit.availableFrom))));

  return { isExpired, isAvailable, availableFrom, availableTill };
};

const Card = styled(Box)`
  border: 1px solid #e9ebed;
  padding: 20px;
  border-radius: 12px;
`;

const CouponValueText = styled(Typography)<{
  component?: TypographyProps["component"];
}>`
  font-family: Alfabet;
  font-size: 25px;
  font-weight: 300;
  line-height: 32px;
  letter-spacing: -1px;
`;

const IconContainer = styled(Box)`
  font-size: 32px;
  display: flex;
  justify-content: center;
`;
