import {
  hasLowerCase,
  hasNumber,
  hasSpecialCharacter,
  hasUpperCase,
} from "@/utils/validation";

export const PASSWORD_GUIDELINES = [
  {
    message: "6 character minimum",
    test: (val?: string) => val && val.length >= 6,
  },
  {
    message: "one lowercase character",
    test: (val?: string) => hasLowerCase(val),
  },
  {
    message: "one uppercase character",
    test: (val?: string) => hasUpperCase(val),
  },
  { message: "one number", test: (val?: string) => hasNumber(val) },
  {
    message: "one special character",
    test: (val?: string) => hasSpecialCharacter(val),
  },
];
