import { SearchSection } from "./components/SearchSection";
import { SelectedSection } from "./components/SelectedSection";
import { Insurance } from "./types";
import { DialogCloseBtn } from "@/components/UIKit/Modal/misc";
import { LoadingButton } from "@mui/lab";
import {
  Box,
  Button,
  DialogActions,
  DialogTitle,
  Drawer,
  DialogContent as MuiDialogContent,
  Typography,
  styled,
} from "@mui/material";
import { useEffect, useState } from "react";

export type onSubmitData = {
  insurances: Insurance[];
};
type InsuranceSet = { [key: string]: Insurance };
type Props = {
  pickedInsurances?: Insurance[];
  onSubmit: (data: onSubmitData) => Promise<void>;
  searchValue: string;
  onSearchChange: (searchVal: string) => void;
  searchResults: Insurance[];
  isSearching?: boolean;
  onClose: () => void;
  isLoadingPickedInsurances?: boolean;
  onLoadMore: () => void;
  totalResults?: number;
  isLoadingMoreResults: boolean;
};

export const AddInsuranceModalView = (props: Props) => {
  const [isLoading, setIsLoading] = useState(false);
  const [pickedInsurances, setPickedInsurances] = useState<InsuranceSet>({});
  const [isOpen, setIsOpen] = useState(false);
  const pickedInsurancesArr = Object.values(pickedInsurances).filter((x) => x);

  // when props.pickedInsurances is updated, copy it into the internal pickedInsurances state (as an object)
  useEffect(() => {
    const newPickedInsurances: InsuranceSet = {};
    props.pickedInsurances?.forEach((x) => {
      newPickedInsurances[x.id] = x;
    });
    setPickedInsurances(newPickedInsurances);
  }, [props.pickedInsurances]);

  const onSubmit = async () => {
    setIsLoading(true);
    await props.onSubmit({ insurances: pickedInsurancesArr });
    setIsLoading(false);
    onClose();
  };

  const onClose = () => {
    // dont allow close while loading
    if (isLoading) return;
    setIsOpen(false); // will initiate close transition
  };

  // when an insurance gets updated, update the whole set
  const onInsuranceChange = (insurance: Insurance) => {
    setPickedInsurances((curr) => {
      const newInsurances = { ...curr };

      // if no plans are selected, delete from picked insurances
      if (insurance.plans.length === 0) {
        delete newInsurances[insurance.id];
        return newInsurances;
      }

      // update insurance value (ie: its plans)
      newInsurances[insurance.id] = insurance;
      return newInsurances;
    });
  };

  // wait for the component to mount before showing the drawer (so that animation can show when opened)
  // - technically we can just do <Modal open={isOpen} /> instead of {isOpen && <Modal open />} but that requires more clean up (ie: ensure API only runs when opened, reset state on close)
  useEffect(() => {
    setIsOpen(true);
  }, []);

  return (
    <Drawer
      open={isOpen}
      onClose={onClose}
      anchor="bottom"
      onTransitionExited={() => {
        props.onClose(); // wait for modal exit transition before unrendering (props.onClose)
      }}
    >
      <DialogTitle component="div" sx={{ borderBottom: "0px", pt: "32px" }}>
        <Box display="flex" alignItems="start" justifyContent="space-between">
          <Typography variant="h3">Add insurance</Typography>
          <DialogCloseBtn
            sx={{ position: "static" }}
            onClick={onClose}
            disabled={isLoading}
          />
        </Box>
      </DialogTitle>
      <DialogContent sx={{ height: "100vh" }}>
        <Main>
          <SearchSection
            searchValue={props.searchValue}
            onSearchChange={props.onSearchChange}
            searchResults={props.searchResults}
            value={pickedInsurancesArr}
            onInsuranceChange={onInsuranceChange}
            isLoading={props.isSearching}
            onLoadMore={props.onLoadMore}
            totalResults={props.totalResults}
            isLoadingMoreResults={props.isLoadingMoreResults}
          />
          <SelectedSection
            insurances={pickedInsurancesArr}
            onInsuranceChange={onInsuranceChange}
            isLoading={props.isLoadingPickedInsurances}
          />
        </Main>
      </DialogContent>
      <DialogActions sx={{ py: "16px" }}>
        <Box display="flex" alignItems="center" justifyContent="end" gap="20px">
          <Button variant="text" onClick={onClose} disabled={isLoading}>
            Cancel
          </Button>
          <LoadingButton
            onClick={() => onSubmit()}
            loading={isLoading}
            variant="contained"
            sx={{ width: "160px" }}
          >
            Save
          </LoadingButton>
        </Box>
      </DialogActions>
    </Drawer>
  );
};
AddInsuranceModalView.defaultProps = {
  onSubmit: async () => {},
  onContinue: () => {},
};

const DialogContent = styled(MuiDialogContent)`
  width: 1504px;
  max-width: 100%;
  display: flex;
  flex-direction: column;
`;

const Main = styled(Box)`
  display: grid;
  grid-template-columns: 1fr 2fr;
  gap: 13px;
  height: 100%;
  ${(props) => props.theme.breakpoints.down("tablet")} {
    grid-template-columns: 1fr 1fr;
  }
`;
