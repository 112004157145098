import { Components, Theme } from "@mui/material";

const surfaces = (theme: Theme): Components<Theme> => ({
  MuiCard: {
    styleOverrides: {
      root: {
        boxShadow: "0px 2px 16px 0px rgba(181, 176, 168, 0.1)",
        borderRadius: "12px",
      },
    },
  },
  MuiPaper: {
    styleOverrides: {
      root: {
        // from MuiSelect (inputs.tsx)
        "&.custom-MuiSelect-paper": {
          boxShadow: "0px 2px 16px 0px rgba(181, 176, 168, 0.1)",
          border: "1px solid #E9EBED",
          borderRadius: "8px",

          "@media (min-height: 420px)": {
            maxHeight: "402px",
          },
        },
      },
      outlined: {
        border: "1px solid #e9ebed",
      },
    },
  },
});

export { surfaces };
