import { DialogCloseBtn } from "@/components/UIKit/Modal/misc";
import useOpenable from "@/hooks/useOpenable";
import WarningRoundedIcon from "@mui/icons-material/WarningRounded";
import { LoadingButton } from "@mui/lab";
import {
  Button,
  Dialog,
  DialogContent,
  Stack,
  Typography,
  styled,
} from "@mui/material";
import { useState } from "react";

type Props = {
  title?: string;
  text?: string;
  onClose: () => void;
  onContinue: () => Promise<void>;
  showContinueButton?: boolean;
  showCloseButton?: boolean;
  closeButtonText?: string;
  continueButtonText?: string;
};

export const InfoModal = ({
  title = "The combined total for facial/peel + sunscreen exceeds $40.",
  text = "Please note that the cost refers to the cost of goods to your practice, not the patient. You can still continue with this value, but we will only reimburse you up to $40.",
  onClose,
  onContinue,
  showContinueButton = true,
  showCloseButton = true,
  closeButtonText = "Cancel",
  continueButtonText = "Continue",
}: Props) => {
  const [isLoading, setIsLoading] = useState(false);

  const { isOpen, onClose: handleDialogClose } = useOpenable({
    shouldStartOpen: true,
    isOnCloseDisabled: isLoading,
  });

  const onContinueClick = async () => {
    setIsLoading(true);
    try {
      // TODO: Need more investigation why infinity loading happens for some customers.
      //  Right now adding a workaround to prevent infinity loading and ensure that
      //  finally block will be executed in any case either if main function finished
      //  successfully or if timeout is reached
      const timeout = 10000; // 10 seconds
      await Promise.race([
        onContinue(),
        new Promise(
          (_, reject) =>
            setTimeout(() => reject(new Error("Operation timed out")), timeout) // Timeout promise
        ),
      ]);
    } catch (e) {
      console.error(e);
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <FormDialog
      open={isOpen}
      onClose={handleDialogClose}
      onTransitionExited={onClose}
    >
      <DialogContent sx={{ padding: "66px 40px 30px" }}>
        <DialogCloseBtn onClick={handleDialogClose} disabled={isLoading} />
        <Stack
          flexDirection="column"
          mb={10}
          justifyContent="center"
          alignItems="center"
        >
          <WarningRoundedIcon sx={{ fontSize: 80, color: "#C1281D", mb: 4 }} />
          <Typography variant="h3" mb={1} color="text.secondary">
            {title}
          </Typography>
          <Typography
            variant="body3"
            color="text.secondary"
            px="52px"
            lineHeight="20px"
          >
            {text}
          </Typography>
        </Stack>
        <Stack flexDirection="row">
          {showCloseButton && (
            <Button fullWidth onClick={handleDialogClose} disabled={isLoading}>
              {closeButtonText}
            </Button>
          )}
          {showContinueButton && (
            <LoadingButton
              fullWidth
              variant="contained"
              color="secondary"
              onClick={onContinueClick}
              loading={isLoading}
            >
              {continueButtonText}
            </LoadingButton>
          )}
        </Stack>
      </DialogContent>
    </FormDialog>
  );
};

const FormDialog = styled(Dialog)`
  .MuiDialog-paper {
    width: 650px;
    max-width: 100%;
    text-align: center;
    word-wrap: break-word;
  }
`;
