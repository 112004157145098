import { StepContainer } from "../../StepContainer";
import { BaseExpandableCheckbox } from "@/components/UIKit/Inputs/Checkbox/ExpandableCheckbox";
import { SurveySectionFields } from "@/scenes/Onboarding/types";
import { Offering } from "@/types/apiContract/practice";
import { useState } from "react";

type Props = {
  offeringOptions: Offering[];
  defaultValues?: Partial<FormInputs>;
  onBack: () => void;
  onContinue: (data: FormInputs) => Promise<void>;
};

const TITLE = "Fillers offered";
const SUBTITLE =
  "Please check all the boxes below for fillers that your practice offers.";

// FIELDS
// IMPORTANT: treat this as a contract, this determines which values gets passed up to the backend
const FIELDS: SurveySectionFields<FormInputs> = {
  offerings: {
    label: TITLE,
    subtitle: SUBTITLE,
    type: "json",
    example: [
      {
        id: "id123",
        name: "name123",
        type: "JOYA",
      },
      {
        id: "id345",
        name: "name345",
        type: "JOYA",
      },
    ],
  },
};
export { FIELDS as FillersOffersFields };

export const FillersOffers = (props: Props) => {
  const offeringIds = new Set(props.defaultValues?.offerings?.map((x) => x.id));
  const [offerings, setOfferings] = useState(new Set<string>(offeringIds));

  const onSubmit = async () => {
    const offeringsArr = props.offeringOptions.filter((of) => {
      return offerings.has(of.id);
    });

    await props.onContinue({ offerings: offeringsArr });
  };

  return (
    <StepContainer
      heading={TITLE}
      description={SUBTITLE}
      onBack={props.onBack}
      onContinue={onSubmit}
    >
      {props.offeringOptions.map((item) => {
        const isSelected = offerings.has(item.id);
        const onCheckboxChange = () => {
          setOfferings((prev) => {
            const newOfferings = new Set(prev);
            if (!isSelected) {
              newOfferings.add(item.id);
            } else {
              newOfferings.delete(item.id);
            }
            return newOfferings;
          });
        };
        return (
          <BaseExpandableCheckbox
            key={item.id}
            label={item.name}
            checkboxProps={{
              checked: isSelected,
              onChange: onCheckboxChange,
            }}
          />
        );
      })}
    </StepContainer>
  );
};
FillersOffers.defaultProps = {
  onBack: () => {},
  onContinue: async () => {},
};

// IMPORTANT: treat this as a contract, this is how values are read from the backend (see defaultValues prop)
type FormInputs = { offerings: Offering[] };
