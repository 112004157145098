import { SvgIcon, SvgIconProps } from "@mui/material";

function DownloadIcon(props: SvgIconProps) {
  return (
    <SvgIcon
      viewBox="0 0 25 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M12.4521 16.9745C12.5522 17.0776 12.6897 17.1357 12.8333 17.1357C12.977 17.1357 13.1145 17.0776 13.2145 16.9745L17.6416 12.4135C17.846 12.203 17.8409 11.8667 17.6304 11.6623C17.4199 11.458 17.0835 11.463 16.8792 11.6735L13.3646 15.2944V4.91699C13.3646 4.62359 13.1267 4.38574 12.8333 4.38574C12.5399 4.38574 12.3021 4.62359 12.3021 4.91699V15.2944L8.78743 11.6735C8.58308 11.463 8.24675 11.458 8.03622 11.6623C7.82568 11.8667 7.82067 12.203 8.02503 12.4135L12.4521 16.9745Z"
        fill="currentColor"
      />
      <path
        d="M4.86456 18.5524C4.57116 18.5524 4.33331 18.7903 4.33331 19.0837C4.33331 19.3771 4.57116 19.6149 4.86456 19.6149H20.8021C21.0955 19.6149 21.3333 19.3771 21.3333 19.0837C21.3333 18.7903 21.0955 18.5524 20.8021 18.5524H4.86456Z"
        fill="currentColor"
      />
    </SvgIcon>
  );
}

export { DownloadIcon };
