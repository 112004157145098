import { ChangePasswordModalView } from "./View";
import {
  ChangePasswordFormInputs,
  ChangePasswordMutationInputs,
} from "./types";
import { updatePassword } from "@/components/GlobalContext/utils/auth";
import { snackIt } from "@/components/SnackbarManager";
import { handleApiError } from "@/utils/feedback";
import { useMutation } from "@tanstack/react-query";

//   ChangePassword
type Props = {
  onClose: () => void;
};

const ChangePasswordModalContainer = (props: Props) => {
  const changePasswordMutation = useMutation({
    onError: handleApiError,
    mutationFn: (values: ChangePasswordFormInputs) => {
      const reqObject = formatChangePassword(values);

      return updatePassword(reqObject);
    },
  });

  const onSubmit = async (values: ChangePasswordFormInputs) => {
    try {
      // call updatePassword endpoint
      const { authErrorMsg } = await changePasswordMutation.mutateAsync(values);

      let updatePasswordFeedback = "Password updated.";
      if (authErrorMsg) {
        updatePasswordFeedback = authErrorMsg;
      }

      snackIt.default({
        severity: authErrorMsg ? "error" : "success",
        message: updatePasswordFeedback,
      });

      authErrorMsg ?? props.onClose();
    } catch (e: any) {
      throw Error(e);
    }
  };

  return (
    <ChangePasswordModalView onClose={props.onClose} onSubmit={onSubmit} />
  );
};

export { ChangePasswordModalContainer };

const formatChangePassword = (
  values: ChangePasswordFormInputs
): ChangePasswordMutationInputs => {
  return {
    oldPassword: values.oldPassword,
    newPassword: values.newPassword,
  };
};
