import searchIllustrationSrc from "@/assets/dashboard/search-illustration.svg";
import { GrayButton } from "@/components/UIKit/Button/styled";
import { DownloadIcon } from "@/components/UIKit/Icons";
import useBreakpointCheck from "@/hooks/useBreakpointCheck";
import { useDownloadManager } from "@/hooks/useDownloadManager";
import { SearchedMember } from "@/types/apiContract/member";
import { LoadingButton } from "@mui/lab";
import {
  Box,
  TableRow as MuiTableRow,
  Skeleton,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  Typography,
  styled,
} from "@mui/material";
import { format, parseISO } from "date-fns";
import { MouseEvent } from "react";
import InfiniteScroll from "react-infinite-scroller";
import { useNavigate } from "react-router-dom";

type Props = {
  members: SearchedMember[];
  isLoading: boolean;
  onLoadMore: () => void;
  hasMoreResults: boolean;
};
export const MembersTable = (props: Props) => {
  if (props.isLoading) {
    return <LoadingSkeleton />;
  }

  if (props.members.length === 0) {
    return <EmptySearchBlurb />;
  }
  return (
    <InfiniteScroll
      loadMore={props.onLoadMore}
      hasMore={props.hasMoreResults}
      loader={
        <div key={0}>
          <RowsLoadingSkeleton />
        </div>
      }
    >
      <Box overflow="scroll">
        <TableContainer
          sx={{
            minWidth: "1000px",
          }}
        >
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>Name</TableCell>
                <TableCell>Sex at Birth & Date of Birth</TableCell>
                <TableCell>Joya ID</TableCell>
                <TableCell align="center">Status</TableCell>
                <TableCell align="center">Skin Profile</TableCell>
                <TableCell align="center">Action</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {props.members.map((m) => {
                return <MemberTableRow key={m.id} member={m} />;
              })}
            </TableBody>
          </Table>
        </TableContainer>
      </Box>
    </InfiniteScroll>
  );
};

const MemberTableRow = (props: { member: SearchedMember }) => {
  const m = props.member;
  const navigate = useNavigate();
  const { download, loading } = useDownloadManager();

  const onViewClick = (e: MouseEvent<HTMLElement>) => {
    e.stopPropagation();

    navigate(`/checkout/${m.id}`);
  };

  const onDownloadClick = async (e: MouseEvent<HTMLElement>) => {
    e.stopPropagation();

    await download({
      fileName: `survey-${m.joyaId}`,
      url: m.survey,
    });
  };

  const fullName = `${m.firstName} ${m.middleName} ${m.lastName}`;
  const isInactive = m.status === "INACTIVE";

  return (
    <TableRow
      key={m.id}
      sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
      onClick={onViewClick}
      isDisabled={isInactive}
    >
      <TableCell component="th" scope="row">
        <Typography variant="body2Emphasized">{fullName}</Typography>
      </TableCell>
      <TableCell>
        <Box>
          <Typography
            sx={{
              textTransform: "capitalize",
              mb: "1px",
              display: "block",
            }}
            variant="body2Emphasized"
          >
            {m.gender.toLowerCase()}
          </Typography>
          <Typography
            display="block"
            variant="body3Emphasized"
            color={isInactive ? "inherit" : "text.secondary"}
          >
            {format(parseISO(m.dateOfBirth), "M.d.y")}
          </Typography>
        </Box>
      </TableCell>
      <TableCell>
        <Typography variant="body2Emphasized">{m.joyaId ?? "-"}</Typography>
      </TableCell>
      <TableCell align="center">
        <StatusTag type={m.status}>
          <Typography variant="body3Emphasized">
            {m.status === "SUSPENDED" ? "active" : m.status.toLowerCase()}
          </Typography>
        </StatusTag>
      </TableCell>
      <FullSpaceTableCell align="center">
        <DownloadButton
          variant="text"
          startIcon={<DownloadIcon />}
          onClick={onDownloadClick}
          loading={loading}
          disabled={isInactive}
        >
          Download
        </DownloadButton>
      </FullSpaceTableCell>
      <TableCell align="center">
        <GrayButton
          variant="contained"
          size="medium"
          onClick={onViewClick}
          disabled={isInactive}
        >
          View
        </GrayButton>
      </TableCell>
    </TableRow>
  );
};

const TableRow = styled(MuiTableRow, {
  shouldForwardProp: (prop) => prop !== "isDisabled",
})<{ isDisabled?: boolean }>`
  ${(props) =>
    props.isDisabled &&
    `
  pointer-events: none;
  color: #C0BDB7;
 `}
  .MuiTableCell-body {
    cursor: pointer;
    color: inherit;
  }
`;

const DownloadButton = styled(LoadingButton)`
  opacity: 0.8;
  padding-left: 0px;
  padding-right: 0px;

  :hover {
    opacity: 1;
    background: none;
  }
`;

const StatusTag = styled(Box, {
  shouldForwardProp: (prop) => prop !== "type",
})<{ type: SearchedMember["status"] }>`
  min-width: 65px;
  display: inline-flex;
  justify-content: center;
  border-radius: 8px;
  padding: 4px;
  text-transform: capitalize;

  ${(props) =>
    props.type === "INACTIVE" &&
    `
    background: ${props.theme.palette.grey[200]};
    color: ${props.theme.palette.text.secondary};
  `}

  ${(props) =>
    (props.type === "ACTIVE" || props.type === "SUSPENDED") &&
    `
    background: rgba(63, 201, 110, 0.1);
    color: ${props.theme.palette.text.primary};
  `}
`;

// a table cell where its inner child takes up all the space, useful for buttons for easier clicking
const FullSpaceTableCell = styled(TableCell)`
  position: relative;

  && {
    > * {
      position: absolute;
      top: 0px;
      left: 0px;
      right: 0px;
      bottom: 0px;
    }
  }
`;

const LoadingSkeleton = () => {
  return (
    <Box pb="20px">
      <Skeleton
        variant="rectangular"
        height={39}
        sx={{ my: "4px", borderRadius: "8px" }}
        width="100%"
      />
      {Array(1)
        .fill(null)
        .map((_, i) => (
          <Skeleton
            key={i}
            variant="rectangular"
            height={300}
            sx={{ my: "8px", borderRadius: "8px" }}
            width="100%"
          />
        ))}
    </Box>
  );
};

const RowsLoadingSkeleton = () => {
  return (
    <Box pb="20px">
      {Array(3)
        .fill(null)
        .map((_, i) => (
          <Skeleton
            key={i}
            variant="rectangular"
            height={52}
            sx={{ my: "8px", borderRadius: "8px" }}
            width="100%"
          />
        ))}
    </Box>
  );
};

const EmptySearchBlurb = () => {
  const isTablet = useBreakpointCheck("tablet");
  return (
    <Box
      marginY={isTablet ? "94px" : 0}
      display="grid"
      sx={{ placeItems: "center" }}
      height="100%"
      minHeight="inherit"
    >
      <Box display="grid" sx={{ placeItems: "center" }}>
        <Box mb="24px">
          <img src={searchIllustrationSrc} alt="search illustration" />
        </Box>
        <Box>
          <EmptySearchText textAlign="center" maxWidth="290px">
            You haven't got any members yet, Don't worry – we're just getting
            started!
          </EmptySearchText>
        </Box>
      </Box>
    </Box>
  );
};

const EmptySearchText = styled(Typography)`
  font-size: 16px;
  font-weight: 300;
  line-height: 21px;
  letter-spacing: 0px;
`;
