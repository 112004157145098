import { VisitReason } from "@/types/apiContract/visit";
import { yupResolver } from "@hookform/resolvers/yup";
import { UseFormProps, useForm } from "react-hook-form";
import * as yup from "yup";

type FormInputs = {
  selectedReasons: {
    type: VisitReason["type"];
    isChecked: boolean;
    isDetailRequired?: boolean;
    detail?: string;
  }[];
  canApplyAestheticTotal?: boolean;
  aestheticsServicesTotal?: string;
  skinCareProductTotal?: string;
  isUsingAvailableCredit?: boolean;
  isSkippingCredit?: boolean;
  skipCreditReason?: string;
  canApplySkinTotal?: boolean;
  isDiscountApplied?: boolean;
  isSkippingDiscount?: boolean;
  skipDiscountReason?: string;
  discountPercent?: string;
  selectedProviders: string[];
  visitDate: Date;
};
export type { FormInputs as VisitFormInputs };

export const useVisitForm = (props?: UseFormProps<FormInputs>) => {
  const today = new Date();
  let defaultValues = props?.defaultValues;
  const validationSchema: yup.SchemaOf<FormInputs> = yup.object({
    selectedReasons: yup.array().of(
      yup.object({
        type: yup.mixed<VisitReason["type"]>().required(),
        isChecked: yup.boolean().required(),
        isDetailRequired: yup.boolean(),
        detail: yup.string().when(["isDetailRequired", "isChecked"], {
          is: (isDetailRequired: boolean, isChecked: boolean) => {
            return isDetailRequired && isChecked;
          },
          then: yup.string().required().typeError("Required"),
        }),
      })
    ),
    canApplyAestheticTotal: yup.boolean(),
    aestheticsServicesTotal: yup.string().when("canApplyAestheticTotal", {
      is: true,
      then: yup
        .string()
        .required()
        .test({
          message: "Cost must be greater than 0",
          test: (value) => {
            return Number(value) > 0;
          },
        }),
    }),
    isUsingAvailableCredit: yup.boolean(),
    isSkippingCredit: yup.boolean(),
    skipCreditReason: yup.string().when("isSkippingCredit", {
      is: true,
      then: yup.string().required(),
    }),
    canApplySkinTotal: yup.boolean(),
    skinCareProductTotal: yup.string().when("selectedReasons", {
      is: (val: FormInputs["selectedReasons"]) =>
        val.find((v) => v.type === "SKINCARE_PRODUCTS")?.isChecked,
      then: yup
        .string()
        .required()
        .test({
          message: "Cost must be greater than 0",
          test: (value) => {
            return Number(value) > 0;
          },
        }),
    }),
    isDiscountApplied: yup.boolean(),
    isSkippingDiscount: yup.boolean(),
    skipDiscountReason: yup.string().when("isSkippingDiscount", {
      is: true,
      then: yup.string().required(),
    }),
    discountPercent: yup.string().when("isDiscountApplied", {
      is: true,
      then: yup
        .string()
        .required()
        .test({
          message: "Discount must be greater than 0 or less than 100",
          test: (value) => {
            const numberVal = Number(value);
            return numberVal > 0 && numberVal <= 100;
          },
        }),
    }),
    selectedProviders: yup.array().of(yup.string().required()),
    visitDate: yup
      .date()
      .required("Visit date is required")
      .max(today, "The visit date cannot be in the future"),
  });

  return useForm<FormInputs>({
    ...props,
    resolver: yupResolver(validationSchema),
    defaultValues,
  });
};
