import { TextField } from "./TextField";
import { InputAdornment } from "@mui/material";
import { ComponentProps } from "react";

type Props = ComponentProps<typeof TextField> & {};

// has an https adornment and strips out http / https from the input
export const URLTextField = (props: Props) => {
  const { ...fieldProps } = props;

  return (
    <TextField
      {...fieldProps}
      onChangeFilter={(val) => {
        if (val.startsWith("https://")) {
          return val.replace(/^https:\/\//, "");
        }
        if (val.startsWith("http://")) {
          return val.replace(/^http:\/\//, "");
        }
        return val;
      }}
      InputProps={{
        startAdornment: <InputAdornment position="start">https</InputAdornment>,
        ...fieldProps.InputProps,
      }}
    />
  );
};
