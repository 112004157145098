import { VisitFormInputs } from "../hooks/useVisitForm";
import { FormRow } from "@/components/UIKit/Form/styled";
import { InfoIcon } from "@/components/UIKit/Icons";
import { Checkbox } from "@/components/UIKit/Inputs/Checkbox/Checkbox";
import { NumberTextField } from "@/components/UIKit/Inputs/TextField/NumberTextField";
import { TextField } from "@/components/UIKit/Inputs/TextField/TextField";
import { VisitCategory } from "@/scenes/Checkout/types";
import { VisitReason } from "@/types/apiContract/visit";
import { formatNum } from "@/utils/formatting";
import {
  Box,
  InputAdornment,
  Tooltip,
  Typography,
  styled,
} from "@mui/material";
import { useCallback, useEffect } from "react";
import { useFormContext } from "react-hook-form";

type Props = {
  availableCredit: number;
  visitCategories: VisitCategory[];
  hasDoneSkinCancerScreening: boolean;
};
export const FooterFields = (props: Props) => {
  const { availableCredit, visitCategories } = props;

  const { register, control, watch, setValue, resetField } =
    useFormContext<VisitFormInputs>();
  const selectedReasonsArr = watch("selectedReasons");
  const selectedReasons = new Set(selectedReasonsArr.map((v) => v.type));
  const isDiscountApplied = Boolean(watch("isDiscountApplied"));
  const isUsingAvailableCredit = Boolean(watch("isUsingAvailableCredit"));
  const aestheticsServicesTotalBeforeJoyaCredits = watch(
    "aestheticsServicesTotal"
  );
  const skinCareProductTotalBeforeDiscount = watch("skinCareProductTotal");
  const discountPercent = watch("discountPercent");

  // --- TOTALS LOGIC ---
  const { postDiscountsTotal, todaysSavings } = calculateTotals({
    availableCredit,
    isDiscountApplied,
    isUsingAvailableCredit,
    aestheticsServicesTotalBeforeJoyaCredits,
    skinCareProductTotalBeforeDiscount,
    discountPercent,
  });

  // --- AESTHETICS TOTAL CARD LOGIC ---
  let canApplyAestheticTotal = false;

  // find specific conditions in the selected reasons
  const visitsByCategory = getVisitsByCategory({ visitCategories });
  selectedReasons.forEach((selectedReason) => {
    // if there is atleast one "aesthetic service" checked (ie: non-medical and non-JOYA Benefit), they can apply the total
    if (
      !visitsByCategory["MEDICAL"].reasonTypes.includes(selectedReason) &&
      !visitsByCategory["JOYA_BENEFITS"].reasonTypes.includes(selectedReason)
    ) {
      canApplyAestheticTotal = true;
    }
  });

  const hasUsedOrWillUseSkinCancerScreening =
    props.hasDoneSkinCancerScreening ||
    selectedReasons.has("SKIN_CANCER_SCREENING");
  const isShowingCreditCheckbox =
    canApplyAestheticTotal &&
    hasUsedOrWillUseSkinCancerScreening &&
    props.availableCredit > 0;

  const isEnoughTotalForCreditUse =
    Number(aestheticsServicesTotalBeforeJoyaCredits) >= props.availableCredit;

  const canUseCredit = isShowingCreditCheckbox && isEnoughTotalForCreditUse;
  const isSkippingCredit = canUseCredit && !isUsingAvailableCredit;

  const clearPriceFields = useCallback(() => {
    resetField("aestheticsServicesTotal");
    resetField("skinCareProductTotal");
    resetField("discountPercent");
  }, [resetField]);

  // if not enough total to use all credit, set it to false.
  // if credit checkbox opens, set it to true. set back to false if it closes
  useEffect(() => {
    setValue("isUsingAvailableCredit", canUseCredit);
  }, [canUseCredit, setValue]);

  // storing into react hook form for dynamic validation
  useEffect(() => {
    setValue("canApplyAestheticTotal", canApplyAestheticTotal);
    if (!canApplyAestheticTotal) {
      clearPriceFields();
    }
  }, [canApplyAestheticTotal, clearPriceFields, setValue]);

  // storing into react hook form for dynamic validation
  useEffect(() => {
    setValue("isSkippingCredit", isSkippingCredit);
  }, [isSkippingCredit, setValue]);

  // --- SKIN CARE TOTAL CARD LOGIC ---
  const canApplySkinTotal = selectedReasons.has("SKINCARE_PRODUCTS");

  // discount applied should be default checked if available. set it back to false if it not available
  useEffect(() => {
    setValue("canApplySkinTotal", canApplySkinTotal);
    if (!canApplySkinTotal) {
      clearPriceFields();
    }
  }, [canApplySkinTotal, clearPriceFields, setValue]);

  // discount applied should be default checked if available. set it back to false if it not available
  useEffect(() => {
    setValue("isDiscountApplied", canApplySkinTotal);
  }, [canApplySkinTotal, setValue]);

  // storing into react hook form for dynamic validation
  const isSkippingDiscount = canApplySkinTotal && !isDiscountApplied;
  useEffect(() => {
    setValue("isSkippingDiscount", isSkippingDiscount);
    if (!isSkippingDiscount) {
      setValue("skipDiscountReason", undefined);
    }
    if (isSkippingDiscount) {
      setValue("discountPercent", undefined);
    }
  }, [isSkippingDiscount, setValue]);

  return (
    <Box>
      <Box mb="24px">
        {props.availableCredit > 0 && !isShowingCreditCheckbox && (
          <Card>
            <Box display="flex" justifyContent="space-between" mb="4px">
              <Typography variant="body2Emphasized" color="text.secondary">
                Available Credit
              </Typography>
              <Typography variant="body2Emphasized" color="text.secondary">
                {formatNum(props.availableCredit, { isDollars: true })}
              </Typography>
            </Box>
            <Typography variant="body3">
              Select eligible reason for the visit that qualifies for the
              credit.
            </Typography>
          </Card>
        )}
        <Show when={canApplyAestheticTotal}>
          <Card>
            <Box display="flex" justifyContent="space-between" mb="16px">
              <Typography variant="body2Emphasized">
                Aesthetics services total
              </Typography>
              <Tooltip
                title="Total amount paid out of pocket by the patient before JOYA credits. Practice promotions or discounts should be included in this total."
                placement="top"
              >
                <div>
                  <InfoIcon sx={{ color: "#78756E", fontSize: "18px" }} />
                </div>
              </Tooltip>
            </Box>

            <FormRow mb="0px">
              <NumberTextField
                control={control}
                register={register("aestheticsServicesTotal")}
                numberFieldProps={{ maxDecimals: 2 }}
                sx={{ input: { borderRight: "none" } }}
                fullWidth
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">$</InputAdornment>
                  ),
                  endAdornment: isShowingCreditCheckbox ? (
                    <InputAdornment position="end">
                      <Typography variant="body2Medium">
                        Before JOYA credits
                      </Typography>
                    </InputAdornment>
                  ) : undefined,
                }}
              />
            </FormRow>
            <Show
              mt="20px"
              color={
                isEnoughTotalForCreditUse ? "text.primary" : "text.secondary"
              }
              when={isShowingCreditCheckbox}
            >
              <Box display="flex" justifyContent="space-between">
                <Checkbox
                  control={control}
                  register={register("isUsingAvailableCredit")}
                  label="Available Credit"
                  disabled={!isEnoughTotalForCreditUse}
                />
                <Typography variant="body2Emphasized">
                  {formatNum(availableCredit, { isDollars: true })}
                </Typography>
              </Box>
              <Show when={isSkippingCredit}>
                <Box mt="16px">
                  <TextField
                    control={control}
                    register={register("skipCreditReason")}
                    fullWidth
                    placeholder="Reason not to use credit"
                  />
                </Box>
              </Show>
              <Show when={!isEnoughTotalForCreditUse}>
                <Box ml="32px" mt="8px">
                  <Typography variant="body3">
                    Amount must be equal to or higher than the available credit.
                  </Typography>
                </Box>
              </Show>
            </Show>
          </Card>
        </Show>
        <Show when={canApplySkinTotal}>
          <Card>
            <Box display="flex" justifyContent="space-between" mb="16px">
              <Typography variant="body2Emphasized">
                Skin care product total
              </Typography>
              <Tooltip
                title="You chose a discount % on skincare products for JOYA patients. Please enter the total amount of products purchased before the discount. Then enter the agreed upon discount below."
                placement="top"
              >
                <div>
                  <InfoIcon sx={{ color: "#78756E", fontSize: "18px" }} />
                </div>
              </Tooltip>
            </Box>
            <FormRow>
              <NumberTextField
                control={control}
                register={register("skinCareProductTotal")}
                numberFieldProps={{ maxDecimals: 2 }}
                fullWidth
                sx={{ input: { borderRight: "none" } }}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">$</InputAdornment>
                  ),
                  endAdornment: (
                    <InputAdornment position="end">
                      <Typography variant="body2Medium">
                        Before JOYA discount
                      </Typography>
                    </InputAdornment>
                  ),
                }}
              />
            </FormRow>
            <Box mb="16px">
              <Checkbox
                control={control}
                register={register("isDiscountApplied")}
                label="Discount applied"
              />
            </Box>
            <Show when={isDiscountApplied}>
              <NumberTextField
                control={control}
                register={register("discountPercent")}
                numberFieldProps={{ maxDecimals: 0 }}
                fullWidth
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">%</InputAdornment>
                  ),
                }}
              />
            </Show>
            <Show when={!isDiscountApplied}>
              <TextField
                control={control}
                register={register("skipDiscountReason")}
                fullWidth
                placeholder="Reason not to apply"
              />
            </Show>
          </Card>
        </Show>
      </Box>
      {(canApplyAestheticTotal || canApplySkinTotal) && (
        <Box mb="32px">
          <Box display="flex" justifyContent="space-between" mb="12px">
            <Typography variant="body2">Today's savings</Typography>
            <Typography variant="body2">
              {formatNum(todaysSavings, { isDollars: true })}
            </Typography>
          </Box>
          <Box display="flex" justifyContent="space-between">
            <Typography variant="body1Emphasized">Today's total</Typography>
            <Typography variant="body1Emphasized">
              {formatNum(postDiscountsTotal, { isDollars: true })}
            </Typography>
          </Box>
        </Box>
      )}
    </Box>
  );
};

type CalculateTotalsParams = {
  availableCredit: number;
  isDiscountApplied?: boolean;
  isUsingAvailableCredit?: boolean;
  aestheticsServicesTotalBeforeJoyaCredits?: string;
  skinCareProductTotalBeforeDiscount?: string;
  discountPercent?: string;
};

// calculate totals after discounts and JOYA credits (if they're applied)
const calculateTotals = (params: CalculateTotalsParams) => {
  const {
    availableCredit,
    isDiscountApplied = false,
    isUsingAvailableCredit = false,
    aestheticsServicesTotalBeforeJoyaCredits = "0",
    skinCareProductTotalBeforeDiscount = "0",
    discountPercent = "0",
  } = params;

  // Convert strings to numbers
  const aestheticsServicesTotalBefore = Number(
    aestheticsServicesTotalBeforeJoyaCredits
  );
  const skinCareProductTotalBefore = Number(skinCareProductTotalBeforeDiscount);
  const discount = Number(discountPercent);

  // real total before discounts and JOYA credits
  const preDiscountsTotal =
    aestheticsServicesTotalBefore + skinCareProductTotalBefore;

  // calculate aesthetics services total (dependent on whether they're using their JOYA credits or not)
  const aestheticsServicesTotalAfterJoyaCredits =
    aestheticsServicesTotalBefore - availableCredit;
  const aestheticsServicesTotal = isUsingAvailableCredit
    ? Math.max(aestheticsServicesTotalAfterJoyaCredits, 0)
    : aestheticsServicesTotalBefore;

  // calculate skin care product total (dependent on whether they're using a discount or not)
  const skinCareProductTotalBeforeDiscountAfterDiscount =
    skinCareProductTotalBefore - skinCareProductTotalBefore * (discount / 100);
  const skinCareProductTotal = isDiscountApplied
    ? Math.max(skinCareProductTotalBeforeDiscountAfterDiscount, 0)
    : skinCareProductTotalBefore;

  // total after discounts and JOYA credits
  const postDiscountsTotal = Math.max(
    aestheticsServicesTotal + skinCareProductTotal,
    0
  );

  const todaysSavings = preDiscountsTotal - postDiscountsTotal;
  return { postDiscountsTotal, todaysSavings };
};

// given an array of visit categories, return an object with the visit categories as keys
const getVisitsByCategory = (params: { visitCategories: VisitCategory[] }) => {
  let visitCatsObj = {} as {
    [key in VisitReason["category"]]: { reasonTypes: VisitReason["type"][] };
  };
  params.visitCategories.forEach((cat) => {
    visitCatsObj[cat.name] = {
      reasonTypes: cat.reasons.map((reason) => reason.type),
    };
  });
  return visitCatsObj;
};

const Card = styled(Box)`
  border: 1px solid #e9ebed;
  border-radius: 10px;
  padding: 16px;
  margin-bottom: 8px;
`;

// just using css to show/hide for fields, trying not to unmount fields to keep things simple (no weird unmounting/remounting behavior w/ react hook form)
const Show = styled(Box, { shouldForwardProp: (prop) => prop !== "when" })<{
  when: boolean;
}>`
  display: ${(props) => (props.when ? "block" : "none")};
`;
