import { StepContainer } from "../../StepContainer";
import { BaseExpandableCheckbox } from "@/components/UIKit/Inputs/Checkbox/ExpandableCheckbox";
import { SurveySectionFields } from "@/scenes/Onboarding/types";
import { Languages } from "@/types/apiContract/practice";
import { useState } from "react";

type Props = {
  defaultValues?: Partial<FormInputs>;
  onBack: () => void;
  onContinue: (data: FormInputs) => Promise<void>;
};

const TITLE = "Practice Languages";
const SUBTITLE =
  "Please let us know what languages your providers are fluent in speaking so that we might also match patients who speak these specific languages.";

// FIELDS
const FIELDS: SurveySectionFields<FormInputs> = {
  languages: {
    label: TITLE,
    subtitle: SUBTITLE,
    type: "json",
    example: [
      { id: "english", name: "English" },
      { id: "spanish", name: "Spanish" },
      { id: "chinese", name: "Chinese (including Mandarin and Cantonese)" },
      { id: "tagalog", name: "Tagalog (including Filipino)" },
      { id: "vietnamese", name: "Vietnamese" },
      { id: "french", name: "French" },
      { id: "arabic", name: "Arabic" },
      { id: "korean", name: "Korean" },
      { id: "russian", name: "Russian" },
      { id: "german", name: "German" },
    ],
  },
};

export { FIELDS as ProviderLanguagesFields };

export const ProviderLanguages = (props: Props) => {
  const languageIds = new Set(props.defaultValues?.languages?.map((x) => x.id));
  const [selectedLanguages, setSelectedLanguages] = useState(
    new Set<string>(languageIds)
  );

  const onSubmit = async () => {
    const selectedLanguagesArray = FIELDS.languages.example.filter((lang) =>
      selectedLanguages.has(lang.id)
    );

    await props.onContinue({ languages: selectedLanguagesArray });
  };

  return (
    <StepContainer
      heading={TITLE}
      description={SUBTITLE}
      onBack={props.onBack}
      onContinue={onSubmit}
    >
      {FIELDS.languages.example.map((language) => {
        const isSelected = selectedLanguages.has(language.id);
        const onCheckboxChange = () => {
          setSelectedLanguages((prev) => {
            const newLanguages = new Set(prev);
            if (!isSelected) {
              newLanguages.add(language.id);
            } else {
              newLanguages.delete(language.id);
            }
            return newLanguages;
          });
        };
        return (
          <BaseExpandableCheckbox
            key={language.id}
            label={language.name}
            checkboxProps={{
              checked: isSelected,
              onChange: onCheckboxChange,
            }}
          />
        );
      })}
    </StepContainer>
  );
};
ProviderLanguages.defaultProps = {
  onBack: () => {},
  onContinue: async () => {},
};

// IMPORTANT: treat this as a contract, this is how values are read from the backend (see defaultValues prop)
type FormInputs = { languages: { id: Languages; name: string }[] };
