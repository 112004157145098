import { VisitFormInputs } from "../../hooks/useVisitForm";
import { ProviderModal } from "@/components/Modals/ProviderModal";
import { getSpecialization } from "@/components/Modals/ProviderModal/constants";
import { AddIcon } from "@/components/UIKit/Icons";
import { ExpandableCheckbox } from "@/components/UIKit/Inputs/Checkbox/ExpandableCheckbox";
import useOpenable from "@/hooks/useOpenable";
import { Provider } from "@/types/apiContract/provider";
import { Box, Button, FormHelperText, Typography, styled } from "@mui/material";
import { useFormContext } from "react-hook-form";

type Props = {
  providers: Provider[];
  onAddProviderComplete: () => Promise<void>;
};
export const SelectProvidersStepView = (props: Props) => {
  const {
    watch,
    setValue,
    formState: { errors },
  } = useFormContext<VisitFormInputs>();
  const selectedProviders = new Set(watch("selectedProviders"));

  const {
    isOpen: isAddProviderModalOpen,
    onOpen: onAddProviderModalOpen,
    onClose: onAddProviderModalClose,
  } = useOpenable();

  const onCheckboxChange = (providerId: string, isSelected: boolean) => {
    const newSelectedProviders = new Set(selectedProviders);
    if (isSelected) {
      newSelectedProviders.delete(providerId);
    } else {
      newSelectedProviders.add(providerId);
    }

    setValue("selectedProviders", Array.from(newSelectedProviders));
  };

  return (
    <div>
      {isAddProviderModalOpen && (
        <ProviderModal
          onClose={onAddProviderModalClose}
          onComplete={async () => {
            await props.onAddProviderComplete();
            onAddProviderModalClose();
          }}
        />
      )}
      <Typography variant="h6" mb="24px">
        Who provided care during the patient's visit?
      </Typography>
      <Box mb="12px" textAlign="center">
        <CardButton
          startIcon={<AddIcon />}
          variant="text"
          fullWidth
          onClick={onAddProviderModalOpen}
        >
          <Typography variant="body2Emphasized">Add a provider</Typography>
        </CardButton>
      </Box>

      {props.providers.map((p) => {
        const isSelected = selectedProviders.has(p.id);

        return (
          <ExpandableCheckbox
            key={p.id}
            variant="outlined"
            checkboxProps={{
              checked: isSelected,
              onChange: () => onCheckboxChange(p.id, isSelected),
            }}
            sx={{
              ".MuiFormControlLabel-root": { py: "25px" },
            }}
            label={
              <Box display="flex" justifyContent="space-between" flex={1}>
                <Typography>{p.firstName + " " + p.lastName}</Typography>
                <Typography variant="body2Medium" color="text.secondary">
                  {getSpecialization(p.specialization)?.label}
                </Typography>
              </Box>
            }
          />
        );
      })}
      {errors.selectedProviders && (
        <FormHelperText error>
          {errors.selectedProviders.message}
        </FormHelperText>
      )}
    </div>
  );
};

const CardButton = styled(Button)`
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 24px;
  border: 1px solid #e9ebed;
  border-radius: 12px;
  :hover {
    color: ${(props) => props.theme.palette.text.secondary};
  }
`;
