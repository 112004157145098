import { SvgIcon, SvgIconProps } from "@mui/material";

function CheckIcon(props: SvgIconProps) {
  return (
    <SvgIcon
      viewBox="0 0 33 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M26.6007 8.61761C26.9964 9.00295 27.0047 9.63606 26.6194 10.0317L13.6332 23.365C13.4425 23.5608 13.18 23.67 12.9067 23.6673C12.6334 23.6645 12.3731 23.55 12.1864 23.3504L6.50595 17.2762C6.12872 16.8729 6.14991 16.24 6.55329 15.8628C6.95667 15.4856 7.58948 15.5068 7.96671 15.9101L12.9315 21.219L25.1866 8.63627C25.572 8.24062 26.2051 8.23227 26.6007 8.61761Z"
        fill="currentColor"
      />
    </SvgIcon>
  );
}

export { CheckIcon };
