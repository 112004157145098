import { Components, Theme } from "@mui/material";

const navigation = (theme: Theme): Components<Theme> => ({
  MuiMenu: {
    styleOverrides: {
      list: {
        // from MuiSelect (inputs.tsx)
        "&.custom-MuiSelect-list": {
          padding: "0px",
          ".MuiMenuItem-root": {
            margin: "4px",
            padding: "8px 10px",
            borderRadius: "4px",
          },
        },
      },
    },
  },
});

export { navigation };
