import { AddInsuranceModalView, onSubmitData } from "./View";
import { Insurance } from "./types";
import { GlobalContext } from "@/components/GlobalContext";
import { snackIt } from "@/components/SnackbarManager";
import {
  GetInsurancesResponse,
  SearchInsurancesResponse,
  UpdateInsurancesRequest,
} from "@/types/apiContract/insurance";
import { handleApiError } from "@/utils/feedback";
import { useInfiniteQuery, useMutation, useQuery } from "@tanstack/react-query";
import { useDebounce } from "@uidotdev/usehooks";
import axios from "axios";
import { useContext, useEffect, useState } from "react";

type Props = {
  onClose: () => void;
  onComplete: () => Promise<void>;
};

const SEARCH_PAGE_SIZE = 20;
export const AddInsuranceModalContainer = (props: Props) => {
  const { loggedInProvider } = useContext(GlobalContext);
  const [searchVal, setSearchVal] = useState("");
  const debouncedSearchVal = useDebounce(searchVal, 500);

  // get searched insurances
  const searchInsurancesQuery = useInfiniteQuery({
    queryKey: ["insurances", debouncedSearchVal],
    queryFn: async ({ pageParam }) => {
      return axios.get<SearchInsurancesResponse>(
        `/practices/insurances?nameContains=${debouncedSearchVal}&page=${pageParam}&size=${SEARCH_PAGE_SIZE}`
      );
    },
    initialPageParam: 0,
    getNextPageParam: (lastPage) => lastPage.data.page.number + 1,
  });
  useEffect(() => {
    if (searchInsurancesQuery.error) {
      snackIt.default({
        severity: "error",
        message: `Something went wrong: ${searchInsurancesQuery.error.message}`,
      });
    }
  }, [searchInsurancesQuery.error]);
  const insuranceResults: Insurance[] = [];
  const totalResults =
    searchInsurancesQuery.data?.pages[0].data.page.totalElements;
  searchInsurancesQuery.data?.pages.forEach((x, i) => {
    return x.data.content.forEach((y) => {
      return insuranceResults.push(y);
    });
  });

  const onLoadMore = async () => {
    await searchInsurancesQuery.fetchNextPage();
  };

  // get current picked insurances
  const pickedInsurancesQuery = useQuery({
    queryKey: ["insurances"],
    queryFn: async () => {
      return axios.get<GetInsurancesResponse>(
        `/practices/${loggedInProvider.id}/insurances`
      );
    },
    throwOnError: true, // will trigger react error boundary
  });
  const pickedInsurances = pickedInsurancesQuery.data?.data || [];

  // update picked insurances
  const createInsuranceMutation = useMutation({
    onError: handleApiError,
    onSuccess: async () => {
      await pickedInsurancesQuery.refetch();
    },
    mutationFn: async (params: { insurances: Insurance[] }) => {
      const pickedPlanIds: string[] = [];
      params.insurances.forEach((x) => {
        x.plans.forEach((y) => {
          pickedPlanIds.push(y.id);
        });
      });
      const req: UpdateInsurancesRequest = {
        insuranceIds: [...pickedPlanIds],
      };
      return axios.put(`/practices/${loggedInProvider.id}/insurances`, req);
    },
  });

  const onSubmit = async (data: onSubmitData) => {
    await createInsuranceMutation.mutateAsync({ insurances: data.insurances });
    await props.onComplete();
  };

  return (
    <AddInsuranceModalView
      onClose={props.onClose}
      onSubmit={onSubmit}
      pickedInsurances={pickedInsurances}
      isLoadingPickedInsurances={pickedInsurancesQuery.isLoading}
      searchResults={insuranceResults}
      onSearchChange={setSearchVal}
      searchValue={searchVal}
      isSearching={searchInsurancesQuery.isLoading}
      onLoadMore={onLoadMore}
      totalResults={totalResults}
      isLoadingMoreResults={searchInsurancesQuery.isFetchingNextPage}
    />
  );
};
AddInsuranceModalContainer.defaultProps = {
  onComplete: async () => {},
};
