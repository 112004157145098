import { ThemeOptions } from "@mui/material";

// NOTE: this affects material's <Container> width
const breakpoints: ThemeOptions["breakpoints"] = {
  values: {
    xs: 0,
    sm: 550,
    md: 850,
    lg: 1410,
    xl: 1920,
    tablet: 1120,
  },
};

export { breakpoints };
