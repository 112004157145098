import { AuthLayout } from "../AuthLayout";
import { AuthFormHeading } from "../UI/misc";
import { FormRow } from "@/components/UIKit/Form/styled";
import { Checkbox } from "@/components/UIKit/Inputs/Checkbox/Checkbox";
import { TextField } from "@/components/UIKit/Inputs/TextField/TextField";
import { yupEmail } from "@/utils/yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { LoadingButton } from "@mui/lab";
import { Box, Button, Typography } from "@mui/material";
import { useState } from "react";
import { UseFormProps, useForm } from "react-hook-form";
import * as yup from "yup";

type Props = {
  onSubmit: (data: FormInputs) => Promise<void>;
  onForgotPasswordClick: (email?: string) => void;
};
export const LoginForm = (props: Props) => {
  const { control, register, handleSubmit, watch } = useLoginForm({
    defaultValues: {
      isRememberMeChecked: false,
    },
  });
  const email = watch("email");
  const [isLoading, setIsLoading] = useState(false);

  const onSubmit = async (data: FormInputs) => {
    try {
      setIsLoading(true);
      await props.onSubmit(data);
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <AuthLayout>
      <form onSubmit={handleSubmit(onSubmit)}>
        <AuthFormHeading
          title="Welcome to the provider portal"
          description="Please enter your details to login."
        />
        <FormRow mb="24px">
          <TextField
            control={control}
            register={register("email")}
            label="Email"
            placeholder="Email"
          />
        </FormRow>
        <FormRow>
          <TextField
            control={control}
            register={register("password")}
            type="password"
            label="Password"
            placeholder="Password"
          />
        </FormRow>
        <Box mt="24px" display="flex" justifyContent="space-between">
          <Checkbox
            control={control}
            register={register("isRememberMeChecked")}
            label="Remember me"
          />
          <Button onClick={() => props.onForgotPasswordClick(email)}>
            <Typography variant="button2">Forgot password?</Typography>
          </Button>
        </Box>
        <Box mt="32px">
          <LoadingButton
            variant="contained"
            type="submit"
            fullWidth
            loading={isLoading}
            color="primary"
          >
            Sign In
          </LoadingButton>
        </Box>
      </form>
    </AuthLayout>
  );
};

type FormInputs = {
  email: string;
  password: string;
  isRememberMeChecked: boolean;
};
export type { FormInputs as LoginFormInputs };

const useLoginForm = (props?: UseFormProps<FormInputs>) => {
  let defaultValues = props?.defaultValues;
  const validationSchema: yup.SchemaOf<FormInputs> = yup.object({
    email: yupEmail().required(),
    password: yup.string().required(),
    isRememberMeChecked: yup.boolean().required(),
  });

  return useForm<FormInputs>({
    ...props,
    resolver: yupResolver(validationSchema),
    defaultValues: defaultValues,
  });
};
