import { StepContainer } from "../../StepContainer";
import { CardButton } from "../../UI/buttons";
import { GlobalContext } from "@/components/GlobalContext";
import { ProviderModal } from "@/components/Modals/ProviderModal";
import { getSpecialization } from "@/components/Modals/ProviderModal/constants";
import { AddIcon, EditIcon, TrashIcon } from "@/components/UIKit/Icons";
import useOpenable from "@/hooks/useOpenable";
import { Provider, GetProvidersResponse } from "@/types/apiContract/provider";
import { handleApiError } from "@/utils/feedback";
import {
  Box,
  Card,
  FormHelperText,
  IconButton,
  Typography,
  styled,
} from "@mui/material";
import { useMutation, useQuery } from "@tanstack/react-query";
import axios from "axios";
import { useContext, useState } from "react";

type Props = {
  onBack: () => void;
  onContinue: () => void;
};
export const ProvidersInYourPractice = (props: Props) => {
  const { loggedInProvider } = useContext(GlobalContext);
  const { isOpen, onClose, onOpen } = useOpenable();
  const [formErrMessage, setFormErrMessage] = useState<string>();
  const [selectedProvider, setSelectedProvider] = useState<Provider>();

  // fetch list
  const providersQuery = useQuery({
    queryKey: ["providers"],
    queryFn: async () => {
      return axios.get<GetProvidersResponse>(
        `/practices/${loggedInProvider.id}/providers`
      );
    },
    throwOnError: true, // will trigger react error boundary
  });
  const providers = providersQuery.data?.data || [];

  // delete item
  const deleteproviderMutation = useMutation({
    onError: handleApiError,
    mutationFn: async (providerId: string) => {
      return axios.delete(
        `/practices/${loggedInProvider.id}/providers/${providerId}`
      );
    },
    onSuccess: async () => await providersQuery.refetch(),
  });

  const onSubmit = async () => {
    setFormErrMessage(undefined);
    props.onContinue();
  };

  return (
    <StepContainer
      heading="Providers in your practice"
      description="Please add all providers who will be treating JOYA patients."
      onBack={props.onBack}
      onContinue={onSubmit}
    >
      {isOpen && (
        <ProviderModal
          onClose={onClose}
          onComplete={async () => {
            await providersQuery.refetch();
            setFormErrMessage(undefined);
            onClose();
          }}
        />
      )}
      {selectedProvider && (
        <ProviderModal
          currentProvider={{
            ...selectedProvider,
            specialization: selectedProvider.specialization,
          }}
          onClose={() => setSelectedProvider(undefined)}
          onComplete={async () => {
            await providersQuery.refetch();
            setSelectedProvider(undefined);
          }}
        />
      )}
      <Box mb="24px" display="flex" flexDirection="column" gap="12px">
        {providers.map((pp) => {
          return (
            <ProviderCard key={pp.id}>
              <Box>
                <Typography variant="body2" mb="4px">
                  {pp.firstName + " " + pp.lastName}
                </Typography>
                <Typography color="text.secondary" variant="body3">
                  <SeparatedText>
                    {getSpecialization(pp.specialization)?.label}{" "}
                    <DotSeparator /> {pp.email}
                  </SeparatedText>
                </Typography>
              </Box>
              <Box display="flex" alignItems="center" gap="20px">
                <IconButton onClick={() => setSelectedProvider(pp)}>
                  <EditIcon />
                </IconButton>
                <IconButton
                  disabled={deleteproviderMutation.isPending}
                  onClick={() => deleteproviderMutation.mutate(pp.id)}
                >
                  <TrashIcon />
                </IconButton>
              </Box>
            </ProviderCard>
          );
        })}
      </Box>
      <CardButton startIcon={<AddIcon />} onClick={onOpen}>
        <Typography variant="body2Emphasized">Add a provider</Typography>
      </CardButton>
      {formErrMessage && (
        <FormHelperText error>{formErrMessage}</FormHelperText>
      )}
    </StepContainer>
  );
};
ProvidersInYourPractice.defaultProps = {
  onBack: () => {},
  onContinue: () => {},
};

const ProviderCard = styled(Card)`
  padding: 17px 20px;
  display: flex;
  justify-content: space-between;
`;

const SeparatedText = styled("div")`
  display: flex;
  align-items: center;
  gap: 6px;
`;

const DotSeparator = styled("div")`
  border-radius: 100%;
  width: 2px;
  height: 2px;
  background: currentColor;
`;
